import { config, getUserDetailsQuery } from '@assembly-web/services';
import type { QueryClient } from '@tanstack/react-query';
import type { LoaderFunctionArgs } from 'react-router-dom';
import { redirect, useLoaderData, useLocation } from 'react-router-dom';

import { RichPostPreview } from '../../../components/RichPostPreview';
import { FilePreviewModalProvider } from '../../../contexts/FilePreviewModalContext';
import {
  getPostQuery,
  getPostRepliesQuery,
} from '../../../queries/getAssemblyPostQuery';

enum PostType {
  Flow = 'flow',
  Recognition = 'recognition',
}

export const checkURLForAssemblyPosts = (url: URL) => {
  const { searchParams, pathname } = url;

  const isValidAssemblyURL = url
    .toString()
    .match(config.publicAssemblyPostRegex);

  if (
    isValidAssemblyURL &&
    pathname.includes('/post/flow/flows') &&
    searchParams.get('responseId')
  ) {
    return PostType.Flow;
  }

  if (
    isValidAssemblyURL &&
    pathname.includes('/post/flow/recognition') &&
    searchParams.get('postId')
  ) {
    return PostType.Recognition;
  }

  return undefined;
};

function getFlowIdAndResponseIdFromURL(inputURL: string) {
  const url = new URL(inputURL);

  const flowId =
    checkURLForAssemblyPosts(url) === 'recognition'
      ? 'recognition'
      : url.searchParams.get('flowId');

  const responseId =
    checkURLForAssemblyPosts(url) === 'recognition'
      ? url.searchParams.get('postId')
      : url.searchParams.get('responseId');

  return {
    flowId,
    responseId,
  };
}

export function embedsLoader(queryClient: QueryClient) {
  return async function loader({ request }: LoaderFunctionArgs) {
    const userDetailsQuery = getUserDetailsQuery();

    queryClient.prefetchQuery({
      queryKey: userDetailsQuery.queryKey,
      queryFn: userDetailsQuery.queryFn,
    });
    const url = new URL(request.url.toString()).searchParams.get('url');

    if (!url) {
      return null;
    }

    const { flowId, responseId } = getFlowIdAndResponseIdFromURL(url);

    if (!flowId || !responseId) {
      return redirect('/404');
    }

    if (flowId && responseId) {
      const assemblyPostQuery = getPostQuery({
        flowId,
        responseId,
        currencyName: 'Lions',
        asEmbed: true,
      });
      queryClient.prefetchQuery(assemblyPostQuery);

      const assemblyPostRepliesQuery = getPostRepliesQuery({
        flowId,
        responseId,
        enabled: true,
      });
      queryClient.prefetchQuery(assemblyPostRepliesQuery);
    }

    return {
      flowId,
      responseId,
    };
  };
}

export function EmbedsRoute() {
  const { search } = useLocation();
  const url = new URLSearchParams(search).get('url');
  const urlPath = url && new URL(url).pathname;

  const { flowId, responseId } = useLoaderData() as {
    flowId: string;
    responseId: string;
  };

  return urlPath ? (
    <FilePreviewModalProvider>
      <RichPostPreview
        flowId={flowId}
        isIFrameEnv
        responseId={responseId}
        urlPath={urlPath}
        hasReplyDraft={false}
        lazyLoadPostDetailsOnScroll={true}
      />
    </FilePreviewModalProvider>
  ) : null;
}
