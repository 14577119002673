import { SVGReactComponents } from '@assembly-web/assets';
import {
  isTruthy,
  SplitNames,
  useFeatureSplit,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import {
  GlobalFilterOption,
  RoutineIcon,
  TextStyle,
  Tooltip,
} from '@assembly-web/ui';
import {
  BellIcon,
  ChartBarIcon,
  DocumentTextIcon,
  FolderIcon,
  GiftIcon,
  HomeIcon,
  LockClosedIcon,
  TrophyIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { useUnreadNotificationsCount } from '../../hooks/useUnreadNotificationsCount';
import { trackAppConnectionAction } from '../../modules/connections/services/analytics';
import { useCreateWorkflow } from '../../modules/discover/hooks/flowsEditor/useCreateWorkflow';
import { useGetPlanFeatures } from '../../modules/discover/hooks/useGetPlanFeaturesQuery';
import { trackDoraAction } from '../../modules/discover/services/analytics';
import { isDoraIncludedInWorkspacePlan } from '../../modules/discover/services/dora';
import { setFullScreenDrawerId } from '../../modules/discover/services/drawers';
import { isAdminMember } from '../../modules/discover/services/member';
import { useMultiDrawerStore } from '../../stores/useMultiDrawerStore';
import { useNavBarStore } from '../../stores/useNavBarStore';
import { NavCollapsibleSection } from './NavCollapsibleSection';
import { NavItem } from './NavItem';

const messages = defineMessages({
  mainSectionTitle: {
    id: 'EFTSMc',
    defaultMessage: 'Main',
  },
  home: {
    defaultMessage: 'Home',
    id: 'ejEGdx',
  },
  activity: {
    defaultMessage: 'Activity',
    id: 'ZmlNQ3',
  },
  rewards: {
    defaultMessage: 'Rewards',
    id: 'y0EYpw',
  },
  challenges: {
    defaultMessage: 'Challenges',
    id: '///zLu',
  },
  aiReporting: {
    defaultMessage: 'AI Reporting',
    id: '7cdCss',
  },
  moreToggle: {
    defaultMessage: 'More',
    id: 'I5NMJ8',
  },
  doraDrawerTitle: {
    defaultMessage: 'DoraAI Reporting',
    id: 'eE3c8F',
  },
  upgrade: {
    defaultMessage: 'Upgrade',
    id: '0h/lPM',
  },
  visibleToAdmins: {
    defaultMessage: 'Only visible to admins.',
    id: 'uzTBkg',
  },
  flows: {
    defaultMessage: 'Flows',
    id: '3Pbj9T',
  },
  folders: {
    defaultMessage: 'Folders',
    id: '8ILyTU',
  },
  people: {
    defaultMessage: 'People',
    id: 'Tpy00S',
  },
  files: {
    defaultMessage: 'Files',
    id: 'm4vqJl',
  },
  appConnections: {
    defaultMessage: 'App Connections',
    id: 'Cgi2RU',
  },
  recognition: {
    defaultMessage: 'Recognition',
    id: 'sV4Od1',
  },
});

export function MainNavSectionSkeleton() {
  const { formatMessage } = useIntl();
  const isMainSectionOpen = useNavBarStore(
    useShallow((state) => state.sectionOpenState.main)
  );

  const toggleNavSection = useNavBarStore(
    useShallow((state) => state.toggleSection)
  );

  return (
    <div className="flex flex-col gap-2">
      <TextStyle variant="sm-bold" subdued className="uppercase">
        {formatMessage(messages.mainSectionTitle)}
      </TextStyle>
      <div className="mt-4 flex flex-col items-start gap-2 px-1">
        <NavItem.Skeleton />
        <NavItem.Skeleton />
        <NavItem.Skeleton />
        <NavItem.Skeleton />
        <NavItem.Skeleton />
        <NavCollapsibleSection
          title={formatMessage(messages.moreToggle)}
          isSectionOpen={isMainSectionOpen}
          onSectionToggle={() => toggleNavSection('main')}
          isSubSection
        >
          <NavItem.Skeleton />
          <NavItem.Skeleton />
          <NavItem.Skeleton />
          <NavItem.Skeleton />
        </NavCollapsibleSection>
      </div>
    </div>
  );
}

export function MainNavSection({
  isRecognitionOnlyCustomer,
}: {
  isRecognitionOnlyCustomer: boolean;
}) {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();

  const { data: userDetails } = useSuspenseUserDetails();

  const { data: planFeatureDetails } = useGetPlanFeatures();

  const createWorkflow = useCreateWorkflow();

  const notificationsCount = useUnreadNotificationsCount();

  const isMainSectionOpen = useNavBarStore(
    useShallow((state) => state.sectionOpenState.main)
  );

  const toggleNavSection = useNavBarStore(
    useShallow((state) => state.toggleSection)
  );

  const { isTreatmentActive: addMergeAppsEnabled } = useFeatureSplit(
    SplitNames.AddMergeApps
  );

  const { isTreatmentActive: isDoraReportingInsightsManagersEnabled } =
    useFeatureSplit(SplitNames.DoraReportingInsightsManagers);

  const { isTreatmentActive: canAccessCollectionPage } = useFeatureSplit(
    SplitNames.AccessCollectionsPage
  );

  const createDrawer = useMultiDrawerStore((store) => store.createDrawer);

  const isDoraIncludedInPlan =
    isDoraIncludedInWorkspacePlan(planFeatureDetails);

  const handleDoraReportingClick = useCallback(() => {
    const drawerId = createDrawer({
      type: 'doraChat',
      title: formatMessage(messages.doraDrawerTitle),
      data: {},
    });

    setFullScreenDrawerId(drawerId); // Open in full-screen mode by default
    trackDoraAction('aiReportingInsightsClicked');
  }, [createDrawer, formatMessage]);

  const isAdmin = isAdminMember(userDetails.member);
  const workspaceSlugPath = userDetails.assembly.workspaceSlugPath;
  const { isManager } = userDetails.member.profile;

  const isDoraReportingInsightsVisible =
    Boolean(isDoraIncludedInPlan) &&
    (isAdmin || (isDoraReportingInsightsManagersEnabled && isManager));

  const canAddConnections =
    addMergeAppsEnabled && isAdminMember(userDetails.member);

  return (
    <div className="flex flex-col gap-2">
      <TextStyle variant="sm-bold" subdued className="uppercase">
        {formatMessage(messages.mainSectionTitle)}
      </TextStyle>
      <div className="mt-4 flex flex-col items-start gap-2">
        <NavItem.InternalLink
          url="/a"
          icon={HomeIcon}
          text={formatMessage(messages.home)}
        />

        <NavItem.InternalLink
          url="/a/activity"
          icon={BellIcon}
          text={formatMessage(messages.activity)}
          isActive={pathname.includes('/activity')}
        >
          {notificationsCount > 0 && (
            <TextStyle
              variant="xs-medium"
              className="ml-auto flex h-6 w-6 items-center justify-center rounded-lg border border-primary-3 bg-primary-1 px-3 py-1"
            >
              {notificationsCount}
            </TextStyle>
          )}
        </NavItem.InternalLink>

        <NavItem.InternalLink
          url="/a/rewards"
          icon={GiftIcon}
          text={formatMessage(messages.rewards)}
        />

        {!isRecognitionOnlyCustomer && (
          <NavItem.InternalLink
            url={`/a/${workspaceSlugPath}/flows/recognition`}
            icon={SVGReactComponents.RecognitionIcon}
            text={formatMessage(messages.recognition)}
          />
        )}

        <NavItem.InternalLink
          url="/a/challenges"
          icon={TrophyIcon}
          text={formatMessage(messages.challenges)}
        />

        {isTruthy(isDoraReportingInsightsVisible) && (
          <NavItem.Button
            onClick={() => handleDoraReportingClick()}
            icon={ChartBarIcon}
            text={formatMessage(messages.aiReporting)}
          />
        )}

        {isRecognitionOnlyCustomer ? (
          <>
            <NavItem.InternalLink
              url="/a/people"
              icon={UsersIcon}
              text={formatMessage(messages.people)}
            />
            {isTruthy(isAdmin) && (
              <NavItem.Button
                onClick={() => createWorkflow()}
                icon={RoutineIcon}
                text={
                  <div className="flex items-center gap-4">
                    {formatMessage(messages.flows)}
                    <Tooltip
                      tooltipText={formatMessage(messages.visibleToAdmins)}
                    >
                      <div className="flex items-center gap-2 rounded-3xl bg-upgrade-4 px-2 py-1">
                        <LockClosedIcon className="h-4 w-4" />
                        <TextStyle variant="sm-medium">
                          {formatMessage(messages.upgrade)}
                        </TextStyle>
                      </div>
                    </Tooltip>
                  </div>
                }
              />
            )}
          </>
        ) : (
          <NavCollapsibleSection
            title={formatMessage(messages.moreToggle)}
            isSectionOpen={isMainSectionOpen}
            onSectionToggle={() => toggleNavSection('main')}
            isSubSection
          >
            <NavItem.InternalLink
              url="/a/flows"
              icon={RoutineIcon}
              text={formatMessage(messages.flows)}
            />

            <NavItem.InternalLink
              url="/a/people"
              icon={UsersIcon}
              text={formatMessage(messages.people)}
            />

            <NavItem.InternalLink
              url="/a/files"
              icon={DocumentTextIcon}
              text={formatMessage(messages.files)}
            />

            {isTruthy(canAccessCollectionPage) && (
              <NavItem.InternalLink
                url="/a/collections"
                icon={FolderIcon}
                text={formatMessage(messages.folders)}
              />
            )}

            {isTruthy(canAddConnections) && (
              <NavItem.InternalLink
                url="/a/connections"
                icon={SVGReactComponents.AppConnectionsIcon}
                text={formatMessage(messages.appConnections)}
                onClick={() => {
                  trackAppConnectionAction('manageAppsOpened', {
                    filterType: GlobalFilterOption.Files,
                  });
                }}
              />
            )}
          </NavCollapsibleSection>
        )}
      </div>
    </div>
  );
}
