import { useSuspenseUserDetails } from '@assembly-web/services';
import { AnnouncementCenterModal, useDeviceInfo } from '@assembly-web/ui';
import { type ReactNode, Suspense, useContext } from 'react';
import { useMatches } from 'react-router-dom';
import type { Primitive } from 'type-fest';

import { AnnouncementsInsights } from '../../modules/discover/components/modals/AnnouncementsInsights';
import { ShareCollectionModal } from '../../modules/discover/components/modals/ShareCollectionModal';
import { AnnouncementsContext } from '../../modules/discover/hooks/useAnnouncements';
import { useIsNewUX } from '../../modules/discover/hooks/useIsNewUX';
import { DesktopLayout } from './DesktopLayout';
import { MobileLayout } from './MobileLayout';
import { TabletLayout } from './TabletLayout';

export function AppLayout({ children }: { children: ReactNode }) {
  const { deviceType, isMobileDevice } = useDeviceInfo();
  const isNewUX = useIsNewUX();
  const { data: userDetails } = useSuspenseUserDetails();

  const isParticipationPage = useMatches().some(
    (match) =>
      (match.handle as undefined | Record<string, Primitive>)?.route ===
      'participation'
  );

  const {
    isSaving,
    closeAnnouncementModal,
    announcementFormValues,
    disableResponseSettings,
    isAnnouncementsModalOpen,
    handleOnGetHelpButtonClick,
    handleOnSaveAnnouncementClick,
    selectedAnnouncementId,
    closeAnnouncementsInsightsModal,
  } = useContext(AnnouncementsContext);

  if (isParticipationPage) {
    return <>{children}</>;
  }

  return (
    <Suspense fallback={null}>
      {isMobileDevice ? (
        <MobileLayout>{children}</MobileLayout>
      ) : deviceType === 'tablet' ? (
        <TabletLayout>{children}</TabletLayout>
      ) : (
        <DesktopLayout>{children}</DesktopLayout>
      )}

      <AnnouncementsInsights
        announcementId={selectedAnnouncementId}
        onClose={closeAnnouncementsInsightsModal}
      />

      <AnnouncementCenterModal
        isNewUX={isNewUX}
        isSavingAnnouncement={isSaving}
        onClose={closeAnnouncementModal}
        isOpen={isAnnouncementsModalOpen}
        onSave={handleOnSaveAnnouncementClick}
        memberID={userDetails.member.memberId}
        announcementFormValues={announcementFormValues}
        onGetHelpButtonClick={handleOnGetHelpButtonClick}
        disableResponseSettings={disableResponseSettings}
        memberFullName={userDetails.member.profile.fullName}
        imageUrl={userDetails.member.profile.image}
      />

      <ShareCollectionModal currentUserDetails={userDetails} />
    </Suspense>
  );
}
