import { usePusherChannel, usePusherEvent } from '@assembly-web/pusher';
import type {
  FlowPostResponse,
  ImportantActivitiesApiResponse,
  UserFeedApiResponse,
} from '@assembly-web/services';
import {
  ActivityCardTypeEnum,
  logger,
  useUserDetails,
} from '@assembly-web/services';
import type { InfiniteData, QueryClient } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';

import type { SearchIndexApiResponse } from '../useSearchIndex';

export type PostInteractionSettingsUpdatedEvent = {
  flowId: string;
  responseId: string;
  hideReplies?: boolean | undefined;
  hideReactions?: boolean | undefined;
};

export type PostInteractionSettingsPusherProps = {
  pageType:
    | 'importantActivities'
    | 'userFeed'
    | 'searchFeed'
    | 'collectionsFeed'
    | 'assemblyPost';
};

function updateAssemblyPostCacheInteractionSettings({
  eventName,
  queryClient,
  socketPayload,
}: {
  queryClient: QueryClient;
  socketPayload: PostInteractionSettingsUpdatedEvent;
  eventName: 'RESPONSE_REACTIONS_SETTING' | 'RESPONSE_REPLIES_SETTING';
}) {
  const assemblyPostQueryKey = [
    'assemblyFlowPost',
    socketPayload.flowId,
    socketPayload.responseId,
  ];
  const queryCacheData: FlowPostResponse | undefined =
    queryClient.getQueryData(assemblyPostQueryKey);
  if (queryCacheData) {
    const updatedQueryCacheData: FlowPostResponse = produce(
      queryCacheData,
      (draft) => {
        if (eventName === 'RESPONSE_REACTIONS_SETTING') {
          draft.hideReactions = socketPayload.hideReactions;
        }
        if (eventName === 'RESPONSE_REPLIES_SETTING') {
          draft.hideReplies = socketPayload.hideReplies;
        }
      }
    );
    queryClient.setQueryData(assemblyPostQueryKey, updatedQueryCacheData);
  }
}

function updateInteractionSettingsStatusUpdated({
  eventName,
  queryClient,
  socketPayload,
}: {
  socketPayload: PostInteractionSettingsUpdatedEvent;
  eventName: 'RESPONSE_REACTIONS_SETTING' | 'RESPONSE_REPLIES_SETTING';
  queryClient: QueryClient;
}) {
  //Individual Assembly Post(Collections, Post Drawer)
  const queryCache = queryClient.getQueryCache();
  updateAssemblyPostCacheInteractionSettings({
    eventName: eventName,
    queryClient: queryClient,
    socketPayload: socketPayload,
  });
  //Important Activities
  const importantActivitiesQueryKey = ['importantCards'];
  const importantActivitiesData:
    | InfiniteData<ImportantActivitiesApiResponse>
    | undefined = queryClient.getQueryData(importantActivitiesQueryKey);
  if (importantActivitiesData) {
    const mutatedImportantActivities: InfiniteData<ImportantActivitiesApiResponse> =
      {
        ...importantActivitiesData,
        pages: importantActivitiesData.pages.map((page) => {
          return {
            ...page,
            data: {
              ...page.data,
              data: page.data.data.map((card) => {
                if (
                  card.type === ActivityCardTypeEnum.OwnerResponseReply ||
                  card.type === ActivityCardTypeEnum.ResponseThreadReplies
                ) {
                  const responseId = card.entity.response.responseId;
                  if (responseId === socketPayload.responseId) {
                    if (eventName === 'RESPONSE_REACTIONS_SETTING') {
                      card.entity.hideReactions = socketPayload.hideReactions;
                    }
                    if (eventName === 'RESPONSE_REPLIES_SETTING') {
                      card.entity.hideReplies = socketPayload.hideReplies;
                    }
                  }
                  return card;
                } else if (
                  card.type === ActivityCardTypeEnum.ResponseMentions
                ) {
                  queryClient.refetchQueries({
                    queryKey: [
                      'assemblyFlowPost',
                      socketPayload.flowId,
                      socketPayload.responseId,
                    ],
                  });
                }
                return card;
              }),
            },
          };
        }),
      };
    queryClient.setQueryData(
      importantActivitiesQueryKey,
      mutatedImportantActivities
    );
  }
  //User Feed
  const userFeedKeys = queryCache
    .findAll({
      queryKey: ['userFeed'],
    })
    .map((query) => query.queryKey);
  userFeedKeys.forEach((key) => {
    const userFeedData: InfiniteData<UserFeedApiResponse> | undefined =
      queryClient.getQueryData(key);
    if (userFeedData) {
      const mutatedUserFeedData: InfiniteData<UserFeedApiResponse> = {
        ...userFeedData,
        pages: userFeedData.pages.map((page) => {
          return {
            ...page,
            data: page.data.map((post) => {
              if (post.cardDetails?.responseId === socketPayload.responseId) {
                const updatedPost = produce(post, (draft) => {
                  if (draft.cardDetails) {
                    if (eventName === 'RESPONSE_REACTIONS_SETTING') {
                      draft.cardDetails.hideReactions =
                        socketPayload.hideReactions;
                    }
                    if (eventName === 'RESPONSE_REPLIES_SETTING') {
                      draft.cardDetails.hideReplies = socketPayload.hideReplies;
                    }
                  }
                });
                return updatedPost;
              }
              return post;
            }),
          };
        }),
      };
      queryClient.setQueryData(key, mutatedUserFeedData);
    }
  });
  //Search Feed
  const searchFeedKeys = queryCache
    .findAll({
      queryKey: ['searchResults'],
    })
    .map((query) => query.queryKey);
  searchFeedKeys.forEach((key) => {
    const searchData: InfiniteData<SearchIndexApiResponse> | undefined =
      queryClient.getQueryData(key);
    if (searchData) {
      const mutatedSearchData: InfiniteData<SearchIndexApiResponse> = {
        ...searchData,
        pages: searchData.pages.map((page) => {
          return {
            ...page,
            data: {
              ...page.data,
              data: page.data.data.map((result) => {
                if (
                  result.cardDetails?.responseId === socketPayload.responseId
                ) {
                  const updatedResult = produce(result, (draft) => {
                    if (draft.cardDetails) {
                      if (eventName === 'RESPONSE_REACTIONS_SETTING') {
                        draft.cardDetails.hideReactions =
                          socketPayload.hideReactions;
                      }
                      if (eventName === 'RESPONSE_REPLIES_SETTING') {
                        draft.cardDetails.hideReplies =
                          socketPayload.hideReplies;
                      }
                    }
                  });
                  return updatedResult;
                }
                return result;
              }),
            },
          };
        }),
      };
      queryClient.setQueryData(key, mutatedSearchData);
    }
  });
}

export function usePostInteractionSettingsUpdatesEvents() {
  const queryClient = useQueryClient();
  const { data: userDetails } = useUserDetails();

  const assembly = usePusherChannel(
    `private-assembly-${userDetails?.assembly.assemblyId}`
  );

  usePusherEvent<PostInteractionSettingsUpdatedEvent>(
    assembly,
    'RESPONSE_REACTIONS_SETTING',
    async (data) => {
      try {
        if (data) {
          updateInteractionSettingsStatusUpdated({
            socketPayload: data,
            eventName: 'RESPONSE_REACTIONS_SETTING',
            queryClient: queryClient,
          });
        }
      } catch (e) {
        logger.error(
          'Refetch error on post interaction settings',
          {},
          e as Error
        );
      }
    }
  );

  usePusherEvent<PostInteractionSettingsUpdatedEvent>(
    assembly,
    'RESPONSE_REPLIES_SETTING',
    async (data) => {
      try {
        if (data) {
          updateInteractionSettingsStatusUpdated({
            socketPayload: data,
            eventName: 'RESPONSE_REPLIES_SETTING',
            queryClient: queryClient,
          });
        }
      } catch (e) {
        logger.error(
          'Refetch error on post interaction settings',
          {},
          e as Error
        );
      }
    }
  );
}
