import ContentLoader from 'react-content-loader';

export function CollectionSkeletonLoader() {
  return (
    <ContentLoader
      speed={2}
      width={246}
      height={84}
      viewBox="0 0 248 84"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="3" y="4" rx="4" ry="4" width="240" height="32" />
      <rect x="7" y="52" rx="4" ry="4" width="4" height="24" />
      <rect x="19" y="60" rx="4" ry="4" width="218" height="8" />
    </ContentLoader>
  );
}

export function ChatsSkeletonLoader() {
  return (
    <ContentLoader
      speed={2}
      width={246}
      height={64}
      viewBox="0 0 248 64"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="7" y="0" rx="4" ry="4" width="4" height="24" />
      <rect x="19" y="8" rx="4" ry="4" width="218" height="8" />

      <rect x="7" y="34" rx="4" ry="4" width="4" height="24" />
      <rect x="19" y="42" rx="4" ry="4" width="218" height="8" />
    </ContentLoader>
  );
}
