import type { UserDetails } from '@assembly-web/services';
import { defaultWorkspaceBackground } from '@assembly-web/services';
import {
  Button,
  ColorPicker,
  LoadingSpinner,
  Modal,
  TextStyle,
  useToastStore,
} from '@assembly-web/ui';
import { type Color, parseColor } from '@react-stately/color';
import { type FocusEvent, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useAssemblySettingsMutation } from '../../hooks/useAssemblySettingsMutation';

const messages = defineMessages({
  title: {
    defaultMessage: 'Change workspace theme',
    id: 'dkcsfC',
  },
  heading: {
    defaultMessage: 'Add a background color',
    id: 'k+ZDFy',
  },
  content: {
    defaultMessage:
      'This color will apply to the background for all members of your workspace. You can choose any color, but we suggest choosing a muted or dark tone that is easier on the eyes.',
    id: 'qP3yTw',
  },
  previewButton: {
    defaultMessage: 'Preview',
    id: 'TJo5E6',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    id: '47FYwb',
  },
  resetButton: {
    defaultMessage: 'Reset to default gray',
    id: 'Y/YAof',
  },
  saveButton: {
    defaultMessage: 'Save',
    id: 'jvo0vs',
  },
  savingText: {
    defaultMessage: 'Saving',
    id: 'QLkzoS',
  },
  successToastMessage: {
    defaultMessage: "Your workspace's theme has been updated successfully",
    id: 'DNVkIl',
  },
  resetToastMessage: {
    defaultMessage: "Your workspace's theme has been reset successfully",
    id: 'oTRja2',
  },
  errorToastMessage: {
    defaultMessage: 'Something went wrong. Please try again.',
    id: 'W/5hwr',
  },
  inputLabel: {
    defaultMessage: 'Pick a background color',
    id: 'ETAuva',
  },
});

export function ThemeCustomizationModal({
  userDetails,
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  userDetails: UserDetails;
  onClose: () => void;
}) {
  const { formatMessage } = useIntl();

  const { mutateAsync: updateSetting, isPending } =
    useAssemblySettingsMutation();

  const { showSuccessToast, showErrorToast } = useToastStore();

  const existingBackgroundColor =
    userDetails.assembly.settings.theme?.backgroundColor ||
    defaultWorkspaceBackground.light;

  const [colorValue, setColorValue] = useState<Color | null>(
    parseColor(existingBackgroundColor)
  );

  useEffect(
    function resetColorOnClose() {
      setColorValue(parseColor(existingBackgroundColor));
    },
    [existingBackgroundColor, isOpen]
  );

  const hexValue = colorValue?.toString('hex');

  const updateColor = async (backgroundColor?: string) => {
    try {
      if (
        !backgroundColor ||
        backgroundColor === defaultWorkspaceBackground.light
      ) {
        await updateSetting({ theme: { backgroundColor: null } });
        showSuccessToast(formatMessage(messages.resetToastMessage));
      } else {
        await updateSetting({ theme: { backgroundColor } });
        showSuccessToast(formatMessage(messages.successToastMessage));
      }
    } catch (error) {
      showErrorToast(formatMessage(messages.errorToastMessage));
    }

    onClose();
  };

  return (
    <Modal
      dataTestId="theme-customization-modal"
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage(messages.title)}
      className="gap-1"
    >
      <TextStyle variant="base-medium" className="mt-3">
        {formatMessage(messages.heading)}
      </TextStyle>
      <TextStyle variant="sm-regular" className="text-gray-8">
        {formatMessage(messages.content)}
      </TextStyle>
      <div className="flex w-full gap-2 pt-1">
        <ColorPicker
          isRequired
          aria-label={formatMessage(messages.inputLabel)}
          defaultValue={colorValue}
          onChange={setColorValue}
          onBlur={(e: FocusEvent) => {
            /** NOTE:
             * This is to help reset the color to handle issues with
             * the react stately color lib bugs when users enter invalid
             * colors
             */
            setTimeout(() => {
              try {
                setColorValue(parseColor((e.target as HTMLInputElement).value));
              } finally {
                /* Empty block */
              }
            });
          }}
        />
      </div>
      <footer className="mt-6 flex justify-between">
        <Button
          variation="tertiaryLite"
          onClick={onClose}
          data-testid="cancel-button"
        >
          {formatMessage(messages.cancelButton)}
        </Button>
        <div className="flex gap-2">
          <Button
            variation="secondaryLite"
            data-testid="reset-button"
            disabled={
              defaultWorkspaceBackground.light === hexValue || isPending
            }
            onClick={() => {
              setColorValue(parseColor(defaultWorkspaceBackground.light));
              updateColor(defaultWorkspaceBackground.light);
            }}
          >
            {formatMessage(messages.resetButton)}
          </Button>
          <Button
            variation="primary"
            data-testid="save-button"
            onClick={() => updateColor(hexValue)}
            disabled={
              !hexValue || isPending || hexValue === existingBackgroundColor
            }
            isLoading={isPending}
          >
            {isPending ? (
              <>
                <LoadingSpinner className="text-gray-1" />
                <span className="text-gray-1">
                  {formatMessage(messages.savingText)}
                </span>
              </>
            ) : (
              formatMessage(messages.saveButton)
            )}
          </Button>
        </div>
      </footer>
    </Modal>
  );
}
