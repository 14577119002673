import { assemblyLogo, fileIcons, postIcon } from '@assembly-web/assets';
import type {
  PinnedCollection,
  SearchIndexResult,
  UserDetails,
} from '@assembly-web/services';
import {
  checkIfCommentKindMatches,
  config,
  formatAssemblySearchResult,
  getFaviconSource,
  getMixpanelCollectionItemType,
  getModifiedAssemblyLinkURL,
  isChallengeFile,
} from '@assembly-web/services';
import {
  AssemblyLink,
  Avatar,
  AvatarSize,
  Challenge,
  getFolderStyles,
  ProfileViewer,
  TextStyle,
  Toolbar,
  type ToolbarItem,
  useToolbarState,
} from '@assembly-web/ui';
import {
  ArrowDownIcon,
  ArrowRightOnRectangleIcon,
  ArrowUpIcon,
  ChatBubbleBottomCenterTextIcon,
  CheckCircleIcon as CheckCircleIconOutline,
  LinkIcon,
} from '@heroicons/react/24/outline';
import {
  Content,
  Root as HoverCardRoot,
  Trigger,
} from '@radix-ui/react-hover-card';
import type { FocusEventHandler, MouseEventHandler, ReactNode } from 'react';
import { useMemo, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { useNavigateToUserFeed } from '../../../../hooks/useNavigateToUserFeed';
import { usePosts } from '../../../../hooks/usePosts';
import { useReplies } from '../../../../hooks/useReplies';
import type { PostData } from '../../../../types/postsAndReplies';
import { useChallengeDrawer } from '../../hooks/challenges/useChallengeDrawer';
import { useNavStore } from '../../hooks/nav/useNavStore';
import { trackNavAction } from '../../services/analytics';
import { getFileIcon } from '../../services/files';
import type { collectionItemActions, NavBarProps } from './NavBar';

const msgs = defineMessages({
  taskTitle: {
    defaultMessage: 'Task: {taskTitle}',
    id: 'i0U44K',
  },
  comment: {
    defaultMessage:
      'Reply by {author, select, self {you} other {{author}}} on {post} in {flow}',
    id: 'RiL5JV',
  },
  challengeComment: {
    defaultMessage:
      'Reply by {author, select, self {you} other {{author}}} on {challenge}',
    id: 'jHDwEn',
  },
  postByLabel: {
    defaultMessage:
      'Post by {author, select, self {you} other {{author}}} in {flow}',
    id: 'd/Wpk/',
  },
  birthDayPostLabel: {
    defaultMessage:
      'Birthday Post for {celebratedTeammate, select, self {you} other {{celebratedTeammate}}} in {flow}',
    id: 'y8Ogzt',
  },
  anniversaryDayPostLabel: {
    defaultMessage:
      'Anniversary Post for {celebratedTeammate, select, self {you} other {{celebratedTeammate}}} in {flow}',
    id: 'aIfTHC',
  },
  copyLink: {
    defaultMessage: 'Copy link',
    id: 'lbr3Lq',
  },
  moveItemUp: {
    defaultMessage: 'Move item up',
    id: '6ibWnb',
  },
  moveItemDown: {
    defaultMessage: 'Move item down',
    id: 'UjPa5a',
  },
  moveToAnotherCollection: {
    defaultMessage: 'Move to another collection',
    id: 'h4xSAq',
  },
  removeItemFromCollection: {
    defaultMessage: 'Remove item from collection',
    id: 'AvphWl',
  },
});

function useCollectionItemOptions({
  isFirstItem,
  isLastItem,
  collection,
  item,
}: {
  isFirstItem: boolean;
  isLastItem: boolean;
  collection: PinnedCollection;
  item: SearchIndexResult;
}) {
  const { formatMessage } = useIntl();

  const { allowedEditing } = collection;

  return useMemo(() => {
    const itemsToShow: ToolbarItem[] = [
      item.type !== 'file' && {
        id: 'copyLink',
        icon: LinkIcon,
        text: formatMessage(msgs.copyLink),
      },

      allowedEditing && {
        id: 'moveItemUp',
        icon: ArrowUpIcon,
        text: formatMessage(msgs.moveItemUp),
        disabled: isFirstItem,
      },

      allowedEditing && {
        id: 'moveItemDown',
        icon: ArrowDownIcon,
        text: formatMessage(msgs.moveItemDown),
        disabled: isLastItem,
      },
      // TODO: need to use radix menubar to enable submenus
      /*    allowedEditing && {
        id: 'moveToAnotherCollection',
        icon: FolderOpenIcon,
        text: formatMessage(msgs.moveToAnotherCollection),
      }, */
      allowedEditing && {
        id: 'removeItemFromCollection',
        icon: ArrowRightOnRectangleIcon,
        text: formatMessage(msgs.removeItemFromCollection),
      },
    ].filter(Boolean);

    return itemsToShow;
  }, [allowedEditing, formatMessage, isFirstItem, isLastItem, item.type]);
}

function Wrapper({
  children,
  url,
  target,
  text,
  showHoverCard,
  onClick,
  onBlur,
  onFocus,
  onMouseOver,
  onMouseLeave,
}: {
  children: ReactNode;
  url?: string;
  target?: '_blank' | '_self';
  text: string;
  showHoverCard: boolean;
  onClick: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  onBlur: FocusEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  onFocus: FocusEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  onMouseOver: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  onMouseLeave: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
}) {
  const trigger = url ? (
    <AssemblyLink
      to={url}
      className="inline-grid w-full grid-cols-12 items-center gap-2 rounded p-1 focus-within:bg-gray-3 hover:bg-gray-3 active:bg-gray-5 enabled:hover:bg-gray-3"
      target={target ?? '_self'}
      onClick={onClick}
      onBlur={onBlur}
      onFocus={onFocus}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </AssemblyLink>
  ) : (
    <button
      className="inline-grid grid-cols-12 items-center gap-2 rounded p-1 focus-within:bg-gray-3 hover:bg-gray-3 active:bg-gray-5 enabled:hover:bg-gray-3"
      onClick={onClick}
      onBlur={onBlur}
      onFocus={onFocus}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </button>
  );

  return showHoverCard ? (
    <HoverCardRoot openDelay={400} closeDelay={0}>
      <Trigger asChild className="flex items-center justify-start">
        {trigger}
      </Trigger>
      <Content className="z-[60] w-[calc(var(--radix-hover-card-trigger-width))] rounded bg-gray-1 px-3 py-2 shadow-lg-down">
        <TextStyle
          variant="xs-regular"
          className="col-span-10 break-words text-left"
          html={text}
        />
      </Content>
    </HoverCardRoot>
  ) : (
    trigger
  );
}

function Entry({
  item,
  plainText,
  text,
  icon,
  collection,
  isFirstItem,
  isLastItem,
  url,
  target,
  onClick,
  onItemActionClick,
}: {
  item: SearchIndexResult;
  plainText?: string;
  text: string;
  icon: ReactNode;
  isFirstItem: boolean;
  isLastItem: boolean;
  collection: PinnedCollection;
  url?: string;
  target?: '_blank' | '_self';
  onClick: () => void;
  onItemActionClick: NavBarProps['onCollectionItemActionClick'];
}) {
  const { getContainerProps, getToolbarProps, isToolbarActive } =
    useToolbarState();
  const [showHoverCard, setShowHoverCard] = useState(false);
  const textRef = useRef<HTMLParagraphElement>(null);

  const toolbarOptions = useCollectionItemOptions({
    isFirstItem,
    isLastItem,
    item,
    collection,
  });

  const modifiedUrl = useMemo(() => getModifiedAssemblyLinkURL(url), [url]);

  return (
    <Wrapper
      url={modifiedUrl}
      onClick={onClick}
      target={target}
      text={plainText ?? text}
      showHoverCard={showHoverCard}
      {...getContainerProps({
        onMouseOver: () => {
          if (textRef.current) {
            setShowHoverCard(
              textRef.current.scrollWidth > textRef.current.offsetWidth
            );
          }
        },
        onMouseLeave: () => {
          setShowHoverCard(false);
        },
      })}
    >
      <div
        className={twMerge(
          'flex items-center justify-start gap-2',
          isToolbarActive ? 'col-span-10' : 'col-span-12'
        )}
      >
        <div
          className={twMerge(
            getFolderStyles(collection.colorName).background,
            'h-6 w-1 flex-shrink-0 rounded-full'
          )}
        />

        {icon}
        <TextStyle className="truncate text-left" html={text} ref={textRef} />
      </div>

      <Toolbar
        className={twMerge(
          'hidden items-center justify-self-end',
          isToolbarActive && 'col-span-2 flex'
        )}
        secondaryToolbarItems={toolbarOptions}
        compact
        {...getToolbarProps({
          onMenuItemClick: ({ id: action }) => {
            onItemActionClick({
              action: action as collectionItemActions,
              collectionId: collection.id,
              item,
              url: modifiedUrl,
              text,
            });
          },
        })}
      />
    </Wrapper>
  );
}

export function CollectionEntry({
  item,
  isFirstItem,
  isLastItem,
  collection,
  userDetails,
  openFilePreviewModal,
  onItemActionClick,
}: {
  item: SearchIndexResult;
  isFirstItem: boolean;
  isLastItem: boolean;
  collection: PinnedCollection;
  userDetails: UserDetails;
  openFilePreviewModal: (props: {
    blockId: string;
    flowId: string;
    fileName: string;
    responseId: string;
  }) => void;
  onItemActionClick: NavBarProps['onCollectionItemActionClick'];
}) {
  const { formatMessage } = useIntl();

  const { navigate: navigateToUserFeed } = useNavigateToUserFeed();

  const { openChallengeDrawer } = useChallengeDrawer();

  const { onPostClicked } = usePosts();
  const { onReplyClicked } = useReplies();

  const workspaceSlugPath = userDetails.assembly.workspaceSlugPath;

  const workspaceName = userDetails.assembly.name;

  const currentUserId = userDetails.member.memberId;

  const isNavOpen = useNavStore((state) => state.isOpen);
  const isNavPinned = useNavStore((state) => state.isPinned);
  const hideNav = useNavStore((state) => state.hideNav);

  const onClick = () => {
    if (!isNavPinned && isNavOpen) {
      hideNav();
    }
    trackNavAction('collectionItemClicked', {
      collectionItemType: getMixpanelCollectionItemType(item),
      isSoftPinnedCollection: Boolean(collection.softPin),
    });
  };

  const commonProps = {
    collection,
    isFirstItem,
    isLastItem,
    item,
    onClick,
    onItemActionClick,
  };

  switch (item.type) {
    case 'flow':
    case 'recognition': {
      const { urlSlug, emoji, flowName } = formatAssemblySearchResult(item);
      const url = urlSlug.includes('recognition')
        ? `/a/${workspaceSlugPath}/flows/recognition`
        : `/a/${workspaceSlugPath}${urlSlug}`;

      return <Entry text={flowName} url={url} icon={emoji} {...commonProps} />;
    }

    case 'challenge': {
      const { title, challengeId } = formatAssemblySearchResult(item);

      return (
        <Entry
          {...commonProps}
          text={title}
          icon={
            <img src={Challenge} alt="" className="h-4 w-4 max-md:max-w-none" />
          }
          onClick={() => {
            openChallengeDrawer(challengeId);
          }}
        />
      );
    }

    case 'member': {
      const { memberName, iconUrl, fullName, memberID, urlSlug } =
        formatAssemblySearchResult(item);

      return (
        <ProfileViewer
          userDetails={{ memberID, firstName: memberName, lastName: '' }}
          onViewProfileClick={navigateToUserFeed}
        >
          <div className="w-full">
            <Entry
              text={memberName}
              icon={
                <Avatar
                  size={AvatarSize.ExtraSmall}
                  image={iconUrl}
                  name={fullName}
                  memberID={memberID}
                />
              }
              url={`/a/${workspaceSlugPath}${urlSlug}`}
              {...commonProps}
            />
          </div>
        </ProfileViewer>
      );
    }

    case 'weblink': {
      const { isRecognitionFlow, title, url, plainUrl } =
        formatAssemblySearchResult(item, workspaceSlugPath);
      return (
        <Entry
          text={title || url}
          url={plainUrl}
          target={isRecognitionFlow ? '_self' : '_blank'}
          icon={
            isRecognitionFlow ? (
              <div className="px-[1px]">🎉</div>
            ) : (
              <img
                src={getFaviconSource(plainUrl)}
                alt=""
                className="h-4 w-4"
              />
            )
          }
          {...commonProps}
        />
      );
    }

    case 'url': {
      const { title, type, url } = formatAssemblySearchResult(item);

      return (
        <Entry
          text={title}
          url={type === 'admin' ? `${config.domains.adminApp}${url}` : url}
          icon={
            <img
              src={assemblyLogo}
              alt=""
              className="h-4 w-4 max-md:max-w-none"
            />
          }
          {...commonProps}
        />
      );
    }

    case 'file': {
      if (item.source !== 'assembly') {
        const { plainFileName, fileType, integrationIcon, remoteLocation } =
          formatAssemblySearchResult(item);

        return (
          <Entry
            text={plainFileName}
            url={remoteLocation}
            target="_blank"
            icon={
              <img
                src={getFileIcon(fileType) ?? integrationIcon}
                alt=""
                className="h-4 w-4"
              />
            }
            {...commonProps}
          />
        );
      } else {
        if (isChallengeFile(item)) {
          const { iconLabel, internalFileName } =
            formatAssemblySearchResult(item);
          return (
            <Entry
              text={internalFileName}
              icon={
                <img
                  src={fileIcons[iconLabel]}
                  className="max-md:h-4 max-md:w-4 max-md:max-w-none"
                  alt=""
                />
              }
              {...commonProps}
              onClick={() => {
                // TODO: @meghana to add previewer changes for challenges files once PR:#2824 is merged
                onClick();
              }}
            />
          );
        }
        const {
          formattedOriginalFileName,
          blockId,
          flowId,
          internalFileName,
          responseId,
          iconLabel,
        } = formatAssemblySearchResult(item);

        return (
          <Entry
            text={formattedOriginalFileName}
            icon={
              <img
                src={fileIcons[iconLabel]}
                className="max-md:h-4 max-md:w-4 max-md:max-w-none"
                alt=""
              />
            }
            {...commonProps}
            onClick={() => {
              openFilePreviewModal({
                blockId,
                flowId,
                fileName: internalFileName,
                responseId,
              });
              onClick();
            }}
          />
        );
      }
    }

    case 'task': {
      const { taskTitle, urlSlug, state } = formatAssemblySearchResult(item);
      const title = formatMessage(msgs.taskTitle, { taskTitle });

      let iconClassName = 'h-4 w-4 max-md:max-w-4 stroke-2';
      let titleText = `<span class="text-base font-normal truncate">${title}</span>`;

      if (state === 'COMPLETED') {
        iconClassName += ' rounded-lg border-none bg-success-7 text-gray-1';
        titleText = `<span class="line-through text-base font-normal truncate">${title}</span>`;
      } else if (state === 'ARCHIVED') {
        iconClassName += ' rounded-lg border-none bg-gray-3 text-gray-7';
        titleText = `<span class="line-through text-base font-normal truncate text-gray-7">${title}</span>`;
      } else {
        titleText = `<span class="text-base font-normal truncate">${title}</span>`;
      }

      const getIcon = () => {
        if (state === 'ACTIVE' || state === 'COMPLETED') {
          iconClassName +=
            state === 'ACTIVE' ? ' hover:text-success-7' : ' hover:bg-gray-8';

          return (
            <button onClick={onClick}>
              <CheckCircleIconOutline className={iconClassName} />
            </button>
          );
        }
        return <CheckCircleIconOutline className={iconClassName} />;
      };

      return (
        <Entry
          text={titleText}
          plainText={title}
          url={`/a/${workspaceSlugPath}${urlSlug}`}
          icon={getIcon()}
          {...commonProps}
        />
      );
    }

    case 'comment': {
      if (checkIfCommentKindMatches(item, 'challenge')) {
        const card = { ...formatAssemblySearchResult(item) };
        const baseUrl = config.domains.app;
        const commentId = item.cardDetails.commentID;
        const challengeId = item.cardDetails.challengeId;
        return (
          <Entry
            text={formatMessage(msgs.challengeComment, {
              author:
                item.cardDetails.fromMember.memberID === currentUserId
                  ? 'self'
                  : item.cardDetails.fromMember.name,
              workspaceName,
              challenge: item._meta.challengeDetails.title,
            })}
            url={
              commentId && challengeId
                ? `${baseUrl}/a/discover?${new URLSearchParams([
                    ['commentId', commentId],
                    ['filter', 'challenges'],
                    ['challengeId', challengeId],
                  ])}`
                : ''
            }
            icon={
              <ChatBubbleBottomCenterTextIcon className="h-4 w-4 stroke-2 max-md:max-w-none" />
            }
            {...commonProps}
            {...card}
            onClick={() => {
              openChallengeDrawer(challengeId);
            }}
          />
        );
      }
      const {
        authorId,
        author,
        flow,
        post,
        postId,
        responseId,
        flowId,
        commentId,
      } = formatAssemblySearchResult(item);

      return (
        <Entry
          text={formatMessage(msgs.comment, {
            author: authorId === currentUserId ? 'self' : author,
            flow,
            workspaceName,
            post,
          })}
          icon={
            <ChatBubbleBottomCenterTextIcon className="h-4 w-4 stroke-2 max-md:max-w-none" />
          }
          {...commonProps}
          onClick={() => {
            const openReplyPayload: PostData = postId
              ? { type: 'post', postId, commentId }
              : {
                  type: 'flow',
                  flowId: flowId,
                  responseId: responseId,
                  commentId,
                };
            onReplyClicked(openReplyPayload);
          }}
        />
      );
    }

    case 'response': {
      const { flow, authorId, author, flowId, responseId } =
        formatAssemblySearchResult(item);

      return (
        <Entry
          text={formatMessage(msgs.postByLabel, {
            flow,
            workspaceName,
            author: authorId === currentUserId ? 'self' : author,
          })}
          icon={
            <img src={postIcon} alt="" className="h-4 w-4 max-md:max-w-none" />
          }
          {...commonProps}
          onClick={() => {
            onPostClicked({
              type: 'flow',
              flowId,
              responseId,
            });
          }}
        />
      );
    }
    case 'post': {
      const {
        flow,
        authorId,
        author,
        postType,
        celebratedTeammate,
        celebratedTeammateId,
        postId,
      } = formatAssemblySearchResult(item);

      let text = formatMessage(msgs.postByLabel, {
        flow,
        workspaceName,
        author: authorId === userDetails.member.memberId ? 'self' : author,
      });

      if (postType === 'birthday') {
        text = formatMessage(msgs.birthDayPostLabel, {
          flow,
          workspaceName,
          celebratedTeammate:
            celebratedTeammateId === currentUserId
              ? 'self'
              : celebratedTeammate,
        });
      } else if (postType === 'anniversary') {
        text = formatMessage(msgs.anniversaryDayPostLabel, {
          flow,
          author,
          workspaceName,
          celebratedTeammate:
            celebratedTeammateId === currentUserId
              ? 'self'
              : celebratedTeammate,
        });
      }

      return (
        <Entry
          text={text}
          icon={
            <img src={postIcon} alt="" className="h-4 w-4 max-md:max-w-none" />
          }
          {...commonProps}
          onClick={() => {
            onPostClicked({
              type: 'post',
              postId,
            });
          }}
        />
      );
    }
    default:
      break;
  }
}
