import {
  type CollectionItem,
  copyToClipboard,
  type Folder,
} from '@assembly-web/services';
import { useToastStore } from '@assembly-web/ui';
import { LinkIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useCollectionItemsMutation } from '../../modules/discover/hooks/useCollectionItemsMutation';
import { useFolderItemDetails } from './useFolderItemDetails';

enum FolderMenuItemActions {
  CopyLink = 'copy-link',
  RemoveItem = 'remove-item',
}

const messages = defineMessages({
  copyLink: {
    defaultMessage: 'Copy link',
    id: 'lbr3Lq',
  },
  removeItem: {
    defaultMessage: 'Remove item',
    id: '3dwSlf',
  },
  removedItemMessage: {
    defaultMessage: 'Removed {itemName} from {folderName}',
    id: '+kmgIR',
  },
  copiedLink: {
    defaultMessage: 'Link copied to clipboard',
    id: '2yCGR2',
  },
});

export function useFolderItemMenu(folder: Folder) {
  const { formatMessage } = useIntl();

  const { mutate: removeFromFolder } = useCollectionItemsMutation();

  const { showSuccessToast } = useToastStore();

  const getFolderDetails = useFolderItemDetails();

  const { allowedEditing } = folder;

  const folderItemMenuOptions = useMemo(
    () =>
      [
        {
          id: FolderMenuItemActions.CopyLink,
          text: formatMessage(messages.copyLink),
          icon: LinkIcon,
        },
        allowedEditing && {
          id: FolderMenuItemActions.RemoveItem,
          text: formatMessage(messages.removeItem),
          icon: XMarkIcon,
        },
      ].filter(Boolean),
    [allowedEditing, formatMessage]
  );

  const handleFolderItemMenuAction = useCallback(
    (item: CollectionItem, action: string) => {
      if (action === FolderMenuItemActions.CopyLink) {
        const linkToCopy = getFolderDetails(item)?.url;

        if (linkToCopy) {
          let url = new URL(window.location.href);

          if (linkToCopy.startsWith('/')) {
            const [path, search] = linkToCopy.split('?');
            url.pathname = path;
            url.search = new URLSearchParams(search).toString();
          } else {
            url = new URL(linkToCopy);
          }

          console.dir(url.toString());

          copyToClipboard(url.toString());
          showSuccessToast(formatMessage(messages.copiedLink));
        }
      } else if (action === FolderMenuItemActions.RemoveItem) {
        removeFromFolder({
          action: 'remove',
          collectionName: folder.name,
          collectionId: folder.id,
          name: item.name,
          type: item.type,
          entityId: item.id,
        });
        showSuccessToast(
          formatMessage(messages.removedItemMessage, {
            itemName: item.name,
            folderName: folder.name,
          })
        );
      }
    },
    [
      folder.id,
      folder.name,
      formatMessage,
      getFolderDetails,
      removeFromFolder,
      showSuccessToast,
    ]
  );

  return { folderItemMenuOptions, handleFolderItemMenuAction };
}
