import type { Folder } from '@assembly-web/services';
import type { ToolbarItem } from '@assembly-web/ui';
import {
  EyeIcon,
  EyeSlashIcon,
  LinkIcon,
  MagnifyingGlassPlusIcon,
  PencilIcon,
  TrashIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { useCallback, useContext, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import invariant from 'tiny-invariant';

import { AddToFolder } from '../../components/Nav/Folder/AddToFolder';
import { ShareCollectionsMessageKey } from '../../modules/discover/components/modals/ShareCollectionModal';
import { CollectionModalsContext } from '../../modules/discover/contexts/CollectionModalsContext';
import { useFolders } from '../../modules/discover/hooks/useGetCollectionsQuery';
import { useFolderVisibility } from './useFolderVisibility';
import { useHiddenFolders } from './useHiddenFolders';

const menuItemsMessages = defineMessages({
  addLink: {
    defaultMessage: 'Add link',
    id: 'JnxkKM',
  },
  addItem: {
    defaultMessage: 'Add item',
    id: 'KDO3hW',
  },
  share: {
    defaultMessage: 'Share',
    id: 'OKhRC6',
  },
  editDetails: {
    defaultMessage: 'Edit details',
    id: 'BVWzJ5',
  },
  hide: {
    defaultMessage: 'Hide',
    id: 'VA/Z1S',
  },
  unhide: {
    defaultMessage: 'Unhide',
    id: 'MQb+Jc',
  },
  delete: {
    defaultMessage: 'Delete',
    id: 'K3r6DQ',
  },
});

enum FolderMenuActions {
  AddLink = 'add-link',
  AddItem = 'add-item',
  Share = 'share',
  EditDetails = 'edit-details',
  Hide = 'hide',
  Unhide = 'unhide',
  Delete = 'delete',
}

export function useFolderMenu(folder: Folder) {
  const { formatMessage } = useIntl();

  const { data: collections } = useFolders();

  const collection = useMemo(
    () => collections.data.find((c) => c.collectionId === folder.id),
    [collections, folder.id]
  );

  const {
    openAddExternalLinkModal,
    openDeleteModal: openDeleteCollectionModal,
    openCreateCollectionModal,
  } = useContext(CollectionModalsContext);

  const { hiddenFolders } = useHiddenFolders();

  const isHiddenFolder = useMemo(
    () => hiddenFolders.some((hiddenFolder) => hiddenFolder.id === folder.id),
    [folder.id, hiddenFolders]
  );

  const { mutate: updateFolderVisibility } = useFolderVisibility();

  const { allowedEditing, allowedSharing } = folder;

  const replacedContent = useCallback(
    (onBack: () => void) => {
      if (!collection?.collectionId || !collection.name) {
        return null;
      }

      return (
        <AddToFolder
          folderId={collection.collectionId}
          folderName={collection.name}
          onBack={onBack}
        />
      );
    },
    [collection]
  );

  const folderToolbarItems: ToolbarItem[] = useMemo(
    () =>
      [
        allowedEditing && {
          id: FolderMenuActions.AddLink,
          text: formatMessage(menuItemsMessages.addLink),
          icon: LinkIcon,
        },
        allowedEditing && {
          id: FolderMenuActions.AddItem,
          text: formatMessage(menuItemsMessages.addItem),
          icon: MagnifyingGlassPlusIcon,
          replacedContent,
        },
        allowedEditing &&
          allowedSharing && {
            id: FolderMenuActions.Share,
            text: formatMessage(menuItemsMessages.share),
            icon: UserPlusIcon,
          },
        allowedEditing && {
          id: FolderMenuActions.EditDetails,
          text: formatMessage(menuItemsMessages.editDetails),
          icon: PencilIcon,
        },
        isHiddenFolder && {
          id: FolderMenuActions.Unhide,
          text: formatMessage(menuItemsMessages.unhide),
          icon: EyeIcon,
        },
        !isHiddenFolder && {
          id: FolderMenuActions.Hide,
          text: formatMessage(menuItemsMessages.hide),
          icon: EyeSlashIcon,
        },
        allowedEditing && {
          id: FolderMenuActions.Delete,
          text: formatMessage(menuItemsMessages.delete),
          icon: TrashIcon,
          isAlertStyle: true,
        },
      ].filter(Boolean),
    [
      allowedEditing,
      formatMessage,
      replacedContent,
      allowedSharing,
      isHiddenFolder,
    ]
  );

  const onMenuItemClick = useCallback(
    (args: ToolbarItem) => {
      invariant(collection, 'Collection not found');
      switch (args.id) {
        case FolderMenuActions.AddLink:
          openAddExternalLinkModal(collection);

          break;
        case FolderMenuActions.Share:
          window.postMessage(
            {
              type: ShareCollectionsMessageKey,
              payload: {
                collectionId: collection.collectionId,
                createdBy: collection.createdBy ?? undefined,
              },
            },
            window.location.origin
          );
          break;
        case FolderMenuActions.EditDetails:
          openCreateCollectionModal(collection);
          break;
        case FolderMenuActions.Delete:
          openDeleteCollectionModal(collection);
          break;
        case FolderMenuActions.Hide:
          updateFolderVisibility({
            id: folder.id,
            hide: true,
            folderName: folder.name,
          });
          break;
        case FolderMenuActions.Unhide:
          updateFolderVisibility({
            id: folder.id,
            hide: false,
            folderName: folder.name,
          });
          break;
        default:
          break;
      }
    },
    [
      collection,
      folder.id,
      folder.name,
      openAddExternalLinkModal,
      openCreateCollectionModal,
      openDeleteCollectionModal,
      updateFolderVisibility,
    ]
  );

  return { folderToolbarItems, onMenuItemClick };
}
