import type {
  CollectionItemsAPIResponse,
  LinkItem,
} from '@assembly-web/services';
import {
  APIEndpoints,
  assemblyAPI,
  config,
  logger,
  useUserDetails,
} from '@assembly-web/services';
import { useToastStore } from '@assembly-web/ui';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { produce } from 'immer';
import { defineMessages, useIntl } from 'react-intl';

import { trackDiscoverError } from '../services/analytics';
import {
  addFlowToNavFolder,
  navItemsQueryKey,
  removeFlowFromNavFolder,
  removeItemFromFoldersFeed,
} from './nav/useNavData';

const messages = defineMessages({
  removeError: {
    defaultMessage:
      "Failed to remove item from ''{collectionName}'. Please try again.",
    id: '7nmhsX',
  },
  removeSuccess: {
    defaultMessage: "Item successfully removed from ''{collectionName}'.",
    id: 'Ty/Jdg',
  },
  addSuccess: {
    defaultMessage: "Item successfully added to ''{collectionName}'.",
    id: 'Dm5jXT',
  },
  addError: {
    defaultMessage:
      "Failed to add item to ''{collectionName}'. Please try again.",
    id: 'kDTyO4',
  },
  editError: {
    defaultMessage:
      "Failed to update item in ''{collectionName}'. Please try again.",
    id: 'Fm4f1B',
  },
  editSuccess: {
    defaultMessage: "Item successfully updated in ''{collectionName}'.",
    id: 'wGBe4C',
  },
  itemExists: {
    defaultMessage: "This item is already in ''{collectionName}'.",
    id: 'z6x6mA',
  },
});

type CollectionItemsUpdate = {
  action: 'add' | 'remove' | 'edit';
  collectionName: string;
  collectionId: string;
  entityId: string;
  link?: LinkItem;
  name?: string;
  responseId?: string;
  type: string;
  enableSuccessToast?: boolean;
  icon?: string;
};

export function useCollectionItemsMutation() {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { showErrorToast, showSuccessToast, showInfoToast } = useToastStore();
  const { data: userDetails } = useUserDetails();
  const workspaceSlugPath = userDetails?.assembly.workspaceSlugPath;

  return useMutation({
    mutationFn: async ({
      collectionId,
      action,
      type,
      entityId,
      link,
      responseId,
    }: CollectionItemsUpdate) => {
      let payload;
      // for adding recognition flow, we must create it as a weblink
      if (entityId.includes('recognition') && action === 'add') {
        payload = {
          type: 'link',
          link: {
            url: `${config.domains.app}/${workspaceSlugPath}/flows/recognition`,
            title: 'Give Recognition',
          },
        };
      } else {
        payload =
          link && action === 'add'
            ? { type, link }
            : link && action === 'edit'
              ? { type, link, entityId }
              : { type, entityId, responseId };
      }

      return assemblyAPI.put(
        APIEndpoints.updateCollectionItems({ collectionId, action }),
        payload
      );
    },
    onMutate: (variables) => {
      const queryKey = ['collectionItems', variables.collectionId];
      const previousCollectionItems =
        queryClient.getQueryData<CollectionItemsAPIResponse>(queryKey);

      if (variables.action === 'add') {
        // This is required for showing the selection in the Add to Folder pop over optimistically
        const updatedCollectionItems = produce(
          previousCollectionItems,
          (draft) => {
            if (draft?.data) {
              draft.data.push(
                variables.type === 'flow'
                  ? {
                      id: variables.entityId,
                      name: '',
                      _meta: {
                        description: '',
                        entityId: '',
                        icon: '',
                        name: '',
                        ownerId: '',
                      },
                      type: 'flow',
                    }
                  : {
                      id: variables.entityId,
                      type: 'file',
                      name: '',
                      _meta: {
                        entityId: variables.entityId,
                        blockId: '',
                        filePath: '',
                        flowDetails: {
                          icon: '',
                          flowId: '',
                          name: '',
                        },
                        name: '',
                        originalName: '',
                        responseDetails: {
                          kind: '',
                          respondent: {
                            email: '',
                            fullName: '',
                            id: '',
                            image: '',
                          },
                        },
                        responseId: '',
                        type: '',
                        uploadedAt: '',
                      },
                    }
              );
            }
          }
        );
        queryClient.setQueryData(queryKey, updatedCollectionItems);

        if (variables.type === 'flow') {
          addFlowToNavFolder({
            collectionId: variables.collectionId,
            queryClient,
            flowName: variables.name ?? '',
            flowIcon: variables.icon ?? '',
            entityId: variables.entityId,
          });
        }
      } else if (variables.action === 'edit') {
        const updatedCollectionItems = produce(
          previousCollectionItems,
          (draft) => {
            if (draft?.data) {
              draft.data = draft.data.map((item) => {
                if (item.type === 'link' && item.id === variables.entityId) {
                  return {
                    ...item,
                    _meta: { ...item._meta, ...variables.link },
                  };
                }
                return item;
              });
            }
          }
        );
        queryClient.setQueryData(queryKey, updatedCollectionItems);
      } else {
        // This is required for showing the selection in the Add to Folder pop over optimistically
        removeItemFromFoldersFeed({
          queryClient,
          entityId: variables.entityId,
          collectionId: variables.collectionId,
        });
        removeFlowFromNavFolder({
          queryClient,
          entityId: variables.entityId,
          collectionId: variables.collectionId,
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: navItemsQueryKey,
      });
    },
    onSuccess: (_, { enableSuccessToast = true, ...variables }) => {
      if (enableSuccessToast) {
        showSuccessToast(
          formatMessage(
            variables.action === 'add'
              ? messages.addSuccess
              : variables.action === 'edit'
                ? messages.editSuccess
                : messages.removeSuccess,
            {
              collectionName: variables.collectionName,
            }
          )
        );
      }
    },
    onError: (err: unknown, variables) => {
      const errorInfo = err instanceof AxiosError ? err : undefined;

      if (errorInfo?.response?.data.body === 'Item exists in the collection') {
        showInfoToast(
          formatMessage(messages.itemExists, {
            collectionName: variables.collectionName,
          })
        );
      } else {
        showErrorToast(
          formatMessage(
            variables.action === 'add'
              ? messages.addError
              : variables.action === 'edit'
                ? messages.editError
                : messages.removeError,
            {
              collectionName: variables.collectionName,
            }
          )
        );
        trackDiscoverError({
          action:
            variables.action === 'add'
              ? 'addToCollection'
              : variables.action === 'edit'
                ? 'editLink'
                : 'removeFromCollection',
        });

        logger.error(
          'Error on updating collection item',
          { ...variables, error: errorInfo },
          errorInfo
        );
      }
    },
  });
}
