import { SplitNames, useFeatureSplit } from '@assembly-web/services';
import { TextStyle } from '@assembly-web/ui';
import { SparklesIcon } from '@heroicons/react/24/outline';
import { FormattedMessage } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { useCreateAskDoraDrawer } from '../../modules/discover/components/Drawers/AskDoraDrawer/hooks/useCreateAskDoraDrawer';

export function AskDoraCTA({ onClick }: { onClick?: () => void }) {
  const { isTreatmentActive: isAskDoraPopoutEnabled } = useFeatureSplit(
    SplitNames.AskDoraPopout
  );

  const handleAskDoraCTAClick = useCreateAskDoraDrawer();

  if (!isAskDoraPopoutEnabled) {
    return null;
  }

  return (
    <div className="md:mr-4">
      <button
        onClick={() => {
          onClick?.();
          handleAskDoraCTAClick({
            question: 'What can DoraAI do for me?',
          });
        }}
        className={twJoin(
          'flex max-h-10 w-full cursor-pointer items-center justify-center gap-1 rounded-lg py-[3px] pl-[3px] pr-[11px] disabled:cursor-not-allowed',
          'bg-[linear-gradient(88deg,rgba(29,63,200,0.20)_-15.88%,rgba(83,29,171,0.20)_40.52%,rgba(163,9,202,0.20)_130.45%)]'
        )}
      >
        <span className="flex h-8 w-8 items-center justify-center p-2">
          <SparklesIcon className="stroke-2 text-purple-8" />
        </span>

        <TextStyle variant="base-medium" as="span" className="text-purple-8">
          <FormattedMessage defaultMessage="Ask DoraAI" id="71+qWd" />
        </TextStyle>
      </button>
    </div>
  );
}
