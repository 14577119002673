import type {
  FlowStatusToDisplay,
  UserFeedApiResponse,
} from '@assembly-web/services';
import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import type { InfiniteData } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';

import type { SearchIndexApiResponse } from './useSearchIndex';

export function useChangeFlowStatusQuery({
  flowStatus,
}: {
  flowStatus: FlowStatusToDisplay;
}) {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();
  return useMutation({
    mutationFn: async (payload: { flowId: string }) => {
      const { flowId } = payload;
      return assemblyAPI.put(
        flowStatus === 'archive'
          ? APIEndpoints.archiveFlow(flowId)
          : APIEndpoints.unArchiveFlow(flowId)
      );
    },
    onSuccess: (_, { flowId }) => {
      const userFeedQueryKeys = queryCache
        .findAll({
          queryKey: ['userFeed'],
        })
        .map((query) => query.queryKey);
      userFeedQueryKeys.forEach((queryKey) => {
        const userFeedData: InfiniteData<UserFeedApiResponse> | undefined =
          queryClient.getQueryData(queryKey);
        if (userFeedData) {
          const mutatedUserFeedData: InfiniteData<UserFeedApiResponse> = {
            ...userFeedData,
            pages: userFeedData.pages.map((page) => {
              return {
                ...page,
                data: page.data
                  .map((post) => {
                    if (post.id === flowId && post.type === 'flow') {
                      const updatedPost = produce(post, (draft) => {
                        if (draft.state === 'INACTIVE') {
                          draft.state = 'ACTIVE';
                        } else {
                          draft.state = 'INACTIVE';
                        }
                      });
                      return updatedPost;
                    }
                    return post;
                  })
                  // Sort the posts by type and then by name
                  .sort((a, b) => {
                    if (a.type === 'flow' && b.type === 'flow') {
                      if (a.state === b.state) {
                        return a._meta.name.localeCompare(b._meta.name);
                      } else {
                        return a.state === 'ACTIVE' ? -1 : 1;
                      }
                    }
                    return 0;
                  }),
              };
            }),
          };
          queryClient.setQueryData(queryKey, mutatedUserFeedData);
        }
      });

      const searchFeedQueryKeys = queryCache
        .findAll({
          queryKey: ['searchResults'],
        })
        .map((query) => query.queryKey);
      searchFeedQueryKeys.forEach((queryKey) => {
        const searchData =
          queryClient.getQueryData<InfiniteData<SearchIndexApiResponse>>(
            queryKey
          );
        if (searchData) {
          const mutatedSearchData: InfiniteData<SearchIndexApiResponse> = {
            ...searchData,
            pages: searchData.pages.map((page) => {
              return {
                ...page,
                data: {
                  ...page.data,
                  data: page.data.data
                    .map((result) => {
                      if (result.id === flowId && result.type === 'flow') {
                        const updatedPost = produce(result, (draft) => {
                          if (draft.state === 'INACTIVE') {
                            draft.state = 'ACTIVE';
                          } else {
                            draft.state = 'INACTIVE';
                          }
                        });
                        return updatedPost;
                      }
                      return result;
                    }) // Sort the posts by type and then by name
                    .sort((a, b) => {
                      if (a.type === 'flow' && b.type === 'flow') {
                        if (a.state === b.state) {
                          return a._meta.name.localeCompare(b._meta.name);
                        } else {
                          return a.state === 'ACTIVE' ? -1 : 1;
                        }
                      }
                      return 0;
                    }),
                },
              };
            }),
          };
          queryClient.setQueryData(queryKey, mutatedSearchData);
        }
      });
    },
  });
}
