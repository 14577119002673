import {
  APIEndpoints,
  type CollectionItemsAPIResponse,
} from '@assembly-web/services';
import { assemblyAPI } from '@assembly-web/services';
import type { UseQueryOptions } from '@tanstack/react-query';

export function getCollectionItemsQuery(
  collectionId: string,
  options?: UseQueryOptions<CollectionItemsAPIResponse>
): UseQueryOptions<CollectionItemsAPIResponse> {
  return {
    ...options,
    queryKey: ['collectionItems', collectionId],
    queryFn: async () => {
      const response = await assemblyAPI.get<CollectionItemsAPIResponse>(
        APIEndpoints.listCollectionItems(collectionId)
      );
      return response.data;
    },
  };
}
