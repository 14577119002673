import {
  AllowanceType,
  getUserMaxGivingPointsPerPost,
  MemberStatus,
  useUserDetails,
} from '@assembly-web/services';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import invariant from 'tiny-invariant';

import { getPostQuery } from '../../../../../../queries/getAssemblyPostQuery';
import { useGetChallengeDetailsByIdQuery } from '../../../../hooks/challenges/useGetChallengeDetailsByIdQuery';

const messages = defineMessages({
  boostDisabledNoAllowance: {
    defaultMessage: 'You are out of {currency} to give for this month.',
    id: 'Rgc6rs',
  },
  boostDisabledByAdmin: {
    defaultMessage: 'Boost disabled for this flow',
    id: 'Fc9hM3',
  },
});

type BoostOptionsArgs =
  | {
      flowId: string;
      responseId: string;
    }
  | {
      challengeId: string;
    };

export function useBoostOptions(props: BoostOptionsArgs) {
  const { formatMessage } = useIntl();
  const { data: currentUser } = useUserDetails();

  invariant(currentUser);

  const { member, assembly } = currentUser;

  const flowId = 'flowId' in props ? props.flowId : '';
  const responseId = 'responseId' in props ? props.responseId : '';
  const challengeId = 'challengeId' in props ? props.challengeId : '';

  const { data: postDetails } = useQuery({
    ...getPostQuery({
      flowId,
      responseId,
      asEmbed: false,
      currencyName: currentUser.assembly.currency.pluralName,
    }),
    enabled: Boolean(flowId),
  });

  const { data: challengeDetails } =
    useGetChallengeDetailsByIdQuery(challengeId);

  const isBoostAllowed = useMemo(() => {
    return postDetails?.isBoostAllowed ?? challengeDetails?.isBoostAllowed;
  }, [challengeDetails, postDetails]);

  const hideBoost = useMemo(() => {
    const { status, allowance } = member;

    const isObserverOrReceiver =
      status.includes(MemberStatus.Observer) ||
      status.includes(MemberStatus.Receiver);

    const hasNoAllowance = allowance.type === AllowanceType.NoAllowance;
    const hasCustomAllowanceWithZeroPoints =
      allowance.type === AllowanceType.Custom && allowance.points === 0;

    return (
      isObserverOrReceiver || hasNoAllowance || hasCustomAllowanceWithZeroPoints
    );
  }, [member]);

  const disableBoostOptions = useMemo(() => {
    const { currency } = assembly;
    const { pointsLeftThisCycle } = member;

    if (!isBoostAllowed) {
      return {
        disableBoost: true,
        disableBoostReason: formatMessage(messages.boostDisabledByAdmin),
      };
    }

    if (pointsLeftThisCycle === 0) {
      return {
        disableBoost: true,
        disableBoostReason: formatMessage(messages.boostDisabledNoAllowance, {
          currency: currency.pluralName,
        }),
      };
    }

    return {
      disableBoost: false,
      disableBoostReason: '',
    };
  }, [assembly, member, isBoostAllowed, formatMessage]);

  return {
    hideBoost,
    currentUser,
    ...disableBoostOptions,
    maxGivingPointsPerPost: getUserMaxGivingPointsPerPost(currentUser),
  };
}
