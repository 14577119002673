import type { ActivityEntity } from '@assembly-web/services';
import { UserActivitySortType } from '@assembly-web/services';
import type { GlobalFilterOption } from '@assembly-web/ui';
import { matchPath } from 'react-router-dom';

import type { TrackEventProps } from '../../../services/analytics';
import { trackAction } from '../../../services/analytics';
import type { ToolbarMenuClickEventArgs } from '../components/cards/type';
import { getUrlPathForCards, ToolbarItemId } from '../components/cards/utils';

type MixPanelCardEventArgs = {
  card?: ActivityEntity | null;
  actionName: CardAction;
  workspaceSlugPath: string;
  searchTerm: string;
  cardPosition: number;
  sortMethod: string | null;
  directReport?: boolean;
  filter: GlobalFilterOption;
};

type MixpanelToolbarClickEventArgs = {
  item: ToolbarMenuClickEventArgs;
  workspaceSlugPath: string;
  searchTerm: string;
  cardPosition: number;
  sortMethod: string | null;
  filter: GlobalFilterOption;
  directReport?: boolean;
};

type EventProps = {
  cardType?: string;
  flowId?: string;
  flowName?: string;
  isNotification: boolean;
  cardDestinationUrl: string;
  cardPosition: number;
  searchQuery: string;
  sortMethod: string | null;
  filterType: GlobalFilterOption;
};

type SharesheetAction =
  | 'removeMemberClicked'
  | 'inviteMemberClicked'
  | 'previewListClicked'
  | 'getHelpClicked'
  | 'shareSettingsSaved';

type AnnouncementAction =
  | 'announcementColorChanged'
  | 'announcementCreated'
  | 'announcementChangesSaved'
  | 'downloadInsightsDataClicked'
  | 'insightsSearched'
  | 'insightsFiltered';

export type CardAction =
  | 'carouselPostClicked'
  | 'carouselScrollClicked'
  | 'cardClicked'
  | 'markAsSeenClicked'
  | 'linkCopied'
  | 'linkShared'
  | 'viewFeedClicked'
  | 'startPostClicked'
  | 'finishDraft'
  | 'editFlowClicked'
  | 'viewFlowInsightsClicked'
  | 'postReactionAdded'
  | 'postReactionRemoved'
  | 'viewRepliesClicked'
  | 'viewThreadClicked'
  | 'replySent'
  | 'followThreadClicked'
  | 'unfollowThreadClicked'
  | 'shareRequestApproved'
  | 'shareRequestDenied'
  | 'goToNotebookClicked'
  | 'taskCompleted'
  | 'taskIncomplete'
  | 'taskArchived'
  | 'taskUnarchved'
  | 'taskDeleted'
  | 'memberClicked'
  | 'fileClicked'
  | 'hideRepliesClicked'
  | 'showRepliesClicked'
  | 'hideReactionsClicked'
  | 'showReactionsClicked'
  | 'editPostInteractionsClicked'
  | 'addToCollection'
  | 'deleteCollection'
  | 'editCollection'
  | 'removeFromCollection'
  | 'shareAsAnnouncementClicked'
  | 'editAnnouncementClicked'
  | 'viewInsightsClicked'
  | 'endAnnouncementClicked'
  | 'goToAnnouncementClicked'
  | 'copyAnnouncementLinkClicked'
  | 'descriptionOpened'
  | 'shareCollectionClicked'
  | 'pinCollectionClicked'
  | 'unpinCollectionClicked'
  | 'collectionItemClicked'
  | 'redeemRewardClicked'
  | 'rewardRedeemed'
  | 'seeRedemptionDetailsClicked'
  | 'rewardsResendEmailClicked'
  | 'rewardsRemindAdminClicked'
  | 'flowClicked'
  | 'profileNameClicked'
  | 'replyReactionAdded'
  | 'replyReactionRemoved'
  | 'seePostClicked'
  | 'pinItemClicked'
  | 'editReplyClicked'
  | 'deleteReplyClicked'
  | 'replyLinkCopied'
  | 'replyButtonClicked'
  | 'mentionedMemberClicked'
  | 'postCollapsed'
  | 'postExpanded'
  | 'editPostClicked'
  | 'deletePostClicked'
  | 'replyStarted'
  | 'replyPosted'
  | 'replyEdited'
  | 'replyEditCancelled'
  | 'replyDeleted'
  | 'getDiscountsClicked'
  | 'viewClaimsClicked'
  | 'claimChallengeClicked'
  | 'endChallengeClicked'
  | 'endChallengeCancelled'
  | 'endChallengeConfirmed'
  | 'launchChallengeClicked'
  | 'launchChallengeConfirmed'
  | 'launchChallengeCancelled'
  | 'editChallengeClicked'
  | 'archiveChallengeConfirmed'
  | 'archiveChallengeCancelled'
  | 'unarchiveChallengeConfirmed'
  | 'unarchiveChallengeCancelled'
  | 'unarchiveChallengeClicked'
  | 'archiveChallengeClicked'
  | 'duplicateChallengeClicked'
  | 'viewChallengeInsightsClicked';

export type DiscoverAction =
  | 'searchEnd'
  | 'searchStart'
  | 'sortApplied'
  | 'googleSearch'
  | 'filterApplied'
  | 'searchCleared'
  | 'inviteClicked'
  | 'searchClicked'
  | 'toggleDisabled'
  | 'notebookClicked'
  | 'thumbsUpClicked'
  | 'answerGenerated'
  | 'pointsMenuOpened'
  | 'getAnswerClicked'
  | 'searchWebClicked'
  | 'profileMenuOpened'
  | 'createCollectionClicked'
  | 'createPostClicked'
  | 'createTaskClicked'
  | 'createFlowClicked'
  | 'showAnswerClicked'
  | 'hideAnswerClicked'
  | 'sourceClicked'
  | 'thumbsDownClicked'
  | 'toDoButtonClicked'
  | 'browseRewardsClicked'
  | 'createPostFlowClicked'
  | 'toDoButtonFlowClicked'
  | 'profileMenuItemClicked'
  | 'inviteTeammatesClicked'
  | 'peopleDirectoryClicked'
  | 'ratingConnectToolClicked'
  | 'ratingSendFeedbackClicked'
  | 'loadMoreClicked'
  | 'collectionFilterLoaded'
  | 'createCollection'
  | 'editCollection'
  | 'deleteCollection'
  | 'addToCollection'
  | 'collectionExpanded'
  | 'collectionCollapsed'
  | 'recentSearchClicked'
  | 'whatCanDoraDoClicked'
  | 'followUpPromptClicked'
  | 'suggestedPromptClicked'
  | 'linkAddedToCollection'
  | 'secondaryFilterApplied'
  | 'secondaryFilterCleared'
  | 'rewardFilterLoaded'
  | 'rewardTypeSelected'
  | 'answerSettingsClicked'
  | 'fileTypeFilterClicked'
  | 'dateRangeFilterClicked'
  | 'saveDoraFiltersClicked'
  | 'swapSourcesClicked'
  | 'newSourceClicked'
  | 'actionBarCreateAnnouncementClicked'
  | 'actionBarGiveRecognitionClicked'
  | 'actionBarCreateFlowClicked'
  | 'actionBarStartPostClicked'
  | 'actionBarStartPostFlowSelected'
  | 'createChallengesClicked'
  | 'challengeTabLoaded'
  | 'turnOnChallengesClicked';

export type PopoutAction =
  | 'expandPopoutClicked'
  | 'collapsePopoutClicked'
  | 'exitPopoutClicked'
  | 'expandOverflowClicked'
  | 'collapseOverflowClicked'
  | 'overflowPopoutListItemClicked'
  | 'exitOverflowItemClicked'
  | 'exitAllOverflowItemsClicked'
  | 'fullScreenModalClicked';

export type RewardAction =
  | 'rewardRedeemed'
  | 'rewardRatingClicked'
  | 'rewardRatingSkipped'
  | 'goToG2Clicked';

export type ChallengeAction =
  | 'fileUploaded'
  | 'challengeCreatedLaunched'
  | 'challengeCreatedSaved'
  | 'challengeEditChangesSaved'
  | 'challengeCreationExited'
  | 'challengeClaimSubmitted'
  | 'downloadChallengeDataClicked'
  | 'secondaryFilterApplied'
  | 'secondaryFilterCleared'
  | 'challengeViewProofClicked'
  | 'challengeClaimApproved'
  | 'challengeClaimDenied'
  | 'challengeClaimsSearched'
  | 'challengeClaimsFiltered'
  | 'challengeRemindIndividualClicked'
  | 'challengeRemindAllClicked'
  | 'challengeRemindAllConfirmed'
  | 'challengeRemindAllCancelled';

export type FlowEditorActions =
  | 'createFlowNavClicked'
  | 'createFlowActionBarClicked'
  | 'startFromScratchClicked'
  | 'templateLibraryClicked'
  | 'flowTitleClicked'
  | 'flowDescriptionClicked'
  | 'editOwnerCollaboratorClicked'
  | 'blockTitleClicked'
  | 'requiredClicked'
  | 'blockDeleted'
  | 'blockDuplicated'
  | 'blockMovedUp'
  | 'blockMovedDown'
  | 'blockTypeDropdownClicked'
  | 'blockTypeSwitched'
  | 'blockTypeSwitchConfirmed'
  | 'blockTypeSwitchCancelled'
  | 'editDetailsClicked'
  | 'blockDetailClicked'
  | 'createFlowClicked'
  | 'distributionSectionOpened'
  | 'contentSectionOpened'
  | 'onDeadlineClicked'
  | 'onMilestoneClicked'
  | 'answerAnytimeClicked'
  | 'scheduleItClicked'
  | 'setManuallyClicked'
  | 'onRecurringBasisClicked'
  | 'justOnceClicked'
  | 'setDueDateOpened'
  | 'setDueDateSelected'
  | 'setTimeOpened'
  | 'setTimeSelected'
  | 'setTimeZoneOpened'
  | 'setTimeZoneSelected'
  | 'setResponseTimeOpened'
  | 'setResponseTimeSelected'
  | 'setPostLimitOpened'
  | 'setPostLimitSelected'
  | 'setRepeatOpened'
  | 'setRepeatSelected'
  | 'setEndDateOpened'
  | 'setRemindersStartDate'
  | 'setEndDateConfirmed'
  | 'remindManuallyClicked'
  | 'setAutomatedRemindersClicked'
  | 'remindersPerDeadlineDropdownClicked'
  | 'anytimeRemindersFrequencyDropdownClicked';

export function trackCardAction(
  actionName: CardAction,
  props?: TrackEventProps
) {
  return trackAction({
    eventName: 'card.events',
    eventProps: {
      url: window.location.href,
      event: 'action',
      action: actionName,
      source: 'webDiscover',
      ...props,
    },
  });
}

export function trackDiscoverShow(props?: TrackEventProps) {
  return trackAction({
    eventName: 'discover.events',
    eventProps: {
      event: 'show',
      ...props,
    },
  });
}

export function trackAnnouncementAction(
  actionName: AnnouncementAction,
  props?: TrackEventProps
) {
  return trackAction({
    eventName: 'announcement.events',
    eventProps: {
      event: 'action',
      action: actionName,
      ...props,
    },
  });
}

export function trackDiscoverError(props?: TrackEventProps) {
  return trackAction({
    eventName: 'discover.events',
    eventProps: {
      event: 'error',
      ...props,
    },
  });
}

export function trackDiscoverAction(
  actionName: DiscoverAction,
  props?: TrackEventProps
) {
  return trackAction({
    eventName: 'discover.events',
    eventProps: {
      url: window.location.href,
      event: 'action',
      action: actionName,
      ...props,
    },
  });
}

export function trackNavShow({
  numPinnedCollections,
  numSoftPinnedCollections,
}: {
  numPinnedCollections: number;
  numSoftPinnedCollections: number;
}) {
  return trackAction({
    eventName: 'nav.events',
    eventProps: {
      event: 'show',
      numPinnedCollections,
      numSoftPinnedCollections,
    },
  });
}

export function trackNavError(props?: TrackEventProps) {
  return trackAction({
    eventName: 'nav.events',
    eventProps: {
      event: 'error',
      ...props,
    },
  });
}

export function trackNavAction(
  actionName:
    | 'actionSectionExpanded'
    | 'actionSectionCollapsed'
    | 'addToCollection'
    | 'collectionCollapsed'
    | 'collectionExpanded'
    | 'collectionItemClicked'
    | 'createCollection'
    | 'createPostClicked'
    | 'createTaskClicked'
    | 'createFlowClicked'
    | 'deleteCollection'
    | 'editCollection'
    | 'exploreRewardsClicked'
    | 'importantActivityClicked'
    | 'inviteTeammateClicked'
    | 'linkCopied'
    | 'moveCollectionDown'
    | 'moveCollectionItemDown'
    | 'moveCollectionItemUp'
    | 'moveCollectionUp'
    | 'navCollapsed'
    | 'navExpanded'
    | 'navHovered'
    | 'navPinned'
    | 'navUnpinned'
    | 'pinExistingCollectionClicked'
    | 'removeFromCollection'
    | 'shareCollectionClicked'
    | 'unpinCollection',
  props?: TrackEventProps
) {
  return trackAction({
    eventName: 'nav.events',
    eventProps: {
      url: window.location.href,
      event: 'action',
      action: actionName,
      ...props,
    },
  });
}

export const trackCardClickEventHandler = ({
  card,
  actionName,
  workspaceSlugPath,
  searchTerm,
  cardPosition,
  directReport,
  sortMethod,
  filter,
}: MixPanelCardEventArgs) => {
  const eventProps: EventProps = {
    isNotification: searchTerm === '',
    cardDestinationUrl: card ? getUrlPathForCards(card, workspaceSlugPath) : '',
    cardPosition,
    searchQuery: searchTerm,
    cardType: '',
    flowId: '',
    flowName: '',
    sortMethod,
    filterType: filter,
  };

  if (card) {
    switch (card.type) {
      case 'FLOW_ACTIVITY':
        eventProps.cardType = 'flow';
        eventProps.flowId = card.entity.flowId;
        eventProps.flowName = card.entity.name;
        break;

      case 'POST_MENTIONS':
        eventProps.cardType = 'post';
        eventProps.flowName = card.entity.name;
        break;

      case 'OWNER_POST_REPLY':
        eventProps.cardType = 'post';
        eventProps.flowName = 'Give recognition';
        break;

      case 'RESPONSE_MENTIONS':
        eventProps.cardType = 'post';
        eventProps.flowId = card.entity.flowId;
        eventProps.flowName = card.entity.name;
        break;

      case 'FLOW_ACCESS_REQUEST':
        eventProps.cardType = 'flow';
        eventProps.flowId = card.entity.flowId;
        eventProps.flowName = card.entity.name;
        break;

      case 'POST_THREAD_REPLIES':
        eventProps.cardType = 'post';
        eventProps.flowName = card.entity.name;
        break;

      case 'OWNER_RESPONSE_REPLY':
        eventProps.cardType = 'post';
        eventProps.flowId = card.entity.flowId;
        eventProps.flowName = card.entity.name;
        break;

      case 'RECOGNITION_REMINDERS':
      case 'BIRTHDAY_CELEBRATION_ACTIVITY':
      case 'ANNIVERSARY_CELEBRATION_ACTIVITY':
        eventProps.cardType = 'flow';
        eventProps.flowName = 'Give recognition';
        break;

      case 'RESPONSE_THREAD_REPLIES':
        eventProps.cardType = 'post';
        eventProps.flowId = card.entity.flowId;
        eventProps.flowName = card.entity.name;
        break;
    }
  }
  if (directReport) {
    eventProps.cardType = 'direct report';
  }
  trackCardAction(actionName, { ...eventProps });
};

export const mapToolbarItemToAction = (id: ToolbarItemId) => {
  const toolbarItemMap: Record<string, string> = {
    [ToolbarItemId.Follow]: 'followThreadClicked',
    [ToolbarItemId.Unfollow]: 'unfollowThreadClicked',
    [ToolbarItemId.MarkAsSeen]: 'markAsSeenClicked',
    [ToolbarItemId.CopyLink]: 'linkCopied',
    [ToolbarItemId.EditFlow]: 'editFlowClicked',
    [ToolbarItemId.ShareLink]: 'linkShared',
    [ToolbarItemId.ViewFlowInsights]: 'viewFlowInsightsClicked',
    [ToolbarItemId.GoToNotebook]: 'goToNotebookClicked',
    [ToolbarItemId.MarkTaskAsComplete]: 'taskCompleted',
    [ToolbarItemId.MarkTaskAsIncomplete]: 'taskIncomplete',
    [ToolbarItemId.ArchiveTask]: 'taskArchived',
    [ToolbarItemId.UnarchiveTask]: 'taskUnarchived',
    [ToolbarItemId.DeleteTask]: 'taskDeleted',
    [ToolbarItemId.AddToCollection]: 'addToCollection',
    [ToolbarItemId.DeleteCollection]: 'deleteCollection',
    [ToolbarItemId.EditCollection]: 'editCollection',
    [ToolbarItemId.RemoveFromCollection]: 'removeFromCollection',
    [ToolbarItemId.EditWebLink]: 'editLink',
    [ToolbarItemId.AddExternalLink]: 'addLinkToCollectionClicked',
    [ToolbarItemId.ShareCollection]: 'shareCollectionClicked',
    [ToolbarItemId.PinCollection]: 'pinCollectionClicked',
    [ToolbarItemId.UnpinCollection]: 'unpinCollectionClicked',
  };

  return toolbarItemMap[id] as CardAction;
};

export const trackToolbarClickEventHandler = ({
  item,
  searchTerm,
  workspaceSlugPath,
  cardPosition,
  sortMethod,
  filter,
  directReport,
}: MixpanelToolbarClickEventArgs) => {
  const { card } = item;

  const actionName = mapToolbarItemToAction(item.id as ToolbarItemId);

  trackCardClickEventHandler({
    card,
    actionName,
    workspaceSlugPath,
    searchTerm,
    cardPosition,
    sortMethod,
    filter,
    directReport,
  });
};

export const getSortMethodValue = (
  sortType: UserActivitySortType | undefined
) => {
  if (!sortType) {
    return null;
  }
  switch (sortType) {
    case UserActivitySortType.AtoZ:
      return 'A to Z';
    case UserActivitySortType.Relevance:
      return 'Most recent activity';
    case UserActivitySortType.MostViewed:
      return 'Most viewed';
    default:
      return 'Most recently viewed';
  }
};

export function trackSharesheetShow(props?: TrackEventProps) {
  return trackAction({
    eventName: 'share.sheet.events',
    eventProps: {
      event: 'show',
      ...props,
    },
  });
}

export function trackSharesheetAction({
  actionName,
  props,
}: {
  actionName: SharesheetAction;
  props?: TrackEventProps;
}) {
  return trackAction({
    eventName: 'share.sheet.events',
    eventProps: {
      event: 'action',
      action: actionName,
      ...props,
    },
  });
}

export function trackPopoutShow(props?: TrackEventProps) {
  return trackAction({
    eventName: 'popout.events',
    eventProps: {
      event: 'show',
      ...props,
    },
  });
}

export function trackPopoutAction(
  actionName: PopoutAction,
  props?: TrackEventProps
) {
  return trackAction({
    eventName: 'popout.events',
    eventProps: {
      event: 'action',
      action: actionName,
      ...props,
    },
  });
}

export function trackRewardAction(
  actionName: RewardAction,
  props?: TrackEventProps
) {
  return trackAction({
    eventName: 'reward.events',
    eventProps: {
      event: 'action',
      action: actionName,
      ...props,
    },
  });
}

export function trackRewardShow(props?: TrackEventProps) {
  return trackAction({
    eventName: 'reward.events',
    eventProps: {
      event: 'show',
      ...props,
    },
  });
}

export function trackRewardError(props?: TrackEventProps) {
  return trackAction({
    eventName: 'reward.events',
    eventProps: {
      event: 'error',
      ...props,
    },
  });
}

export function trackReplyDrawerAction(
  actionName: CardAction,
  props?: TrackEventProps
) {
  return trackAction({
    eventName: 'reply.drawer.events',
    eventProps: {
      event: 'action',
      action: actionName,
      ...props,
    },
  });
}

export function trackReplyDrawerError(props?: TrackEventProps) {
  return trackAction({
    eventName: 'reply.drawer.events',
    eventProps: {
      event: 'error',
      ...props,
    },
  });
}

export function trackChallengeShow(props?: TrackEventProps) {
  return trackAction({
    eventName: 'challenge.events',
    eventProps: {
      event: 'show',
      ...props,
    },
  });
}

export function trackChallengeAction(
  actionName: ChallengeAction,
  props?: TrackEventProps
) {
  return trackAction({
    eventName: 'challenge.events',
    eventProps: {
      event: 'action',
      action: actionName,
      ...props,
    },
  });
}

export function trackChallengeError(props?: TrackEventProps) {
  return trackAction({
    eventName: 'challenge.events',
    eventProps: {
      event: 'error',
      ...props,
    },
  });
}

export function trackFlowEditorAction(
  action: FlowEditorActions,
  props?: TrackEventProps
) {
  return trackAction({
    eventName: 'floweditorv3.events',
    eventProps: {
      event: 'action',
      action,
      ...props,
    },
  });
}

export function trackDoraAction(
  actionName:
    | 'aiReportingInsightsClicked'
    | 'teamInsightsClicked'
    | 'blocksIncludedConfirmed'
    | 'blocksToIncludeSelected'
    | 'clickedTimeoutStartNew'
    | 'copySuccessEmailClicked'
    | 'customTimePeriodConfirmed'
    | 'downloadChartImageClicked'
    | 'exitConfirmationClicked'
    | 'flowEngagementReportingCategoryClicked'
    | 'flowSummaryCanceled'
    | 'flowSummaryCopied'
    | 'flowSummaryExecuted'
    | 'flowSummaryOpened'
    | 'flowResponseOpened'
    | 'fullScreenChartClicked'
    | 'historyConversationClicked'
    | 'inputtedFocusConfirmed'
    | 'milestonesReportingCategoryClicked'
    | 'missingDataAdminLinkClicked'
    | 'peopleSelectedConfirmed'
    | 'peopleToIncludeSelected'
    | 'recognitionReportingCategoryClicked'
    | 'rewardRedemptionsReportingCategoryClicked'
    | 'runSavedPromptClicked'
    | 'savePromptClicked'
    | 'startNewSummaryWithSettings'
    | 'summaryFocusSelected'
    | 'summaryInputEditCanceled'
    | 'summaryInputEditConfirmed'
    | 'summaryInputEdited'
    | 'switchToFlowEngagementReportingCategory'
    | 'switchToMilestonesReportingCategory'
    | 'switchToRecognitionReportingCategory'
    | 'switchToRewardRedemptionsReportingCategory'
    | 'teamInsightsTipsOpen'
    | 'timePeriodSelected'
    | 'upgradeOnConversationCounterClicked'
    | 'upgradeOnLimitReachedClicked'
    | 'upgradeOnSavedSectionClicked',
  props?: TrackEventProps
) {
  const flowPageMatch = matchPath(
    '/:workspaceSlug/flows/*',
    window.location.pathname
  );

  return trackAction({
    eventName: 'dora.events',
    eventProps: {
      event: 'action',
      action: actionName,
      view: flowPageMatch ? 'flowPage' : 'discoverPage',
      ...props,
    },
  });
}
