import { APIEndpoints, assemblyAPI, logger } from '@assembly-web/services';
import { useToastStore } from '@assembly-web/ui';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { arrayMoveImmutable } from 'array-move';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { defineMessages, useIntl } from 'react-intl';
import type { RequireAtLeastOne } from 'type-fest';

import type { NavItemsResponse } from './useNavData';
import { navItemsQueryKey } from './useNavData';

type Payload = {
  collectionId: string;
} & RequireAtLeastOne<{
  beforeId: string;
  afterId: string;
}>;

const messages = defineMessages({
  failedToReorderCollection: {
    defaultMessage: 'Failed to reorder collection. Please try again later.',
    id: 'LgYC8X',
  },
});

export function useNavCollectionReorderMutation() {
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastStore();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationFn: async (payload: Payload) => {
      const { beforeId, afterId, collectionId } = payload;
      await assemblyAPI.put(
        APIEndpoints.updateNavItems(collectionId),
        omitBy({ beforeId, afterId, type: 'collection' }, isNil)
      );
    },

    onMutate: (payload: Payload) => {
      const navCacheData =
        queryClient.getQueryData<NavItemsResponse>(navItemsQueryKey);

      const pinnedCollections =
        navCacheData?.pages.flatMap((page) => page.data) ?? [];

      if (navCacheData?.pages.length && pinnedCollections.length > 0) {
        const modifiedCollections = arrayMoveImmutable(
          pinnedCollections,
          pinnedCollections.findIndex(
            (item) => item.id === payload.collectionId
          ),
          pinnedCollections.findIndex(
            (item) => item.id === (payload.beforeId ?? payload.afterId)
          )
        );

        const modifiedPages = navCacheData.pages.map((page, index) => ({
          ...page,
          data: modifiedCollections.slice(0 + index * 10, 10 + index * 10),
        }));

        queryClient.setQueryData(navItemsQueryKey, () => ({
          pages: modifiedPages,
          pageParams: navCacheData.pageParams,
        }));
      }
    },

    onError: (payload: Payload) => {
      showErrorToast(formatMessage(messages.failedToReorderCollection));
      logger.error('Failed to reorder collection.', payload);
    },
  });
}
