import { mapHexCodeToEmoticon } from '@assembly-web/services';
import {
  AssemblyLink,
  Avatar,
  AvatarSize,
  FeedActionBar,
} from '@assembly-web/ui';
import { defineMessage, useIntl } from 'react-intl';

import { useGiveRecognitionFlowDetails } from '../../discover/hooks/flows/useGiveRecognitionFlowDetails';
import { useCurrentUserDetail } from '../../discover/hooks/useCurrentUserDetail';

const buttonText = defineMessage({
  defaultMessage: 'Write a recognition post...',
  id: 'Mt3DIk',
});

export function RecognitionActionBar() {
  const { formatMessage } = useIntl();

  const {
    data: {
      link,
      flowDetails: { description, name, icon },
    },
  } = useGiveRecognitionFlowDetails();
  const userDetails = useCurrentUserDetail();

  return (
    <FeedActionBar.Root>
      <FeedActionBar.Header>
        <FeedActionBar.Title shareType="everyone">
          {mapHexCodeToEmoticon(icon.value)} {name}
        </FeedActionBar.Title>
        <FeedActionBar.Description>{description}</FeedActionBar.Description>
      </FeedActionBar.Header>
      <FeedActionBar.Footer>
        <Avatar
          memberID={userDetails.memberID}
          name={userDetails.name}
          size={AvatarSize.Large}
          image={userDetails.image}
          isRounded
          className="flex-shrink-0"
        />
        <FeedActionBar.ActionButton asChild>
          <AssemblyLink to={link}>{formatMessage(buttonText)}</AssemblyLink>
        </FeedActionBar.ActionButton>
      </FeedActionBar.Footer>
    </FeedActionBar.Root>
  );
}
