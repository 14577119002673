import { usePusherChannel, usePusherEvent } from '@assembly-web/pusher';
import {
  type FlowPostResponse,
  logger,
  type Nullable,
  type UserFeedApiResponse,
} from '@assembly-web/services';
import { useUserDetails } from '@assembly-web/services';
import { type InfiniteData, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';

import type { SearchIndexApiResponse } from '../useSearchIndex';

export type NotificationPreferenceUpdatedEvent = {
  flowId?: string;
  postId: Nullable<string>;
  responseId: Nullable<string>;
  assemblyId: string;
  memberId: string;
  subscriptionStatus: 'SUBSCRIBE' | 'UNSUBSCRIBE';
};

export function useNotificationPreferenceUpdatedEvents() {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();
  const { data: userDetails } = useUserDetails();

  const member = usePusherChannel(
    `private-member-${userDetails?.member.memberId}`
  );

  usePusherEvent<NotificationPreferenceUpdatedEvent>(
    member,
    'FOLLOWED_OR_UNFOLLOWED_POST',
    async (data) => {
      try {
        if (data) {
          //Update Single Post Cache
          const assemblyPostQueryKey = [
            'assemblyFlowPost',
            data.postId ? 'recognition' : data.flowId,
            data.postId ? data.postId : data.responseId,
          ];
          const queryCacheData: FlowPostResponse | undefined =
            queryClient.getQueryData(assemblyPostQueryKey);
          if (queryCacheData) {
            const updatedQueryCacheData: FlowPostResponse = produce(
              queryCacheData,
              (draft) => {
                draft.isMuted =
                  data.subscriptionStatus === 'UNSUBSCRIBE' ? true : undefined;
              }
            );
            queryClient.setQueryData(
              assemblyPostQueryKey,
              updatedQueryCacheData
            );
          }
          //Update User Feed Cache
          const userFeedKeys = queryCache
            .findAll({
              queryKey: ['userFeed'],
            })
            .map((query) => query.queryKey);
          const searchFeedKeys = queryCache
            .findAll({
              queryKey: ['searchResults'],
            })
            .map((query) => query.queryKey);

          userFeedKeys.forEach((key) => {
            const userFeedData: InfiniteData<UserFeedApiResponse> | undefined =
              queryClient.getQueryData(key);
            if (userFeedData) {
              const mutatedUserFeedData: InfiniteData<UserFeedApiResponse> = {
                ...userFeedData,
                pages: userFeedData.pages.map((page) => {
                  return {
                    ...page,
                    data: page.data.map((post) => {
                      if (
                        post.type === 'post' &&
                        post.cardDetails?.post?.postID === data.postId
                      ) {
                        return produce(post, (draft) => {
                          if (draft.cardDetails?.post) {
                            draft.cardDetails.post.isMuted =
                              data.subscriptionStatus === 'UNSUBSCRIBE'
                                ? true
                                : undefined;
                          }
                        });
                      }

                      if (
                        post.type === 'response' &&
                        post.cardDetails?.responseId === data.responseId
                      ) {
                        return produce(post, (draft) => {
                          if (draft.cardDetails) {
                            draft.cardDetails.isMuted =
                              data.subscriptionStatus === 'UNSUBSCRIBE'
                                ? true
                                : undefined;
                          }
                        });
                      }
                      return post;
                    }),
                  };
                }),
              };
              queryClient.setQueryData(key, mutatedUserFeedData);
            }
          });

          searchFeedKeys.forEach((queryKey) => {
            const searchData =
              queryClient.getQueryData<InfiniteData<SearchIndexApiResponse>>(
                queryKey
              );
            if (searchData) {
              const mutatedSearchData: InfiniteData<SearchIndexApiResponse> = {
                ...searchData,
                pages: searchData.pages.map((page) => {
                  return {
                    ...page,
                    data: {
                      ...page.data,
                      data: page.data.data.map((result) => {
                        if (
                          result.type === 'post' &&
                          result.cardDetails?.post?.postID === data.postId
                        ) {
                          return produce(result, (draft) => {
                            if (draft.cardDetails?.post) {
                              draft.cardDetails.post.isMuted =
                                data.subscriptionStatus === 'UNSUBSCRIBE'
                                  ? true
                                  : undefined;
                            }
                          });
                        }

                        if (
                          result.type === 'response' &&
                          result.cardDetails?.responseId === data.responseId
                        ) {
                          return produce(result, (draft) => {
                            if (draft.cardDetails) {
                              draft.cardDetails.isMuted =
                                data.subscriptionStatus === 'UNSUBSCRIBE'
                                  ? true
                                  : undefined;
                            }
                          });
                        }
                        return result;
                      }),
                    },
                  };
                }),
              };
              queryClient.setQueryData(queryKey, mutatedSearchData);
            }
          });
        }
      } catch (e) {
        logger.error(
          'Refetch error on post interaction settings',
          {},
          e as Error
        );
      }
    }
  );
}
