import {
  config,
  SplitNames,
  type ThemePreset,
  useFeatureSplit,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import {
  ProfileMenu,
  ThemeSelectorModal,
  useAssemblyNavigate,
  useDeviceInfo,
} from '@assembly-web/ui';
import {
  ArrowRightEndOnRectangleIcon,
  ArrowUturnLeftIcon,
  BuildingOfficeIcon,
  Cog6ToothIcon,
  GiftIcon,
  PaintBrushIcon,
} from '@heroicons/react/24/outline';
import type { ComponentProps, Key } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { NewUxOverrideKey } from '../../modules/discover/hooks/useIsNewUX';
import { trackDiscoverAction } from '../../modules/discover/services/analytics';
import { isAdminMember } from '../../modules/discover/services/member';
import { useRewardsDetails } from '../../modules/rewards/hooks/useRewardsDetails';
import { useWorkspace } from '../../queries/useWorkspace';

const messages = defineMessages({
  goToAdmin: {
    defaultMessage: 'Admin',
    id: 'iHN12u',
  },
  themeYourWorkspace: {
    defaultMessage: 'Change Theme',
    id: 'm1YRjG',
  },
  myRewards: {
    defaultMessage: 'My Rewards',
    id: 'Wocb8I',
  },
  mySettings: {
    defaultMessage: 'My Settings',
    id: 'ECJpOU',
  },
  switchAccounts: {
    defaultMessage: 'Switch accounts',
    id: '6xNr8c',
  },
  logout: {
    defaultMessage: 'Logout',
    id: 'C81/uG',
  },
});

enum ProfileMenuItem {
  MySettings = 'my-settings',
  MyRewards = 'my-rewards',
  Admin = 'admin',
  ChangeTheme = 'change-theme',
  Logout = 'logout',
  SwitchToOldUX = 'switch-to-old-ux',
}

export function UserMenu() {
  const { data: userDetails } = useSuspenseUserDetails();

  const { deviceType } = useDeviceInfo();

  const [openProfileMenu, setOpenProfileMenu] = useState(false);

  const variant: ComponentProps<typeof ProfileMenu>['variant'] = useMemo(() => {
    if (
      deviceType === 'desktop' ||
      deviceType === 'laptop' ||
      deviceType === 'tablet'
    ) {
      return 'dropdown';
    }

    return 'collapsible';
  }, [deviceType]);

  const { isTreatmentActive: isNewAdminExperience } = useFeatureSplit(
    SplitNames.NewAdminExperience
  );

  const currentTheme =
    userDetails.assembly.settings.theme?.preset ?? ('neutral' as ThemePreset);

  const [showThemeSelector, setShowThemeSelector] = useState<boolean>(false);

  const { formatMessage } = useIntl();
  const { isRewardsSetupForUser } = useRewardsDetails();
  const isAdmin = isAdminMember(userDetails.member);
  const { isMobileDevice } = useDeviceInfo();

  const { data: workspaceData } = useWorkspace();
  const hasMultipleWorkspaces = workspaceData.workspaceIDs.length > 1;

  const navigate = useAssemblyNavigate();

  const menuItems = useMemo(() => {
    const items: ComponentProps<typeof ProfileMenu>['menuItems'] = new Map();

    items.set(ProfileMenuItem.MySettings, {
      text: formatMessage(messages.mySettings),
      icon: Cog6ToothIcon,
    });

    if (isRewardsSetupForUser) {
      items.set(ProfileMenuItem.MyRewards, {
        text: formatMessage(messages.myRewards),
        icon: GiftIcon,
      });
    }

    if (isAdmin) {
      items.set(ProfileMenuItem.Admin, {
        text: formatMessage(messages.goToAdmin),
        icon: BuildingOfficeIcon,
      });
      if (!isMobileDevice)
        items.set(ProfileMenuItem.ChangeTheme, {
          text: formatMessage(messages.themeYourWorkspace),
          icon: PaintBrushIcon,
        });
    }

    items.set(ProfileMenuItem.SwitchToOldUX, {
      text: 'Turn off new UX',
      icon: ArrowUturnLeftIcon,
    });

    items.set(ProfileMenuItem.Logout, {
      text: formatMessage(
        hasMultipleWorkspaces ? messages.switchAccounts : messages.logout
      ),
      icon: ArrowRightEndOnRectangleIcon,
    });

    return items;
  }, [formatMessage, hasMultipleWorkspaces, isAdmin, isRewardsSetupForUser]);

  const onMenuItemClick = useCallback(
    (key: Key) => {
      switch (key) {
        case ProfileMenuItem.MySettings: {
          navigate(
            `/a/${userDetails.assembly.workspaceSlugPath}/settings/general`
          );
          trackDiscoverAction('profileMenuItemClicked', {
            profileMenuItem: 'mySettings',
            v3enabled: true,
          });
          break;
        }
        case ProfileMenuItem.MyRewards: {
          navigate(`/a/rewards?sortOrder=asc&tab=myRewards`);
          trackDiscoverAction('profileMenuItemClicked', {
            profileMenuItem: 'myRewards',
            v3enabled: true,
          });
          break;
        }
        case ProfileMenuItem.ChangeTheme: {
          setShowThemeSelector(true);
          break;
        }
        case ProfileMenuItem.Admin: {
          trackDiscoverAction('profileMenuItemClicked', {
            profileMenuItem: 'goToAdmin',
            v3enabled: true,
          });

          if (isNewAdminExperience) {
            window.location.href = `${config.domains.newAdminApp}/settings`;
          } else {
            window.location.href = config.domains.adminApp;
          }
          break;
        }
        case ProfileMenuItem.SwitchToOldUX: {
          try {
            localStorage.removeItem(NewUxOverrideKey);
          } catch {
            // ignore
          }
          window.location.reload();
          break;
        }
        case ProfileMenuItem.Logout: {
          trackDiscoverAction('profileMenuItemClicked', {
            profileMenuItem: 'switchAccounts',
            v3enabled: true,
          });
          window.location.href = `${config.domains.legacyApp}/signout`;
          break;
        }
      }
    },
    [isNewAdminExperience, navigate, userDetails.assembly.workspaceSlugPath]
  );

  return (
    <>
      <ProfileMenu
        variant={variant}
        member={userDetails.member}
        onMenuItemClick={onMenuItemClick}
        menuItems={menuItems}
        open={openProfileMenu}
        onOpenChange={setOpenProfileMenu}
      />
      {Boolean(showThemeSelector) && (
        <ThemeSelectorModal
          onClose={() => setShowThemeSelector(false)}
          currentTheme={currentTheme}
        />
      )}
    </>
  );
}
