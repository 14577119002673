import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import { useQuery } from '@tanstack/react-query';

import { useIsChatEnabled } from './useIsChatEnabled';

export type UnReadChatMessageResponse = {
  count: number;
  memberId: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
}[];

export function useGetUnReadChatMessages() {
  const isChatEnabled = useIsChatEnabled();

  return useQuery({
    enabled: isChatEnabled,
    queryFn: async () => {
      const response = await assemblyAPI.get<UnReadChatMessageResponse>(
        APIEndpoints.getUnreadChatMessages
      );

      return response.data;
    },
    queryKey: ['unreadChatMessages'],
  });
}
