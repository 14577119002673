import {
  type FlowStatusType,
  UserActivitySortType,
} from '@assembly-web/services';
import { GlobalFilterOption } from '@assembly-web/ui';
import type { RefObject } from 'react';
import { useInViewport } from 'react-in-viewport';

import { useGetUserFeed } from '../../modules/discover/hooks/useGetUserFeed';
import {
  SearchSortType,
  useSearchIndex,
} from '../../modules/discover/hooks/useSearchIndex';

const LIMIT = 20;

export const useGetListItems = ({
  searchTerm,
  folderId,
  lastPageRef,
}: {
  searchTerm: string;
  folderId: string;
  lastPageRef: RefObject<HTMLDivElement>;
}) => {
  const isSearch = searchTerm !== '';

  const {
    data: searchResults,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = useSearchIndex({
    searchTerm,
    selectedFilters: [GlobalFilterOption.Flows],
    sortBy: SearchSortType.LexicographicalAscending,
    from: 0,
    excludeRecognitionFlow: false,
    populateCardDetails: true,
    secondaryFilters: {
      entityIn: [],
      flowStatus: ['ACTIVE', 'INACTIVE' as FlowStatusType],
    },
    enabled: Boolean(searchTerm),
  });

  const {
    data: userFeed,
    hasNextPage: hasNextPageUserFeed,
    fetchNextPage: fetchNextPageUserFeed,
    isFetchingNextPage: isFetchingNextPageUserFeed,
    isLoading: isLoadingUserFeed,
  } = useGetUserFeed(
    {
      filters: {
        type: ['flows'],
      },
      userActivitySortType: UserActivitySortType.AtoZ,
      excludeRecognitionFlow: false,
      populateCardDetails: true,
      secondaryFilters: {
        entityIn: [],
        flowStatus: ['ACTIVE'],
      },
    },
    {
      enabled: Boolean(folderId) && !searchTerm,
    }
  );

  const isFetchingNewPage = isSearch
    ? isFetchingNextPage
    : isFetchingNextPageUserFeed;

  const isDataLoading = isSearch ? isLoading : isLoadingUserFeed;

  const noResults = isSearch
    ? !searchResults?.pages[0].data.data.length
    : !userFeed?.pages[0].data.length;

  const listItems = isSearch
    ? searchResults?.pages.flatMap((page) => page.data.data)
    : userFeed?.pages.flatMap((page) => page.data);

  useInViewport(
    lastPageRef,
    {},
    { disconnectOnLeave: false },
    {
      onEnterViewport: () => {
        if (searchTerm && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        } else if (
          !searchTerm &&
          hasNextPageUserFeed &&
          !isFetchingNextPageUserFeed
        ) {
          fetchNextPageUserFeed();
        }
      },
    }
  );

  return {
    listItems,
    isFetchingNewPage,
    isDataLoading,
    noResults,
    hasNextPage: isSearch ? hasNextPage : hasNextPageUserFeed,
    totalItemsLExceedLimit:
      userFeed?.pages[0].data.length && userFeed.pages[0].data.length >= LIMIT,
  };
};
