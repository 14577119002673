import {
  isTruthy,
  useSuspenseUserDetails,
  withoutDefaultEventBehavior,
} from '@assembly-web/services';
import {
  GlobalFilterOption,
  GlobalSearchInput,
  Greeting,
  useTimeOfDay,
} from '@assembly-web/ui';
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { AnimatePresence, motion } from 'framer-motion';
import debounce from 'lodash/debounce';
import {
  type ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { twJoin, twMerge } from 'tailwind-merge';
import { Drawer as MobileDrawer } from 'vaul';

import { useGlobalFilter } from '../../modules/discover/hooks/useGlobalFilter';
import { NavHeader } from '../Nav/NavHeader';
import { UserMenu } from '../Nav/UserMenu';

const messages = defineMessages({
  clearSearch: {
    defaultMessage: 'Clear search',
    id: '4YJHut',
  },
});

export function MobileAppHeader() {
  const [showSearchInput, setShowSearchInput] = useState(false);
  const { formatMessage } = useIntl();

  const {
    searchTerm,
    onSearchClearClick,
    onSearchInputChange,
    onSearchInputClick,
  } = useAppHeader();

  return (
    <header className="sticky top-0 z-10 flex h-14 w-full items-center justify-between bg-gray-1 p-2">
      <AnimatePresence>
        {isTruthy(showSearchInput) ? (
          <Fragment key="searchView">
            <GlobalSearchInput
              id="global-search-input"
              hideClearButton
              searchTerm={searchTerm}
              className="min-w-[310px]"
              onClick={onSearchInputClick}
              onChange={onSearchInputChange}
              onClearClick={onSearchClearClick}
            />
            <button
              aria-label={formatMessage(messages.clearSearch)}
              type="button"
              className="z-[1] flex h-full cursor-pointer items-center justify-center p-2"
              onClick={withoutDefaultEventBehavior(function () {
                setShowSearchInput(false);
                onSearchClearClick();
              })}
              data-testid="clearSearch"
            >
              <XMarkIcon className="size-6 text-gray-9" />
            </button>
          </Fragment>
        ) : (
          <Fragment key="defaultView">
            <NavHeader />
            <motion.div className="flex items-center justify-center gap-3">
              <button
                className="rounded-lg px-1"
                onClick={() => {
                  setShowSearchInput(true);
                  setTimeout(() => {
                    document
                      .querySelector<HTMLInputElement>('#global-search-input')
                      ?.focus();
                  }, 100);
                }}
              >
                <MagnifyingGlassIcon className="size-6 text-gray-9" />
              </button>

              <MobileDrawer.Trigger>
                <button className="flex justify-center rounded-lg px-1">
                  <Bars3Icon className="size-6 text-gray-9" />
                </button>
              </MobileDrawer.Trigger>
            </motion.div>
          </Fragment>
        )}
      </AnimatePresence>
    </header>
  );
}

function useAppHeader() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchTerm, setSearchTerm] = useState(
    searchParams.get('search') || ''
  );

  const filter = useGlobalFilter();
  const isRewardsPage = filter === GlobalFilterOption.Rewards;

  const debouncedUpdateRef = useRef<ReturnType<typeof debounce>>();

  useEffect(() => {
    debouncedUpdateRef.current = debounce((value: string) => {
      setSearchParams((state) => {
        const newState = new URLSearchParams(state);
        if (value) {
          newState.set('search', value);
        } else {
          newState.delete('search');
        }
        return newState;
      });
    }, 300);

    return () => debouncedUpdateRef.current?.cancel();
  }, [setSearchParams]);

  useEffect(() => {
    debouncedUpdateRef.current = debounce((value: string) => {
      setSearchParams((state) => {
        const newState = new URLSearchParams(state);
        if (value) {
          newState.set('search', value);
        } else {
          newState.delete('search');
        }
        return newState;
      });
    }, 300);

    return () => debouncedUpdateRef.current?.cancel();
  }, [setSearchParams]);

  const handleSearchUpdate = useCallback(
    (value: string) => debouncedUpdateRef.current?.(value),
    []
  );

  const onSearchInputClick = () => {
    if (isRewardsPage) {
      const $rewardsSearchInput =
        document.querySelector<HTMLInputElement>('#search-rewards');

      $rewardsSearchInput?.focus();
      $rewardsSearchInput?.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    }
  };

  const onSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    handleSearchUpdate(e.target.value);
  };

  const onSearchClearClick = () => {
    setSearchTerm('');
    handleSearchUpdate('');
  };

  return {
    searchTerm,
    setSearchTerm,
    isRewardsPage,
    handleSearchUpdate,
    onSearchClearClick,
    onSearchInputChange,
    onSearchInputClick,
  };
}

export function DesktopAppHeader() {
  const { data: userDetails } = useSuspenseUserDetails();
  const timeOfDay = useTimeOfDay(dayjs().hour());

  const {
    isRewardsPage,
    searchTerm,
    onSearchClearClick,
    onSearchInputChange,
    onSearchInputClick,
  } = useAppHeader();

  return (
    <header
      className={twJoin(
        'fixed top-0 z-10 flex h-[88px] w-[calc(100%-300px)] items-center justify-between border-b-[0.75px] border-gray-5 bg-gray-1 px-6 py-3',
        timeOfDay === 'morning'
          ? 'header-bg-morning-gradient'
          : timeOfDay === 'afternoon'
            ? 'header-bg-afternoon-gradient'
            : 'header-bg-evening-gradient'
      )}
    >
      <section>
        <Greeting
          firstName={userDetails.member.profile.firstName}
          hour={dayjs().hour()}
        />
      </section>
      <section className="flex flex-row items-center gap-1">
        <GlobalSearchInput
          searchTerm={searchTerm}
          className={twMerge('w-[310px]', !isRewardsPage && 'focus:w-[600px]')}
          onClick={onSearchInputClick}
          onChange={onSearchInputChange}
          onClearClick={onSearchClearClick}
        />
        <UserMenu />
      </section>
    </header>
  );
}

export function TabletAppHeader() {
  const [showSearchInput, setShowSearchInput] = useState(false);

  const { formatMessage } = useIntl();

  const { data: userDetails } = useSuspenseUserDetails();
  const timeOfDay = useTimeOfDay(dayjs().hour());

  const {
    searchTerm,
    onSearchClearClick,
    onSearchInputChange,
    onSearchInputClick,
  } = useAppHeader();

  return (
    <header
      className={twJoin(
        'fixed top-0 z-10 flex h-[88px] w-[calc(100%-222px)] items-center justify-between border-b-[0.75px] border-gray-5 bg-gray-1 px-6 py-3',
        timeOfDay === 'morning'
          ? 'header-bg-morning-gradient'
          : timeOfDay === 'afternoon'
            ? 'header-bg-afternoon-gradient'
            : 'header-bg-evening-gradient'
      )}
    >
      {!showSearchInput && (
        <section>
          <Greeting
            firstName={userDetails.member.profile.firstName}
            hour={dayjs().hour()}
          />
        </section>
      )}

      <section
        className={twMerge(
          'flex flex-row items-center gap-1',
          showSearchInput && 'w-full'
        )}
      >
        <AnimatePresence>
          {showSearchInput ? (
            <>
              <GlobalSearchInput
                id="global-search-input"
                searchTerm={searchTerm}
                className="w-full"
                onClick={onSearchInputClick}
                onChange={onSearchInputChange}
                onClearClick={onSearchClearClick}
              />
              <button
                aria-label={formatMessage(messages.clearSearch)}
                type="button"
                className="z-[1] flex h-full cursor-pointer items-center justify-center p-2"
                onClick={withoutDefaultEventBehavior(function () {
                  setShowSearchInput(false);
                  onSearchClearClick();
                })}
                data-testid="clearSearch"
              >
                <XMarkIcon className="size-6 text-gray-9" />
              </button>
            </>
          ) : (
            <button
              className="rounded-lg px-1"
              onClick={() => {
                setShowSearchInput(true);
                setTimeout(() => {
                  document
                    .querySelector<HTMLInputElement>('#global-search-input')
                    ?.focus();
                }, 100);
              }}
            >
              <MagnifyingGlassIcon className="size-6 text-gray-9" />
            </button>
          )}
        </AnimatePresence>
        <UserMenu />
      </section>
    </header>
  );
}
