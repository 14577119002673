import {
  canUserDeletePost,
  type ChallengeAPIResponse,
  copyToClipboard,
  getMemberDetailsFromUserDetails,
  type ReactionDetails,
  type RepliesResponse,
  type ReplyData,
  type UserDetails,
} from '@assembly-web/services';
import { config } from '@assembly-web/services';
import {
  Replies,
  type Reply,
  type ToolbarItem,
  useDeviceInfo,
  useToastStore,
} from '@assembly-web/ui';
import {
  type MutableRefObject,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useNavigateToUserFeed } from '../../../../../hooks/useNavigateToUserFeed';
import { useCommentReactionMutation } from '../../../../../hooks/useReactionMutation';
import { useMultiDrawerStore } from '../../../../../stores/useMultiDrawerStore';
import { ModalsContext } from '../../../contexts/ModalsContext';
import { useGetChallengeRepliesInfiniteQuery } from '../../../hooks/challenges/useChallengeRepliesQueries';
import { ToolbarItemId } from '../../cards/utils';
import { getReplyByReplyId } from '../PostDrawer/utils/util';
import { useBoostOptions } from '../shared/hooks/useBoostOptions';

type ChallengeRepliesContainer = {
  userDetails: UserDetails;
  focusedCommentId?: string;
  repliesData: RepliesResponse;
  editingCommentId: string | null;
  challengeDetails: ChallengeAPIResponse;
  containerRef: MutableRefObject<HTMLElement | null>;
  onEditReplyClick: (selectedReply: ReplyData) => void;
  commentIdToEdit?: string;
};

const messages = defineMessages({
  copiedToClipboard: {
    defaultMessage: 'Link successfully copied to clipboard',
    id: 'QHwY68',
  },
});

export function ChallengeRepliesContainer({
  repliesData,
  userDetails,
  containerRef,
  focusedCommentId,
  challengeDetails,
  editingCommentId,
  onEditReplyClick,
  commentIdToEdit,
}: ChallengeRepliesContainer) {
  const { formatMessage } = useIntl();
  const { showSuccessToast } = useToastStore();
  const { navigate: navigateToUserFeed } = useNavigateToUserFeed();

  const { challengeId } = challengeDetails;
  const { assembly, member } = userDetails;
  const {
    data,
    isError,
    replies,
    hasNextPage,
    fetchNextPage,
    hasPreviousPage,
    isLoading,
    fetchPreviousPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
  } = useGetChallengeRepliesInfiniteQuery({
    challengeId: challengeDetails.challengeId,
    commentId: focusedCommentId ?? commentIdToEdit ?? null,
  });

  const boostOptions = useBoostOptions({ challengeId });

  const toggleIsOverflowOpen = useMultiDrawerStore(
    (store) => store.toggleIsOverflowOpen
  );

  useEffect(() => {
    if (commentIdToEdit && data) {
      const replyToEdit = getReplyByReplyId(data, commentIdToEdit);
      if (replyToEdit) {
        onEditReplyClick(replyToEdit);
      }
    }
  }, [data, commentIdToEdit, onEditReplyClick]);

  const findAndUpdateDrawerField = useMultiDrawerStore(
    (store) => store.findAndUpdateDrawerField
  );

  const reactionMutate = useCommentReactionMutation({
    currentUser: getMemberDetailsFromUserDetails(member),
  });

  const { onDeleteChallengeReplyClick } = useContext(ModalsContext);

  const isMobile = useDeviceInfo().deviceType === 'mobile';

  const handleMenuItemClick = useCallback(
    async (
      args: ToolbarItem & {
        cardId: string;
        hasPoints?: boolean;
      }
    ) => {
      const { id, cardId: replyId, hasPoints } = args;

      switch (id) {
        case ToolbarItemId.EditReply:
          {
            const selectedReply = getReplyByReplyId(data, replyId);
            if (selectedReply) {
              onEditReplyClick(selectedReply);
            }
          }
          break;
        case ToolbarItemId.CopyReplyLink:
          {
            const baseUrl = config.domains.app;
            const linkToCopy = `${baseUrl}/a/discover?${new URLSearchParams([
              ['commentId', replyId],
              ['filter', 'challenges'],
              ['challengeId', challengeId],
            ])}`;
            await copyToClipboard(linkToCopy);
            showSuccessToast(formatMessage(messages.copiedToClipboard));
          }
          break;
        case ToolbarItemId.DeleteReply:
          {
            onDeleteChallengeReplyClick({
              replyId,
              challengeId,
              hasPoints: Boolean(hasPoints),
            });
          }
          break;
      }
    },
    [
      data,
      challengeId,
      formatMessage,
      onEditReplyClick,
      showSuccessToast,
      onDeleteChallengeReplyClick,
    ]
  );

  const handleResetScroll = useCallback(() => {
    if (focusedCommentId) {
      findAndUpdateDrawerField(challengeId, (draft) => {
        if (draft.type === 'challenges') {
          draft.data = {
            ...draft.data,
            challengeId,
          };
        }
      });
    }
  }, [focusedCommentId, findAndUpdateDrawerField, challengeId]);

  const handleInternalLinkClick = useCallback(() => {
    if (!isMobile) {
      return;
    }

    toggleIsOverflowOpen();
  }, [isMobile, toggleIsOverflowOpen]);

  const handleReactionClick = useCallback(
    (emoji: ReactionDetails, reply: Reply) => {
      const currentUser = member;
      const hasCurrentUserReactedToTheSelectedEmoji = reply.reactions?.some(
        (reaction) =>
          reaction.name === emoji.name &&
          reaction.members.some(
            (member) => member.memberID === currentUser.memberId
          )
      );

      reactionMutate({
        challengeId,
        commentId: reply.cardId,
        payload: {
          name: emoji.name,
          type: emoji.type,
          value: emoji.type,
          displayName: emoji.displayName,
        },
        action: hasCurrentUserReactedToTheSelectedEmoji ? 'unset' : 'set',
      });
    },
    [member, reactionMutate, challengeId]
  );

  return (
    <Replies
      replies={replies}
      isError={isError}
      containerRef={containerRef}
      boostOptions={boostOptions}
      selectedReplyToDelete={null}
      fetchNextPage={fetchNextPage}
      currentUserId={member.memberId}
      editingReplyId={editingCommentId}
      onMemberClick={navigateToUserFeed}
      hasNextPage={Boolean(hasNextPage)}
      isInitialLoading={isLoading}
      totalReplyCount={repliesData.count}
      currencyDetails={assembly.currency}
      onMenuItemClick={handleMenuItemClick}
      handleResetScroll={handleResetScroll}
      fetchPreviousPage={fetchPreviousPage}
      activeCardId={focusedCommentId ?? null}
      isFetchingNextPage={isFetchingNextPage}
      handleReactionClick={handleReactionClick}
      hasPreviousPage={Boolean(hasPreviousPage)}
      postAuthor={challengeDetails.createdBy.name}
      isFetchingPreviousPage={isFetchingPreviousPage}
      canDeleteReplies={canUserDeletePost(userDetails)}
      handleInternalLinkClick={handleInternalLinkClick}
    />
  );
}
