import {
  type ChallengeAPIResponse,
  type ChallengeDetails,
  getMemberDetailsFromUserDetails,
  isUserAdmin,
  logger,
  type ReactionDetails,
  type RepliesResponse,
  type UserDetails,
} from '@assembly-web/services';
import {
  ChallengeCard,
  GlobalFilterOption,
  IconButton,
  TextStyle,
  useAssemblyNavigate,
  useToastStore,
} from '@assembly-web/ui';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { AnimatePresence, motion } from 'framer-motion';
import { useContext, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { useChallengeReactionMutation } from '../../../../../hooks/useReactionMutation';
import { ChallengeModalsContext } from '../../../contexts/ChallengeModalsContext';
import { useGlobalFilter } from '../../../hooks/useGlobalFilter';
import {
  type SubmitClaimPayload,
  useSubmitClaimMutation,
} from '../../../hooks/useSubmitClaimMutation';
import { trackCardAction } from '../../../services/analytics';
import { ChallengeCardHeader } from './ChallengeCardHeader';

type ChallengePostContainerProps = {
  userDetails: UserDetails;
  repliesData: RepliesResponse;
  challengeDetails: ChallengeAPIResponse;
};

const messages = defineMessages({
  originalPost: {
    defaultMessage: 'Original post',
    id: 'p1pzyX',
  },
  claimSubmittedSuccessfully: {
    defaultMessage: 'Claim submitted successfully!',
    id: 'Fu6ENy',
  },
  failedToSubmitClaim: {
    defaultMessage: 'Unable to submit claim, please try again later',
    id: 'TqeH9H',
  },
  challengeHasEnded: {
    defaultMessage: 'Challenge has ended.',
    id: 'zD5hk+',
  },
});

const challengeNotActiveError = 'CHALLENGE_NOT_ACTIVE';

export function ChallengePostContainer({
  userDetails,
  repliesData,
  challengeDetails,
}: ChallengePostContainerProps) {
  const {
    type,
    title,
    proof,
    state,
    image,
    points,
    endedAt,
    canEdit,
    reactions,
    createdBy,
    launchedAt,
    description,
    challengeId,
    claimButtonText,
    claimsRemaining,
    selfClaimDetails,
    interactionSettings,
  } = challengeDetails;

  const { formatMessage } = useIntl();

  const descriptionText = description?.messageHtml ?? '';

  const {
    member: { role, memberId },
    assembly: { currency },
  } = userDetails;

  const { showSuccessToast, showErrorToast } = useToastStore();
  const {
    openLaunchChallengeModal,
    openEndChallengeModal,
    openSubmitChallengeModal,
    openSubmitChallengeSuccessModal,
    openUnarchiveChallengeModal,
    closeModals,
  } = useContext(ChallengeModalsContext);

  const navigate = useAssemblyNavigate();

  const isChallengesTab = useGlobalFilter() === GlobalFilterOption.Challenges;

  const challengePayload: ChallengeDetails = {
    id: challengeId,
    description: descriptionText,
    title,
    state,
    points,
    currency,
    endedAt,
    proof,
    launchedAt,
    image,
  };

  const reactionMutate = useChallengeReactionMutation({
    cardId: '',
    challengeId: challengeId,
    currentUser: getMemberDetailsFromUserDetails(userDetails.member),
  });

  const { mutate: submitClaimWithoutProof, isPending: isSubmittingClaim } =
    useSubmitClaimMutation({
      onSuccess: () => {
        showSuccessToast(formatMessage(messages.claimSubmittedSuccessfully));
        openSubmitChallengeSuccessModal(challengePayload);
      },
      onError: (payload, errorMsg, errorInfo) => {
        closeModals();
        if (errorMsg === challengeNotActiveError) {
          showErrorToast(formatMessage(messages.challengeHasEnded));
        } else {
          showErrorToast(
            errorMsg ?? formatMessage(messages.failedToSubmitClaim)
          );
        }
        logger.error('Failed to submit claim from drawer', payload, errorInfo);
      },
    });

  const [isPostExpanded, setPostExpanded] = useState(true);

  const trackChallengeCardActions = (
    actionName:
      | 'viewClaimsClicked'
      | 'postReactionAdded'
      | 'viewRepliesClicked'
      | 'postReactionRemoved'
      | 'claimChallengeClicked'
  ) => {
    trackCardAction(actionName, {
      challengeId: challengeId,
      challengeName: challengeDetails.title,
      challengeRewardValue: challengeDetails.points,
      challengeProofRequired: challengeDetails.proof.isRequired,
    });
  };

  const handleReactionClick = (emoji: ReactionDetails) => {
    const hasCurrentUserReactedToTheSelectedEmoji = reactions.some(
      (reaction) =>
        reaction.name === emoji.name &&
        reaction.members.some((member) => member.memberID === memberId)
    );

    reactionMutate({
      payload: {
        displayName: emoji.displayName,
        name: emoji.name,
        type: emoji.type,
        value: emoji.type,
      },
      action: hasCurrentUserReactedToTheSelectedEmoji ? 'unset' : 'set',
    });

    const userAction = hasCurrentUserReactedToTheSelectedEmoji
      ? 'postReactionRemoved'
      : 'postReactionAdded';

    trackChallengeCardActions(userAction);
  };

  const { hideReactions = false, hideReplies = false } =
    interactionSettings ?? {};

  const handleSubmitClaimWithoutProof = (challenge: ChallengeDetails) => {
    const payload: SubmitClaimPayload = {
      challengeId: challenge.id,
    };
    submitClaimWithoutProof(payload);
  };

  const handleClaim = async () => {
    trackChallengeCardActions('claimChallengeClicked');

    if (proof.isRequired) {
      openSubmitChallengeModal(challengePayload);
    } else {
      handleSubmitClaimWithoutProof(challengePayload);
    }
  };

  const handleOnViewClaims = () => {
    trackChallengeCardActions('viewClaimsClicked');

    if (isChallengesTab) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate('/discover?filter=challenges');
    }
  };

  const handleChallengeHeaderButtonClick = (
    actionType: string,
    challenge: ChallengeDetails
  ) => {
    switch (actionType) {
      case 'launch-challenge':
        openLaunchChallengeModal({
          id: challenge.id,
          title: challenge.title,
        });
        break;
      case 'end-challenge':
        openEndChallengeModal({
          id: challenge.id,
          title: challenge.title,
        });
        break;
      default:
        break;
    }
  };

  return (
    <section>
      <ChallengeCardHeader
        challengeDetails={challengeDetails}
        isAdmin={isUserAdmin({ role })}
      />
      <div className="my-2">
        <IconButton
          size="small"
          className="-mt-1 w-fit"
          variation="tertiaryLite"
          onClick={() => {
            setPostExpanded(!isPostExpanded);
          }}
        >
          <TextStyle variant="sm-regular" className="text-gray-8">
            {formatMessage(messages.originalPost)}
          </TextStyle>
          <ChevronDownIcon
            className={twMerge(
              'h-4 w-4 text-gray-8',
              isPostExpanded && 'rotate-180'
            )}
          />
        </IconButton>
      </div>
      <AnimatePresence initial={false}>
        <motion.div
          animate={isPostExpanded ? 'open' : 'collapsed'}
          variants={{
            open: { opacity: 1, height: 'auto', transition: { duration: 0.3 } },
            collapsed: { opacity: 0, height: 0, transition: { duration: 0.3 } },
          }}
          exit="collapsed"
          initial="collapsed"
          className="flex w-full flex-col"
        >
          <ChallengeCard
            onUnarchiveChallenge={() =>
              openUnarchiveChallengeModal({
                id: challengeId,
                title,
                points,
              })
            }
            type={type}
            image={image}
            title={title}
            state={state}
            points={points}
            variant="drawer"
            canEdit={canEdit}
            currency={currency}
            reactions={reactions}
            createdBy={createdBy}
            launchedAt={launchedAt}
            hideReplies={hideReplies}
            challengeId={challengeId}
            repliesData={repliesData}
            description={descriptionText}
            hideReactions={hideReactions}
            claimsRemaining={claimsRemaining}
            claimButtonText={claimButtonText}
            onReactionClick={handleReactionClick}
            selfClaimDetails={selfClaimDetails}
            isUserAdmin={isUserAdmin({ role })}
            currentUserDetails={getMemberDetailsFromUserDetails(
              userDetails.member
            )}
            onClaim={handleClaim}
            isClaimButtonLoading={isSubmittingClaim}
            onViewClaims={handleOnViewClaims}
            onHeaderButtonClick={(button: string) => {
              handleChallengeHeaderButtonClick(button, challengePayload);
            }}
          />
        </motion.div>
      </AnimatePresence>
    </section>
  );
}
