import type { CollectionsAPIResponse } from '@assembly-web/services';
import { APIEndpoints, assemblyAPI, logger } from '@assembly-web/services';
import { useToastStore } from '@assembly-web/ui';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import { defineMessages, useIntl } from 'react-intl';

import { trackDiscoverError } from '../../services/analytics';
import { navItemsQueryKey } from './useNavData';

const messages = defineMessages({
  deleteError: {
    defaultMessage: "Failed to unpin ''{name}'. Please try again.",
    id: 'nan2AR',
  },
  deleteSuccess: {
    defaultMessage: "Successfully unpinned ''{name}'.",
    id: 'Hl4YKm',
  },
});

type UnpinPayload = {
  name: string;
  itemId: string;
};

export function useUnpinItemFromNav() {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { showErrorToast, showSuccessToast } = useToastStore();

  return useMutation({
    mutationFn: async (payload: UnpinPayload) => {
      return assemblyAPI.delete(APIEndpoints.updateNavItems(payload.itemId));
    },
    onSuccess: (data, payload) => {
      const collectionQueryKey = ['collections'];
      const previousCollections =
        queryClient.getQueryData<CollectionsAPIResponse>(collectionQueryKey);
      const updatedCollections = produce(previousCollections, (draft) => {
        if (draft) {
          draft.data = draft.data.map((collection) => {
            if (collection.collectionId === payload.itemId) {
              return {
                ...collection,
                isPinned: false,
              };
            }
            return collection;
          });
        }
      });

      showSuccessToast(
        formatMessage(messages.deleteSuccess, {
          name: payload.name,
        })
      );
      queryClient.setQueryData(collectionQueryKey, updatedCollections);
      queryClient.invalidateQueries({
        queryKey: navItemsQueryKey,
      });
    },
    onError: (err: unknown, payload) => {
      const errorInfo = err instanceof Error ? err : undefined;

      logger.error(
        'Error deleting pinned item',
        { ...payload, error: errorInfo },
        errorInfo
      );

      trackDiscoverError({ action: 'unpinItemFromNav' });

      showErrorToast(
        formatMessage(messages.deleteError, {
          name: payload.name,
        })
      );
    },
  });
}
