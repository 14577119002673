import { TextStyle } from '@assembly-web/ui';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import * as Collapsible from '@radix-ui/react-collapsible';
import type { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import type { RequireAllOrNone } from 'type-fest';

export function NavCollapsibleSection({
  variant,
  title,
  children,
  menu,
  isSubSection = false,
  isSectionOpen,
  onSectionToggle,
  triggerClassName,
}: {
  title: ReactNode;
  children: ReactNode;
  menu?: ReactNode;
  isSubSection?: boolean;
  triggerClassName?: string;
  variant?: 'default' | 'folder' | 'flows' | 'hiddenFolder';
} & RequireAllOrNone<
  {
    isSectionOpen: boolean;
    onSectionToggle: (isOpen: boolean) => void;
  },
  'isSectionOpen' | 'onSectionToggle'
>) {
  const isFolder = variant === 'folder';
  const isHiddenFolder = variant === 'hiddenFolder';
  const isFlows = variant === 'flows';

  return (
    <Collapsible.Root
      open={isSectionOpen}
      onOpenChange={onSectionToggle}
      className="flex w-full flex-col items-start justify-start"
    >
      <Collapsible.Trigger
        className={twMerge(
          'inline-flex w-full items-center gap-1 rounded-lg',
          isSubSection && 'pl-3',
          triggerClassName,
          isFolder && 'justify-between rounded-xl py-1 pl-2 pr-1'
        )}
      >
        <TextStyle
          variant={isFolder || isHiddenFolder ? 'base-medium' : 'sm-bold'}
          subdued={!isFolder}
        >
          {title}
        </TextStyle>
        {isFolder ? (
          <div className="flex items-center gap-2">
            {isSectionOpen ? (
              <ChevronDownIcon className="h-4 w-4" />
            ) : (
              <ChevronRightIcon className="h-4 w-4" />
            )}
            {Boolean(menu) && <div className="ml-auto">{menu}</div>}
          </div>
        ) : (
          <>
            {isSectionOpen ? (
              <ChevronDownIcon className="h-4 w-4" />
            ) : (
              <ChevronRightIcon className="h-4 w-4" />
            )}
            {Boolean(menu) && <div className="ml-auto">{menu}</div>}
          </>
        )}
      </Collapsible.Trigger>
      <Collapsible.Content
        className={
          isSectionOpen
            ? twMerge(
                'mt-2 flex w-full flex-col gap-1',
                isFolder && 'ml-2',
                (isFlows || isFolder || isHiddenFolder) && 'gap-2'
              )
            : ''
        }
      >
        {children}
      </Collapsible.Content>
    </Collapsible.Root>
  );
}
