import {
  type ChallengeSecondaryFilterParams,
  deriveEnumForValue,
  RewardType,
} from '@assembly-web/services';
import { GlobalFilterOption } from '@assembly-web/ui';
import { useSearchParams } from 'react-router-dom';

import { useRewardsDetails } from '../../rewards/hooks/useRewardsDetails';
import { RewardsSecondaryFilterParams } from '../components/rewardsFilter/types';
import { getSortMethodValue, trackDiscoverAction } from '../services/analytics';
import { useFeedSortStore } from '../stores/useFeedSortStore';
import { useGlobalFilter } from './useGlobalFilter';

export function useAppFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const filter = useGlobalFilter();

  const { getSortType } = useFeedSortStore();
  const sortType = getSortType(filter);

  const searchTerm = searchParams.get('search') ?? '';

  const {
    isRewardsTabsDisabled,
    hasCharitiesSetup,
    hasGiftCardsSetup,
    hasCultureSetup,
    hasAmazonSetup,
    hasDiscountsSetup,
  } = useRewardsDetails();

  const getDefaultRewardsType = () => {
    if (isRewardsTabsDisabled) {
      return null;
    }

    let defaultRewardsType;

    switch (true) {
      case hasGiftCardsSetup:
        defaultRewardsType = RewardType.GiftCards;
        break;
      case hasCharitiesSetup:
        defaultRewardsType = RewardType.Charities;
        break;
      case hasCultureSetup:
        defaultRewardsType = RewardType.Culture;
        break;
      case hasAmazonSetup:
        defaultRewardsType = RewardType.Amazon;
        break;
      case hasDiscountsSetup:
        defaultRewardsType = RewardType.Discounts;
        break;
      default:
        defaultRewardsType = RewardType.Swag;
        break;
    }

    const tabValue = searchParams.get('tab');
    let derivedValue =
      deriveEnumForValue(tabValue, RewardType) ?? defaultRewardsType;

    return Object.values(RewardType).includes(derivedValue)
      ? derivedValue
      : defaultRewardsType;
  };

  const rewardsFilter = getDefaultRewardsType();

  const challengeSecondaryFilterParamsArray: ChallengeSecondaryFilterParams[] =
    [
      'challengeStatusType',
      'tableFilterExpanded',
      'claimStatus',
      'tableChallenge',
      'tableDateCreated',
      'tableDateStart',
      'tablePostedBy',
      'tableDateEnd',
      'tableChallengeStatusType',
      'tableDepartment',
    ];

  const onDiscoverPageFilterChange = (filter: GlobalFilterOption) => {
    const isRewardsFilter = filter === GlobalFilterOption.Rewards;
    trackDiscoverAction('filterApplied', {
      filterType: filter,
      sortMethod: isRewardsFilter
        ? searchParams.get('sortOrder')
        : getSortMethodValue(sortType),
      searchQuery: searchTerm,
      connectedTools: '',
      time: new Date().toISOString(),
      ...(isRewardsFilter && { rewardType: rewardsFilter }),
    });
    if (isRewardsFilter) {
      trackDiscoverAction('rewardFilterLoaded', {
        filterType: filter,
        rewardType: rewardsFilter,
      });
    }
    window.scrollTo(0, 0);

    let updatedSearchParams = { ...Object.fromEntries(searchParams) };
    const rewardsFilterParams = new Set(
      Object.values(RewardsSecondaryFilterParams)
    );
    if (filter === GlobalFilterOption.Rewards && rewardsFilter) {
      trackDiscoverAction('rewardTypeSelected', {
        rewardType: rewardsFilter,
        filterType: filter,
      });
      updatedSearchParams = {
        filter,
        tab: rewardsFilter,
        ...(searchTerm && { search: searchTerm }),
      };
    } else {
      const filteredSearchParams = Object.fromEntries(
        Object.entries(updatedSearchParams).filter(
          ([key]) => !rewardsFilterParams.has(key)
        )
      );
      delete filteredSearchParams.tab;
      updatedSearchParams = {
        ...filteredSearchParams,
        filter,
      };
    }
    if (filter !== GlobalFilterOption.Challenges) {
      updatedSearchParams = Object.fromEntries(
        Object.entries(updatedSearchParams).filter(
          ([key]) =>
            !challengeSecondaryFilterParamsArray.includes(
              key as ChallengeSecondaryFilterParams
            )
        )
      );
    }
    setSearchParams(updatedSearchParams);
  };

  return onDiscoverPageFilterChange;
}
