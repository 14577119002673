import {
  APIEndpoints,
  assemblyAPI,
  type FlowCollectionItem,
  type Icon,
  type MetadataProps,
  type PinnedCollection,
} from '@assembly-web/services';
import {
  type InfiniteData,
  infiniteQueryOptions,
  type QueryClient,
  useSuspenseInfiniteQuery,
} from '@tanstack/react-query';
import { produce } from 'immer';

import type { NavItemsResponse } from '../../modules/discover/hooks/nav/useNavData';

type HiddenFoldersAPIResponse = {
  total: number;
  metadata?: MetadataProps;
  data: PinnedCollection[];
};

const defaultParams = {
  limit: 20,
  hidden: true,
};

export function updateFlowDetailsInNavFolders({
  cache,
  flowId,
  flowName,
  flowIcon,
}: {
  cache: NavItemsResponse | undefined;
  flowId: string;
  flowName: string;
  flowIcon: Icon;
}) {
  return produce(cache, (draft) => {
    if (draft?.pages) {
      draft.pages.forEach((page) => {
        page.data.forEach((folder) => {
          folder.listItems = folder.listItems.map((item) => {
            if (item.id === flowId) {
              return {
                ...item,
                name: flowName,
                _meta: {
                  ...item._meta,
                  name: flowName,
                  icon: flowIcon.value,
                },
              } as FlowCollectionItem;
            }
            return item;
          });
        });
      });
    }
  });
}

export function updateFlowInNavHiddenFolders({
  queryClient,
  flowId,
  flowName,
  flowIcon,
}: {
  queryClient: QueryClient;
  flowId: string;
  flowName: string;
  flowIcon: Icon;
}) {
  const hiddenFoldersCache: NavItemsResponse | undefined =
    queryClient.getQueryData(hiddenFoldersQueryOptions.queryKey);

  const updatedCache = updateFlowDetailsInNavFolders({
    cache: hiddenFoldersCache,
    flowId,
    flowName,
    flowIcon,
  });

  queryClient.setQueryData(hiddenFoldersQueryOptions.queryKey, updatedCache);
}

export function removeFlowFromHiddenFolders({
  queryClient,
  flowId,
}: {
  queryClient: QueryClient;
  flowId: string;
}) {
  const hiddenFoldersCache: NavItemsResponse | undefined =
    queryClient.getQueryData(hiddenFoldersQueryOptions.queryKey);

  const updatedCache = produce(hiddenFoldersCache, (draft) => {
    if (draft?.pages) {
      draft.pages.forEach((page) => {
        page.data.forEach((folder) => {
          folder.listItems = folder.listItems.filter(
            (item) => item.id !== flowId
          );
        });
      });
    }
  });
  queryClient.setQueryData(hiddenFoldersQueryOptions.queryKey, updatedCache);
}

export const hiddenFoldersQueryOptions =
  infiniteQueryOptions<HiddenFoldersAPIResponse>({
    queryKey: ['hiddenFolders'],
    queryFn: async ({ pageParam }) => {
      const { data } = await assemblyAPI.post(APIEndpoints.getNavItems, {
        ...(pageParam
          ? { cursor: pageParam, ...defaultParams }
          : defaultParams),
      });
      return data satisfies HiddenFoldersAPIResponse;
    },
    initialPageParam: 0,
    getNextPageParam: (page) => page.metadata?.pagination.cursor.next,
    getPreviousPageParam: (page) => page.metadata?.pagination.cursor.previous,
  });

export async function addHiddenFolderToNav(
  queryClient: QueryClient,
  folder: PinnedCollection
) {
  const hiddenFolders = queryClient.getQueryData(
    hiddenFoldersQueryOptions.queryKey
  );

  if (hiddenFolders) {
    const newHiddenFolders = produce(hiddenFolders, (draft) => {
      draft.pages[0].data.push(folder);
      draft.pages[0].total += 1;
    });

    queryClient.setQueryData<InfiniteData<HiddenFoldersAPIResponse>>(
      hiddenFoldersQueryOptions.queryKey,
      newHiddenFolders
    );
  }
}

export async function updateExpandedFolderStateInNav(
  queryClient: QueryClient,
  folderId: string,
  isExpanded: boolean
) {
  const hiddenFolders = queryClient.getQueryData(
    hiddenFoldersQueryOptions.queryKey
  );

  if (hiddenFolders) {
    const newHiddenFolders = produce(hiddenFolders, (draft) => {
      const folder = draft.pages[0].data.find(
        (folder) => folder.id === folderId
      );

      if (folder) {
        folder.isExpanded = isExpanded;
      }
    });

    queryClient.setQueryData<InfiniteData<HiddenFoldersAPIResponse>>(
      hiddenFoldersQueryOptions.queryKey,
      newHiddenFolders
    );
  }
}

export async function removeHiddenFolderFromNav(
  queryClient: QueryClient,
  folderId: string
) {
  let removedFolder: PinnedCollection | undefined;
  const hiddenFolders = queryClient.getQueryData(
    hiddenFoldersQueryOptions.queryKey
  );

  if (hiddenFolders) {
    const newHiddenFolders = produce(hiddenFolders, (draft) => {
      // We do this because at this spot, this is a proxy object created by immer and we need the actual folder details to be returned
      removedFolder = JSON.parse(
        JSON.stringify(
          draft.pages[0].data.find((folder) => folder.id === folderId)
        )
      ) as PinnedCollection;

      draft.pages[0].data = draft.pages[0].data.filter(
        (folder) => folder.id !== folderId
      );
      draft.pages[0].total -= 1;
    });

    queryClient.setQueryData<InfiniteData<HiddenFoldersAPIResponse>>(
      hiddenFoldersQueryOptions.queryKey,
      newHiddenFolders
    );
  }
  return removedFolder;
}

export function useHiddenFolders() {
  const result = useSuspenseInfiniteQuery<HiddenFoldersAPIResponse>(
    hiddenFoldersQueryOptions
  );

  return {
    ...result,
    hiddenFolders: result.data.pages
      .flatMap((x) => x.data)
      .sort((a, b) => a.name.localeCompare(b.name)),
  };
}
