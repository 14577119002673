import type { CollectionItemsAPIResponse } from '@assembly-web/services';
import {
  useQuery,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query';

import { getCollectionItemsQuery } from '../queries/getCollectionItemsQuery';

export function useGetCollectionItemsQuery(
  collectionId: string,
  options?: UseQueryOptions<CollectionItemsAPIResponse>
): UseQueryResult<CollectionItemsAPIResponse> {
  return useQuery(getCollectionItemsQuery(collectionId, options));
}
