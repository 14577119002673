import type {
  CollectionItemsAPIResponse,
  Icon,
  MetadataProps,
  PinnedCollection,
} from '@assembly-web/services';
import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import type {
  InfiniteData,
  QueryClient,
  UndefinedInitialDataInfiniteOptions,
  UseSuspenseInfiniteQueryResult,
} from '@tanstack/react-query';
import {
  infiniteQueryOptions,
  useInfiniteQuery,
  useSuspenseInfiniteQuery,
} from '@tanstack/react-query';
import { produce } from 'immer';

import { updateFlowDetailsInNavFolders } from '../../../../hooks/folder/useHiddenFolders';

export const navItemsQueryKey = ['navItems'];

type NavItemsPageData = {
  total: number;
  metadata?: MetadataProps;
  data: PinnedCollection[];
};

export type NavItemsResponse = {
  pages: NavItemsPageData[];
  pageParams: unknown[];
};

export function removeItemFromFoldersFeed({
  queryClient,
  entityId,
  collectionId,
}: {
  queryClient: QueryClient;
  entityId: string;
  collectionId: string;
}) {
  const queryKey = ['collectionItems', collectionId];
  const previousCollectionItems: CollectionItemsAPIResponse | undefined =
    queryClient.getQueryData(queryKey);

  const updatedCollectionItems = produce(previousCollectionItems, (draft) => {
    if (draft?.data) {
      draft.data = draft.data.filter((item) => item.id !== entityId);
    }
  });
  queryClient.setQueryData(queryKey, updatedCollectionItems);
}

export function removeFlowFromAllActiveFolders({
  queryClient,
  flowId,
}: {
  queryClient: QueryClient;
  flowId: string;
}) {
  const collectionsCache: NavItemsResponse | undefined =
    queryClient.getQueryData(navItemsQueryKey);

  const updatedCollectionCache = produce(collectionsCache, (draft) => {
    if (draft?.pages) {
      draft.pages.forEach((page) => {
        page.data.forEach((folder) => {
          folder.listItems = folder.listItems.filter(
            (item) => item.id !== flowId
          );
        });
      });
    }
  });
  queryClient.setQueryData(navItemsQueryKey, updatedCollectionCache);
}

export function removeFlowFromNavFolder({
  collectionId,
  queryClient,
  entityId,
}: {
  collectionId: string;
  queryClient: QueryClient;
  entityId: string;
}) {
  const collectionsCache: NavItemsResponse | undefined =
    queryClient.getQueryData(navItemsQueryKey);

  const updatedCollectionCache = produce(collectionsCache, (draft) => {
    if (draft?.pages) {
      draft.pages.forEach((page) => {
        page.data.forEach((folder) => {
          if (folder.id === collectionId) {
            folder.listItems = folder.listItems.filter(
              (item) => item.id !== entityId
            );
          }
        });
      });
    }
  });

  queryClient.setQueryData(navItemsQueryKey, updatedCollectionCache);
}

export function addFlowToNavFolder({
  collectionId,
  queryClient,
  flowName,
  flowIcon,
  entityId,
}: {
  collectionId: string;
  queryClient: QueryClient;
  flowName: string;
  flowIcon: string;
  entityId: string;
}) {
  const collectionsCache: NavItemsResponse | undefined =
    queryClient.getQueryData(navItemsQueryKey);

  const updatedCollectionCache = produce(collectionsCache, (draft) => {
    if (draft?.pages) {
      draft.pages.forEach((page) => {
        page.data.forEach((folder) => {
          if (folder.id === collectionId) {
            folder.listItems.push({
              id: entityId,
              name: flowName,
              type: 'flow',
              _meta: {
                description: '',
                entityId: entityId,
                icon: flowIcon,
                name: flowName,
                ownerId: '',
              },
            });
          }
        });
      });
    }
  });

  queryClient.setQueryData(navItemsQueryKey, updatedCollectionCache);
}

export function updateFlowDetailsInNavActiveFolders({
  flowId,
  flowName,
  flowIcon,
  queryClient,
}: {
  flowId: string;
  flowName: string;
  flowIcon: Icon;
  queryClient: QueryClient;
}) {
  const navQueryCache: NavItemsResponse | undefined =
    queryClient.getQueryData(navItemsQueryKey);

  const updatedCache = updateFlowDetailsInNavFolders({
    cache: navQueryCache,
    flowId,
    flowName,
    flowIcon,
  });

  queryClient.setQueryData(navItemsQueryKey, updatedCache);
}

export function getNavDataInfinite(
  options?: Partial<UndefinedInitialDataInfiniteOptions<NavItemsPageData>>
): UndefinedInitialDataInfiniteOptions<NavItemsPageData> {
  return {
    ...options,
    queryKey: navItemsQueryKey,
    queryFn: async ({ pageParam }) => {
      const { data } = await assemblyAPI.post(APIEndpoints.getNavItems, {
        ...(pageParam ? { cursor: pageParam, limit: 20 } : { limit: 20 }),
      });
      return data satisfies NavItemsPageData;
    },
    initialPageParam: 0,
    getNextPageParam: (page) => page.metadata?.pagination.cursor.next,
    getPreviousPageParam: (page) => page.metadata?.pagination.cursor.previous,
  };
}

export function useNavDataQuery(
  options?: Partial<UndefinedInitialDataInfiniteOptions<NavItemsPageData>>
) {
  const result = useInfiniteQuery<NavItemsPageData>(
    getNavDataInfinite(options)
  );
  const pinnedCollections = result.data?.pages.flatMap((x) => x.data) ?? [];

  const totalNoOfPinnedCollections = result.data?.pages[0].total ?? 0;
  const totalSoftPinnedCollections =
    result.data?.pages[0].data.filter((item) => item.softPin).length ?? 0;

  return {
    ...result,
    pinnedCollections,
    totalNoOfPinnedCollections,
    totalSoftPinnedCollections,
  };
}

export const navDataQueryOptions = infiniteQueryOptions<NavItemsPageData>({
  queryKey: navItemsQueryKey,
  queryFn: async ({ pageParam }) => {
    const { data } = await assemblyAPI.post(APIEndpoints.getNavItems, {
      ...(pageParam ? { cursor: pageParam, limit: 20 } : { limit: 20 }),
    });
    return data satisfies NavItemsPageData;
  },
  initialPageParam: 0,
  getNextPageParam: (page) => page.metadata?.pagination.cursor.next,
  getPreviousPageParam: (page) => page.metadata?.pagination.cursor.previous,
});

export function updateFolderInfo(
  queryClient: QueryClient,
  folder: Pick<
    PinnedCollection,
    'name' | 'colorName' | 'description' | 'icon'
  > & { collectionId: string }
) {
  const oldData = queryClient.getQueryData(navDataQueryOptions.queryKey);

  if (oldData) {
    const newData = produce(oldData, (draft) => {
      draft.pages.forEach((page) => {
        page.data.forEach((item) => {
          if (item.id === folder.collectionId) {
            item.name = folder.name;
            item.colorName = folder.colorName;
            item.description = folder.description;
            item.icon = folder.icon;
          }
        });
      });
    });
    queryClient.setQueryData(navDataQueryOptions.queryKey, newData);
  }
}

export function removeFolderFromNav(
  queryClient: QueryClient,
  folderID: string
) {
  const oldData = queryClient.getQueryData(navDataQueryOptions.queryKey);
  let folderToRemove: PinnedCollection | undefined;

  if (oldData) {
    const newData = produce(oldData, (draft) => {
      draft.pages.forEach((page) => {
        folderToRemove = page.data.find((item) => item.id === folderID);

        if (folderToRemove) {
          page.data = page.data.filter((item) => item.id !== folderID);
          page.total = page.data.length;

          // We do this because at this spot, this is a proxy object created by immer and we need the actual folder details to be returned
          folderToRemove = JSON.parse(
            JSON.stringify(folderToRemove)
          ) as PinnedCollection;
        }
      });
    });

    queryClient.setQueryData(navDataQueryOptions.queryKey, newData);
  }
  return folderToRemove;
}

export function addFolderToNav(
  queryClient: QueryClient,
  folder: PinnedCollection
) {
  const oldData = queryClient.getQueryData(navDataQueryOptions.queryKey);

  if (oldData) {
    const newData = produce(oldData, (draft) => {
      draft.pages.forEach((page) => {
        page.data.push(folder);
        page.total = page.data.length;
      });
    });

    queryClient.setQueryData(navDataQueryOptions.queryKey, newData);
  }
}

function getAdditionalFolderInfo(
  originalData: UseSuspenseInfiniteQueryResult<InfiniteData<NavItemsPageData>>
) {
  const pinnedCollections = originalData.data.pages.flatMap((x) => x.data);
  const totalNoOfPinnedCollections = originalData.data.pages[0].total;
  const totalSoftPinnedCollections = originalData.data.pages[0].data.filter(
    (item) => item.softPin
  ).length;

  return {
    pinnedCollections,
    totalNoOfPinnedCollections,
    totalSoftPinnedCollections,
  };
}

export function useNavData() {
  const result = useSuspenseInfiniteQuery(navDataQueryOptions);

  return {
    ...result,
    ...getAdditionalFolderInfo(result),
  };
}
