import { postIcon } from '@assembly-web/assets';
import {
  canUserDeletePost,
  checkIfFlowPostHasTrophies,
  copyToClipboard,
  isUserAdmin,
  type ItemToAdd,
  mapHexCodeToEmoticon,
  type ResponseDetails,
} from '@assembly-web/services';
import { config } from '@assembly-web/services';
import type { ToolbarItem } from '@assembly-web/ui';
import { useAssemblyNavigate, useToastStore } from '@assembly-web/ui';
import { isAxiosError } from 'axios';
import { useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useGetPostAndRepliesDetails } from '../../../../../../hooks/useGetPostAndRepliesDetails';
import { CollectionModalsContext } from '../../../../contexts/CollectionModalsContext';
import { ModalsContext } from '../../../../contexts/ModalsContext';
import { AnnouncementsContext } from '../../../../hooks/useAnnouncements';
import { useEndAnnouncementMutation } from '../../../../hooks/useEndAnnouncementMutation';
import { useGetEntityPermissionQuery } from '../../../../hooks/useGetEntityPermissionQuery';
import { useIsNewUX } from '../../../../hooks/useIsNewUX';
import { useUpdateAssemblyPostNotificationMutationQuery } from '../../../../hooks/useUpdateAssemblyPostNotificationPreferenceMutationQuery';
import {
  trackReplyDrawerAction,
  trackReplyDrawerError,
} from '../../../../services/analytics';
import { getFlowOrRecognitionPostLinkToCopy } from '../../../../services/common';
import {
  defaultRecognitionFlowIcon,
  getToolbarMenuItemsForResponseCard,
  ToolbarItemId,
} from '../../../cards/utils';

const messages = defineMessages({
  copiedToClipboard: {
    defaultMessage: 'Link successfully copied to clipboard',
    id: 'QHwY68',
  },
  postByLabel: {
    defaultMessage:
      'Post by {author, select, self {you} other {{author}}} in {flow}',
    id: 'd/Wpk/',
  },
  notificationPreferenceSuccess: {
    defaultMessage: "You successfully {action} {author}'s post",
    id: 'nuj7hI',
  },
  notificationPreferenceError: {
    defaultMessage: '`Unable to {action} this post, please try again later',
    id: 'IQb/Ct',
  },
});

type PostToolbarHook = {
  flowId: string;
  responseId: string;
  kind: 'RESPONSE' | 'POST';
};

export function usePostToolbar({ kind, flowId, responseId }: PostToolbarHook) {
  const { formatMessage } = useIntl();

  const isNewUX = useIsNewUX();

  const { showSuccessToast, showErrorToast } = useToastStore();

  const { currentUser, flowResponse } = useGetPostAndRepliesDetails({
    flowId,
    responseId,
    enabled: true,
  });

  const { data: permissions } = useGetEntityPermissionQuery({
    entityIds: [responseId],
  });

  const { openAnnouncementModal, openAnnouncementsInsightsModal } =
    useContext(AnnouncementsContext);

  const { onDeleteReplyOrPostClick } = useContext(ModalsContext);

  const navigate = useAssemblyNavigate();

  const handleNotificationPreferenceChangeSuccess = () => {
    showSuccessToast(
      formatMessage(messages.notificationPreferenceSuccess, {
        action: flowResponse?.isMuted ? 'followed' : 'unfollowed',
        author: flowResponse?.respondent.firstName,
      })
    );
  };

  const handleNotificationPreferenceChangeError = (error: unknown) => {
    const errorObj = isAxiosError(error) ? error : undefined;
    showErrorToast(
      formatMessage(messages.notificationPreferenceError, {
        action: flowResponse?.isMuted ? 'follow' : 'unfollow',
      })
    );
    trackReplyDrawerError({
      errorType:
        errorObj?.response?.data?.message ??
        'Error on notification preference change',
    });
  };

  const { mutate: endAnnouncement } = useEndAnnouncementMutation(
    (error: unknown) => {
      const errorObj = isAxiosError(error) ? error : undefined;
      trackReplyDrawerError({
        errorType:
          errorObj?.response?.data?.message ?? 'Error on end announcement',
      });
    }
  );

  const { mutate: updateAssemblyPostNotificationPreference } =
    useUpdateAssemblyPostNotificationMutationQuery();

  const { openAddToPinnedCollectionModal, openAddToCollectionModal } =
    useContext(CollectionModalsContext);

  const { secondaryToolbarItems } = getToolbarMenuItemsForResponseCard(
    {
      formatMessage,
      isAuthor:
        currentUser.member.memberId === flowResponse?.respondent.memberID,
      isAdmin: isUserAdmin(currentUser.member),
      isFollowingThread: false,
      canShowViewInsights:
        flowResponse?.activeAnnouncement?.showInsights ?? false,
      showPostInteractionSettings: flowResponse?.showTurnOffSettings,
      canShareAsAnnouncement:
        permissions?.data.find((x) => x.entityId === responseId)
          ?.canShareAsAnnouncement ?? false,
      canEditAnnouncement:
        permissions?.data.find((x) => x.entityId === responseId)
          ?.canEditAnnouncement ?? false,
      canEndAnnouncement:
        permissions?.data.find((x) => x.entityId === responseId)
          ?.canEndAnnouncement ?? false,
      postNotificationPreference: {
        isMuted: flowResponse?.isMuted ?? false,
      },
      canDeletePost: canUserDeletePost(currentUser) || flowResponse?.canDelete,
    },
    isNewUX
  );

  const workSpaceSlugPath = `${currentUser.assembly.workspaceSlug.name}-${currentUser.assembly.workspaceSlug.shortCode}`;

  const flowEmoji = mapHexCodeToEmoticon(
    flowResponse?.flow.icon.value ?? defaultRecognitionFlowIcon
  );
  const flowName = flowResponse?.flow.name;
  const plainFlow = `${flowEmoji} ${flowName}`;
  const plainAuthor = flowResponse?.kind.includes('ANONYMOUS')
    ? 'Anonymous'
    : flowResponse?.respondent.name;

  const plainTitle = formatMessage(messages.postByLabel, {
    flow: plainFlow,
    workspaceName: currentUser.assembly.name,
    author:
      flowResponse?.respondent.memberID === currentUser.member.memberId
        ? 'self'
        : plainAuthor,
  });

  const icon = (
    <img src={postIcon} alt="" className="h-4 w-4 max-md:max-w-none" />
  );

  const handleMenuItemClick = async (args: ToolbarItem) => {
    switch (args.id) {
      case ToolbarItemId.ShareAsAnnouncement:
        openAnnouncementModal({
          flowId,
          responseId,
          allowReplies: Boolean(!flowResponse?.hideReplies),
          allowReactions: Boolean(!flowResponse?.hideReactions),
        });
        trackReplyDrawerAction('shareAsAnnouncementClicked', {
          flowId,
          isRecognition: kind === 'POST',
          flowName: flowName ?? 'Recognition',
          isAnnouncement: Boolean(
            flowResponse?.activeAnnouncement?.announcementId
          ),
        });
        break;
      case ToolbarItemId.CopyLink:
        {
          const baseUrl = config.domains.app;
          const linkToCopy = getFlowOrRecognitionPostLinkToCopy({
            type: kind === 'POST' ? 'post' : 'flow',
            postId: kind === 'POST' ? responseId : '',
            flowId: kind === 'RESPONSE' ? flowId : '',
            responseId: kind === 'RESPONSE' ? responseId : '',
            commentId: '',
          });
          await copyToClipboard(`${baseUrl}${linkToCopy}`);
          showSuccessToast(formatMessage(messages.copiedToClipboard));
          trackReplyDrawerAction('linkCopied', {
            flowId,
            isRecognition: kind === 'POST',
            flowName: flowName ?? 'Recognition',
            isAnnouncement: Boolean(
              flowResponse?.activeAnnouncement?.announcementId
            ),
          });
        }
        break;
      case ToolbarItemId.PinItemToCollection:
        {
          const item: ItemToAdd = {
            icon: icon,
            name: plainTitle,
            entityId: responseId,
            type: flowId === 'recognition' ? 'post' : 'response',
          };
          openAddToPinnedCollectionModal(item);
          trackReplyDrawerAction('pinItemClicked', {
            flowId,
            isRecognition: kind === 'POST',
            flowName: flowName ?? 'Recognition',
            isAnnouncement: Boolean(
              flowResponse?.activeAnnouncement?.announcementId
            ),
          });
        }
        break;
      case ToolbarItemId.AddToCollection:
        {
          const item: ItemToAdd = {
            icon: icon,
            name: plainTitle,
            entityId: responseId,
            type: flowId === 'recognition' ? 'post' : 'response',
          };
          openAddToCollectionModal(item);
          trackReplyDrawerAction('addToCollection', {
            flowId,
            isRecognition: kind === 'POST',
            flowName: flowName ?? 'Recognition',
            isAnnouncement: Boolean(
              flowResponse?.activeAnnouncement?.announcementId
            ),
          });
        }
        break;
      case ToolbarItemId.EditPost:
        {
          trackReplyDrawerAction('editPostClicked', {
            flowId,
            isRecognition: kind === 'POST',
            flowName: flowName ?? 'Recognition',
            isAnnouncement: Boolean(
              flowResponse?.activeAnnouncement?.announcementId
            ),
          });

          if (kind === 'RESPONSE') {
            navigate(
              `/${workSpaceSlugPath}/flows/${flowId}/edit/${responseId}?redirectUrl=${window.location.href}`
            );
          } else {
            navigate(
              `/${workSpaceSlugPath}/flows/recognition/edit/${responseId}?redirectUrl=${window.location.href}`
            );
          }
        }
        break;
      case ToolbarItemId.ManageInteractionSettings:
        {
          const responseDetailsPayload: ResponseDetails = {
            flowId,
            responseId,
            allowReactions: Boolean(!flowResponse?.hideReactions),
            allowReplies: Boolean(!flowResponse?.hideReplies),
            flowName: flowResponse
              ? `${mapHexCodeToEmoticon(flowResponse.flow.icon.value)} ${
                  flowResponse.flow.name
                }`
              : '',
            author: flowResponse?.respondent.name ?? '',
            emoticon: '',
          };
          window.postMessage(
            {
              type: 'OPEN_CONFIGURE_POST_INTERACTION_SETTINGS_MODAL',
              payload: responseDetailsPayload,
            },
            window.location.origin
          );
          trackReplyDrawerAction('editPostInteractionsClicked', {
            flowId,
            isRecognition: kind === 'POST',
            flowName: flowName ?? 'Recognition',
            isAnnouncement: Boolean(
              flowResponse?.activeAnnouncement?.announcementId
            ),
          });
        }
        break;
      case ToolbarItemId.EditAnnouncement:
        {
          openAnnouncementModal({
            flowId,
            responseId,
            activeAnnouncement: flowResponse?.activeAnnouncement,
            allowReactions: Boolean(!flowResponse?.hideReactions),
            allowReplies: Boolean(!flowResponse?.hideReplies),
          });
        }
        break;
      case ToolbarItemId.ViewAnnouncementInsights:
        {
          openAnnouncementsInsightsModal(
            flowResponse?.activeAnnouncement?.announcementId ?? ''
          );
        }
        break;
      case ToolbarItemId.EndAnnouncement:
        {
          endAnnouncement({
            flowId,
            responseId,
            announcementId:
              flowResponse?.activeAnnouncement?.announcementId ?? '',
          });
        }
        break;
      case ToolbarItemId.DeleteAssemblyPost:
        {
          onDeleteReplyOrPostClick({
            replyId: null,
            flowId,
            responseId,
            hasTrophies: flowResponse?.responses
              ? checkIfFlowPostHasTrophies(flowResponse.responses)
              : false,
          });
          trackReplyDrawerAction('deletePostClicked', {
            flowId,
            isRecognition: kind === 'POST',
            flowName: flowName ?? 'Recognition',
            isAnnouncement: Boolean(
              flowResponse?.activeAnnouncement?.announcementId
            ),
          });
        }
        break;
      case ToolbarItemId.PostNotificationPreference:
        {
          updateAssemblyPostNotificationPreference(
            {
              postType: kind === 'RESPONSE' ? 'RESPONSE' : 'POST',
              flowId,
              responseId,
              type: flowResponse?.isMuted ? 'SUBSCRIBE' : 'UNSUBSCRIBE',
            },
            {
              onSuccess: handleNotificationPreferenceChangeSuccess,
              onError: handleNotificationPreferenceChangeError,
            }
          );
          const userAction = flowResponse?.isMuted
            ? 'followThreadClicked'
            : 'unfollowThreadClicked';
          trackReplyDrawerAction(userAction, {
            flowId,
            isRecognition: kind === 'POST',
            flowName: flowName ?? 'Recognition',
            isAnnouncement: Boolean(
              flowResponse?.activeAnnouncement?.announcementId
            ),
          });
        }

        break;
    }
  };

  return {
    secondaryToolbarItems,
    handleMenuItemClick,
  };
}
