import { fileIcons } from '@assembly-web/assets';
import type { SecondaryFiltersType } from '@assembly-web/services';
import { FileType } from '@assembly-web/services';
import {
  GlobalFilterOption,
  type SelectableOptionProps,
} from '@assembly-web/ui';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useGetSecondaryFilterOptionsQuery } from '../useGetSecondaryFilterOptionsQuery';
import { useGlobalFilter } from '../useGlobalFilter';

export function useGetFilesSecondaryFilters({
  secondaryFilterTypes,
}: {
  secondaryFilterTypes: Record<string, SecondaryFiltersType>;
}) {
  const [params] = useSearchParams();

  // Filetype Filter
  const isFilesTabSelected = useGlobalFilter() === GlobalFilterOption.Files;
  const selectedFileTypes = params.get('fileType')?.split(',') ?? [];

  const {
    isPending: isFileTypeDataPending,
    hasNextPage: hasMoreFileTypes,
    fetchNextPage: fetchMoreFileTypes,
    data: fileTypeData,
    isFetchingNextPage: isFetchingMoreFileTypes,
  } = useGetSecondaryFilterOptionsQuery({
    topLevelFilter: 'FILE',
    secondaryFilter: {
      filter: secondaryFilterTypes['fileType'],
      includeEntityIds: [],
    },
    enabled: isFilesTabSelected,
    searchTerm: '',
  });

  const fileTypeOptions: SelectableOptionProps[] = useMemo(() => {
    if (!fileTypeData) return [];
    return fileTypeData.pages.reduce<SelectableOptionProps[]>((acc, page) => {
      return [
        ...acc,
        ...page.FILE_TYPE.map((item) => {
          const fileType = item.key.charAt(0).toLowerCase() + item.key.slice(1);
          return {
            id: item.key,
            value: item.key,
            icon: fileIcons[
              item.key === 'Others'
                ? FileType.GenericFile
                : (fileType as FileType)
            ],
          };
        }),
      ];
    }, []);
  }, [fileTypeData]);

  const fileTypesSelectedOptions = fileTypeOptions.filter((item) =>
    selectedFileTypes.includes(item.id)
  );

  const fileTypesProps = {
    fileTypeData,
    isFileTypeDataPending,
    hasMoreFileTypes,
    fetchMoreFileTypes,
    isFetchingMoreFileTypes,
    fileTypeOptions,
    fileTypesSelectedOptions,
    hasSelectedFileTypes: selectedFileTypes.length > 0,
  };

  return { fileTypesProps };
}
