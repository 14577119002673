// eslint-disable-next-line @eslint-community/eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-explicit-any */

import type { PostData } from '../../../../types/postsAndReplies';
import type { DoraResponseMetadata } from './AskDoraDrawer/hooks/useDoraEventSource';
import { config, type DrawerConfig } from './configuration';

export type ChallengeData = {
  commentId?: string;
  challengeId: string;
  commentIdToEdit?: string;
};

export type FlowSummaryData = { flowId: string; flowTitle: string };

export type AskDoraData = { query: string; metadata?: DoraResponseMetadata };

// TODO: [siva] this is too generic, we should narrow it down
export type DoraChatData = object | { prompt: string; isDefaultTitle: boolean };

export type FlowCreationData = {
  view: 'chat' | 'empty' | 'editor' | 'permissions';
  id: string;
  type: 'template' | 'edit' | 'scratch' | 'duplicate';
};

export type ChatData = {
  image?: string;
  memberId: string;
  lastName: string;
  firstName: string;
};

export type DrawerData =
  | PostData
  | FlowSummaryData
  | AskDoraData
  | DoraChatData
  | FlowCreationData
  | ChatData;

export type DrawerType = Drawer['type'];

export type ChallengesDrawer = {
  data: ChallengeData;
  type: 'challenges';
} & BaseDrawer;

export type PostDrawerProps = {
  data: PostData;
  type: 'postsAndReplies';
} & BaseDrawer;

export type FlowSummaryDrawer = {
  data: FlowSummaryData;
  type: 'flowSummary';
} & BaseDrawer;

export type FlowResponseDrawer = {
  data: FlowSummaryData;
  type: 'flow_responses';
} & BaseDrawer;

export type AskDoraDrawer = {
  data: AskDoraData;
  type: 'doraQ';
} & BaseDrawer;

export type DoraChatDrawer = {
  data: DoraChatData;
  isFullScreen?: boolean;
  type: 'doraChat';
} & BaseDrawer;

export type FlowCreationDrawer = {
  data: FlowCreationData;
  type: 'flowCreation';
} & BaseDrawer;

export type ChatDrawer = {
  type: 'chat';
  data: ChatData;
} & BaseDrawer;

export type BaseDrawer = {
  hasNewActivity?: boolean;
  id: string;
  title: string;
  lastInteractedTS: number;
  isOpen: boolean;
  inputDraftValue?: string;
  newActivityType?: 'info' | 'warning';
};

export type CreateDrawerPayload = {
  data: DrawerData;
  shouldOpenInFullScreen?: boolean;
  title: string;
  type: DrawerType;
};

type PropsMap = {
  postsAndReplies: PostDrawerProps;
  flowSummary: FlowSummaryDrawer;
  flow_responses: FlowResponseDrawer;
  doraChat: DoraChatDrawer;
  doraQ: AskDoraDrawer;
  flowCreation: FlowCreationDrawer;
  challenges: ChallengesDrawer;
  chat: ChatDrawer;
};

type DataMap = {
  postsAndReplies: PostData;
  flowSummary: FlowSummaryData;
  flow_responses: FlowSummaryData;
  doraChat: DoraChatData;
  doraQ: AskDoraData;
  flowCreation: FlowCreationData;
  challenges: ChallengesDrawer;
  chat: ChatData;
};

export type DoraDrawer = AskDoraDrawer | DoraChatDrawer | FlowCreationDrawer;

export type Drawer =
  | PostDrawerProps
  | FlowSummaryDrawer
  | FlowResponseDrawer
  | DoraDrawer
  | ChallengesDrawer
  | ChatDrawer;

export type DoraFeedbackPromptSource = DoraDrawer['type'];

export function accessDrawer<
  TDrawer extends { type: DrawerType },
  TDrawerType extends DrawerType,
  TReturn,
>(
  drawer: TDrawer,
  type: TDrawerType,
  cb?: (
    drawerConfig: DrawerConfig<any, any>,
    props: PropsMap[TDrawerType]
  ) => TReturn
): TReturn | null;

export function accessDrawer<TDrawerType extends DrawerType, TReturn>(
  type: TDrawerType,
  data: DrawerData,
  cb?: (
    drawerConfig: DrawerConfig<any, any>,
    props: DataMap[TDrawerType]
  ) => TReturn
): TReturn | null;

export function accessDrawer(drawerOrType: any, typeOrData: any, cb: any) {
  if (typeof drawerOrType === 'string' && isDrawerType(drawerOrType)) {
    return cb(config[drawerOrType], narrowDrawerData(typeOrData));
  }
  if (drawerOrType.type === typeOrData && isDrawerType(typeOrData)) {
    return cb?.(config[typeOrData], drawerOrType);
  }
  return null;
}

export const isDrawerType = (type: string): type is DrawerType => {
  return type in config;
};

const narrowDrawerData = (data: DrawerData): DataMap[DrawerType] => {
  if ('postId' in data || ('flowId' in data && 'responseId' in data)) {
    return data;
  }
  if ('flowId' in data) {
    return data;
  }
  return data;
};
