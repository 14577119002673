import {
  SplitNames,
  useFeatureSplit,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import type { GlobalFilterOption } from '@assembly-web/ui';
import { GlobalFilter } from '@assembly-web/ui';

import { useUnreadNotificationsCount } from '../../../hooks/useUnreadNotificationsCount';
import { useRewardsDetails } from '../../rewards/hooks/useRewardsDetails';
import { useIsNewUX } from '../hooks/useIsNewUX';
import { getDefaultRewardsType } from '../services/common';
import { isAdminMember } from '../services/member';

type DiscoverPageFilterSectionProps = {
  filter: GlobalFilterOption;
  aggregations: Record<GlobalFilterOption, number> | undefined;
  onFilterChange: (filter: GlobalFilterOption) => void;
};

export function DiscoverPageFilterSection({
  filter,
  aggregations,
  onFilterChange,
}: DiscoverPageFilterSectionProps) {
  const {
    hasCharitiesSetup,
    hasGiftCardsSetup,
    hasCultureSetup,
    isRewardsTabsDisabled,
    isRewardsSetupForUser,
  } = useRewardsDetails();

  const { isTreatmentActive: isV3RewardsEnabled } = useFeatureSplit(
    SplitNames.RewardsV3
  );

  const rewardsFilter = getDefaultRewardsType({
    hasCharitiesSetup,
    hasCultureSetup,
    hasGiftCardsSetup,
    isRewardsTabsDisabled,
  });

  const { data: currentUserDetails } = useSuspenseUserDetails();
  const isAdmin = isAdminMember(currentUserDetails.member);

  const showRewardsTab =
    isV3RewardsEnabled && (isAdmin || isRewardsSetupForUser);

  const isNewUX = useIsNewUX();

  const showChallengesTab =
    isAdmin || currentUserDetails.assembly.settings.challenge.creation.enabled;

  const notificationsCount = useUnreadNotificationsCount();

  return (
    <GlobalFilter
      isNewUX={isNewUX}
      selectedFilter={filter}
      aggregations={aggregations}
      showRewardsTab={showRewardsTab}
      onFilterChange={onFilterChange}
      showChallengesTab={showChallengesTab}
      notificationsCount={notificationsCount}
      hideAggregationsValue={rewardsFilter === 'amazon'}
    />
  );
}
