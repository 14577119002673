import type { SearchSuggestedQuestionsApiResponse } from '@assembly-web/services';
import {
  useQuery,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query';

import { getSuggestedQuestionsQuery } from '../queries/getSuggestedQuestionsQuery';

export function useGetSuggestedQuestionsQuery({
  searchTerm,
  limit,
  options,
}: {
  searchTerm: string;
  limit?: number;
  options?: Partial<UseQueryOptions<SearchSuggestedQuestionsApiResponse>>;
}): UseQueryResult<SearchSuggestedQuestionsApiResponse> {
  return useQuery(getSuggestedQuestionsQuery(searchTerm, limit, options));
}
