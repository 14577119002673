import { type ReactNode, Suspense } from 'react';
import { useMatches } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import type { Primitive } from 'type-fest';

import { useIsRecognitionOnlyCustomer } from '../../hooks/useIsRecognitionOnlyCustomer';
import { AskDoraCTA } from '../Nav/AskDoraCTA';
import {
  FlowsNavSection,
  FlowsNavSectionSkeleton,
} from '../Nav/FlowsNavSection';
import {
  FoldersNavSection,
  FoldersNavSectionSkeleton,
} from '../Nav/Folder/FoldersNavSection';
import { MainNavSection, MainNavSectionSkeleton } from '../Nav/MainNavSection';
import { NavFooter } from '../Nav/NavFooter';
import { NavHeader } from '../Nav/NavHeader';
import { DesktopAppHeader } from './AppHeader';
import { SecondaryContentSection } from './SecondaryContent/SecondaryContentSection';

export function DesktopLayout({ children }: { children: ReactNode }) {
  const isRecognitionOnlyCustomer = useIsRecognitionOnlyCustomer();

  const isLegacyView = useMatches().some((match) =>
    Boolean(
      (match.handle as undefined | Record<string, Primitive>)?.isLegacyView
    )
  );

  return (
    <div className="theme-surface flex min-h-screen">
      <nav className="group/nav sticky top-0 flex h-screen w-[222px] min-w-[222px] flex-col overflow-hidden border-r-[0.75px] border-gray-5 bg-gray-1 p-4 pr-0 lg:w-[300px] lg:min-w-[300px]">
        <NavHeader />

        <div className="mb-4 mt-10 flex h-screen flex-col gap-8 overflow-y-auto overflow-x-hidden pl-0.5 pr-4">
          <Suspense fallback={<MainNavSectionSkeleton />}>
            <MainNavSection
              isRecognitionOnlyCustomer={isRecognitionOnlyCustomer}
            />
          </Suspense>

          <div className="flex flex-col gap-4">
            {!isRecognitionOnlyCustomer && (
              <>
                <Suspense fallback={<FoldersNavSectionSkeleton />}>
                  <FoldersNavSection />
                </Suspense>

                <Suspense fallback={<FlowsNavSectionSkeleton />}>
                  <FlowsNavSection />
                </Suspense>
              </>
            )}
          </div>
          <NavFooter />
        </div>
        {!isRecognitionOnlyCustomer && <AskDoraCTA />}
      </nav>

      <main className="theme-surface flex-1 overflow-hidden">
        <DesktopAppHeader />

        <div className="relative flex pt-[88px]">
          <div className="feed flex flex-1 justify-center overflow-x-hidden">
            <div
              className={twMerge(
                'w-full flex-shrink overflow-hidden',
                !isLegacyView && 'md:max-w-[758px]'
              )}
            >
              {children}
            </div>
          </div>
          {!isLegacyView && (
            <div className="relative w-[18rem] xl:w-[22rem]">
              <SecondaryContentSection className="fixed flex h-[calc(100vh-80px)] w-[inherit] flex-col gap-8 overflow-y-auto pb-12 pl-1 pr-6 pt-4" />
            </div>
          )}
        </div>
      </main>
    </div>
  );
}
