import {
  APIEndpoints,
  assemblyAPI,
  type Collection,
  type CollectionsAPIResponse,
} from '@assembly-web/services';
import {
  queryOptions,
  useQuery,
  type UseQueryOptions,
  type UseQueryResult,
  useSuspenseQuery,
} from '@tanstack/react-query';

export const foldersQueryOptions = queryOptions({
  queryKey: ['collections'],
  queryFn: async () => {
    const response = await assemblyAPI.get<CollectionsAPIResponse>(
      APIEndpoints.listCollections
    );
    return response.data;
  },
});

export function useFolders() {
  const collections = useSuspenseQuery(foldersQueryOptions);

  const pinnedCollections = collections.data.data.filter((collection) => {
    return collection.isPinned;
  });

  return {
    ...collections,
    pinnedCollections,
  };
}

export function useGetCollectionsQuery(
  options?: Partial<UseQueryOptions<CollectionsAPIResponse>>
): UseQueryResult<CollectionsAPIResponse> & {
  pinnedCollections: Collection[];
} {
  const collections = useQuery({
    ...options,
    queryKey: ['collections'],
    queryFn: async () => {
      const response = await assemblyAPI.get<CollectionsAPIResponse>(
        APIEndpoints.listCollections
      );
      return response.data;
    },
  });

  const pinnedCollections =
    collections.data?.data.filter((collection) => {
      return collection.isPinned;
    }) ?? [];

  return {
    ...collections,
    pinnedCollections,
  };
}
