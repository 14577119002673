import { assemblyLogo } from '@assembly-web/assets';
import { config, getFaviconSource, isTruthy } from '@assembly-web/services';
import { Avatar, AvatarSize, Challenge, ProfileViewer } from '@assembly-web/ui';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';
import { useContext } from 'react';

import { FilePreviewModalContext } from '../../../contexts/FilePreviewModalContext';
import { useFlowNavItems } from '../../../hooks/flows/useFlowNavItems';
import type { useFolderItemDetails } from '../../../hooks/folder/useFolderItemDetails';
import { useNavigateToUserFeed } from '../../../hooks/useNavigateToUserFeed';
import { usePosts } from '../../../hooks/usePosts';
import { useReplies } from '../../../hooks/useReplies';
import { useChallengeDrawer } from '../../../modules/discover/hooks/challenges/useChallengeDrawer';
import { useActionBar } from '../../../modules/discover/hooks/useActionBarQuery';
import { FlowNavItem } from '../FlowNavItem';
import { NavItem } from '../NavItem';

export function FolderItem({
  folderInfo,
}: {
  folderInfo: ReturnType<ReturnType<typeof useFolderItemDetails>>;
}) {
  const getFlowNavItems = useFlowNavItems();
  const { data: actionBarData } = useActionBar();

  const { openChallengeDrawer } = useChallengeDrawer();
  const { navigate: navigateToUserFeed } = useNavigateToUserFeed();
  const { onPostClicked } = usePosts();
  const { onReplyClicked } = useReplies();

  const { openModal } = useContext(FilePreviewModalContext);

  if (!isTruthy(folderInfo)) {
    return null;
  }

  if (folderInfo.type === 'flow') {
    const { id } = folderInfo;

    const flowItem = actionBarData.flowsToView.find((x) => x.flowId === id);

    if (!flowItem) {
      return null;
    }

    const flow = getFlowNavItems([flowItem])[0];

    return <FlowNavItem flow={flow} />;
  }

  if (folderInfo.type === 'challenge') {
    const { title, challengeId } = folderInfo;

    if (!challengeId) {
      return null;
    }

    return (
      <NavItem.Button
        className="h-auto py-1"
        text={title}
        icon={<img src={Challenge} alt="" className="h-4 w-4" />}
        onClick={() => openChallengeDrawer(challengeId)}
      />
    );
  }

  if (folderInfo.type === 'member') {
    const { memberName, iconUrl, fullName, memberID, url } = folderInfo;

    if (!memberID) {
      return null;
    }

    return (
      <ProfileViewer
        userDetails={{ memberID, firstName: memberName, lastName: '' }}
        onViewProfileClick={navigateToUserFeed}
      >
        <NavItem.InternalLink
          className="h-auto py-1"
          url={url}
          text={fullName}
          icon={
            <Avatar
              size={AvatarSize.ExtraSmall}
              image={iconUrl}
              name={fullName}
              memberID={memberID}
            />
          }
        />
      </ProfileViewer>
    );
  }

  if (folderInfo.type === 'weblink') {
    const { isRecognitionFlow, title, url, plainUrl } = folderInfo;

    if (!plainUrl) {
      return null;
    }

    if (isRecognitionFlow) {
      return (
        <NavItem.InternalLink
          className="h-auto py-1"
          url={url}
          text={title}
          icon={<div className="px-[1px]">🎉</div>}
        />
      );
    }

    return (
      <NavItem.ExternalLink
        className="h-auto py-1"
        url={plainUrl}
        text={title}
        icon={
          <img src={getFaviconSource(plainUrl)} alt="" className="h-4 w-4" />
        }
      />
    );
  }

  if (folderInfo.type === 'url') {
    const { title, url, urlSearchType } = folderInfo;

    if (!url) {
      return null;
    }

    if (urlSearchType === 'admin') {
      return (
        <NavItem.ExternalLink
          className="h-auto py-1"
          url={`${config.domains.adminApp}${url}`}
          text={title}
          icon={<img src={assemblyLogo} alt="" className="h-4 w-4" />}
        />
      );
    }

    return (
      <NavItem.InternalLink
        className="h-auto py-1"
        url={url}
        text={title}
        icon={<img src={assemblyLogo} alt="" className="h-4 w-4" />}
      />
    );
  }

  if (folderInfo.type === 'externalFile') {
    const { imgIcon, text, url } = folderInfo;

    if (!url) {
      return null;
    }

    return (
      <NavItem.ExternalLink
        className="h-auto py-1"
        text={text}
        url={url}
        icon={<img src={imgIcon} alt="" className="h-4 w-4" />}
      />
    );
  }

  if (folderInfo.type === 'challengeFile') {
    const { imgIcon, text } = folderInfo;

    // TODO: meghna - handle this case
    return (
      <NavItem.Button
        className="h-auto py-1"
        text={text}
        icon={<img src={imgIcon} alt="" className="h-4 w-4" />}
      />
    );
  }

  if (folderInfo.type === 'flowFile') {
    const { imgIcon, text, filePreviewerProps } = folderInfo;

    return (
      <NavItem.Button
        className="h-auto py-1"
        text={text}
        icon={<img src={imgIcon} alt="" className="h-4 w-4" />}
        onClick={() => openModal(filePreviewerProps)}
      />
    );
  }

  if (folderInfo.type === 'response') {
    const { imgIcon, text, flowId, responseId } = folderInfo;

    if (!flowId || !responseId) {
      return null;
    }

    return (
      <NavItem.Button
        className="h-auto py-1"
        text={text}
        icon={<img src={imgIcon} alt="" className="h-4 w-4" />}
        onClick={() => onPostClicked({ type: 'flow', flowId, responseId })}
      />
    );
  }

  if (folderInfo.type === 'post') {
    const { text, imgIcon, postId } = folderInfo;

    if (!postId) {
      return null;
    }

    return (
      <NavItem.Button
        text={text}
        className="h-auto py-1"
        icon={<img src={imgIcon} alt="" className="h-4 w-4" />}
        onClick={() => onPostClicked({ type: 'post', postId })}
      />
    );
  }

  if (folderInfo.type === 'challengeComment') {
    const { challengeId, text } = folderInfo;

    return (
      <NavItem.Button
        text={text}
        className="h-auto py-1"
        icon={<ChatBubbleBottomCenterTextIcon className="h-4 w-4 stroke-2" />}
        onClick={() => openChallengeDrawer(challengeId)}
      />
    );
  }

  if (folderInfo.type === 'comment') {
    const { postId, commentId, flowId, responseId, text } = folderInfo;

    return (
      <NavItem.Button
        className="h-auto py-1"
        text={text}
        icon={<ChatBubbleBottomCenterTextIcon className="h-4 w-4 stroke-2" />}
        onClick={() => {
          onReplyClicked(
            postId
              ? { type: 'post', postId, commentId }
              : { type: 'flow', flowId, responseId, commentId }
          );
        }}
      />
    );
  }

  return null;
}
