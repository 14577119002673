import {
  type SecondaryFiltersType,
  useDebounce,
  useUserDetails,
} from '@assembly-web/services';
import {
  GlobalFilterOption,
  type SelectableOptionProps,
} from '@assembly-web/ui';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getIncludedEntityIds } from '../../services/secondaryFilters';
import { useGetSecondaryFilterOptionsQuery } from '../useGetSecondaryFilterOptionsQuery';
import { useGlobalFilter } from '../useGlobalFilter';
import { useSecondaryFilterState } from './useSecondaryFilterState';

export function useGetPostsSecondaryFilters({
  secondaryFilterTypes,
  postContentTypeOptions,
}: {
  secondaryFilterTypes: Record<string, SecondaryFiltersType>;
  postContentTypeOptions: SelectableOptionProps[];
}) {
  const { data: userDetails } = useUserDetails();

  const currentUserId = userDetails?.member.memberId ?? '';

  const [params] = useSearchParams();

  const filter = useGlobalFilter();

  const isPostFilterSelected =
    filter === GlobalFilterOption.All ||
    filter === GlobalFilterOption.Recognition;

  // Mentioned Filter
  const selectedMentionedPeople = params.get('mentionedBy')?.split(',') ?? [];

  const {
    searchValues: { mentionedBySearchValue },
  } = useSecondaryFilterState();

  const debouncedMentionedBySearchValue = useDebounce(
    mentionedBySearchValue,
    500
  );

  const {
    isPending: isMentionedPeopleLoading,
    hasNextPage: hasMoreMentionedPeople,
    fetchNextPage: fetchMoreMentionedPeople,
    data: mentionedByPeopleData,
    isFetchingNextPage: isFetchingMoreMentionedPeople,
  } = useGetSecondaryFilterOptionsQuery({
    topLevelFilter: 'POST',
    secondaryFilter: {
      filter: secondaryFilterTypes['mentionedBy'],
      includeEntityIds: getIncludedEntityIds({
        selectedItems: selectedMentionedPeople,
        currentUserId,
      }),
    },
    enabled: isPostFilterSelected,
    searchTerm: mentionedBySearchValue ? debouncedMentionedBySearchValue : '',
  });

  const mentionedByPeopleOptions: SelectableOptionProps[] = useMemo(() => {
    if (!mentionedByPeopleData) return [];
    return mentionedByPeopleData.pages.reduce<SelectableOptionProps[]>(
      (acc, page) => {
        return [
          ...acc,
          ...page.MENTIONED.data.map((item) => {
            return {
              id: item.type === 'member' ? item._meta.entityId : '',
              value:
                item.type === 'member'
                  ? item._meta.entityId === currentUserId
                    ? `${item._meta.fullName} (You)`
                    : item._meta.fullName
                  : '',
              imageUrl:
                item.type === 'member' ? item._meta.profileImageUrl : '',
              state: item.type === 'member' ? item.state : 'ACTIVE',
            };
          }),
        ];
      },
      []
    );
  }, [currentUserId, mentionedByPeopleData]);

  const mentionedBySelectedOptions = mentionedByPeopleOptions.filter((item) =>
    selectedMentionedPeople.includes(item.id)
  );

  // Post Content Type Filter
  const selectedContentType = params.get('postContentType')?.split(',') ?? [];

  const postContentTypeSelectedOptions = postContentTypeOptions.filter((item) =>
    selectedContentType.includes(item.id)
  );

  const mentionedByProps = {
    isMentionedPeopleLoading,
    hasMoreMentionedPeople,
    fetchMoreMentionedPeople,
    isFetchingMoreMentionedPeople,
    mentionedByPeopleOptions,
    mentionedBySelectedOptions,
    mentionedBySearchValue: mentionedBySearchValue,
    hasSelectedMentionedPeople: selectedMentionedPeople.length > 0,
  };

  const postContentTypeProps = {
    postContentTypeOptions,
    hasSelectedContentType: selectedContentType.length > 0,
    postContentTypeSelectedOptions,
  };

  return { mentionedByProps, postContentTypeProps };
}
