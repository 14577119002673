import {
  useSuspenseUserDetails,
  withoutDefaultEventBehavior,
} from '@assembly-web/services';
import { IconButton, Tooltip, useDeviceInfo } from '@assembly-web/ui';
import { PlusIcon } from '@heroicons/react/24/outline';
import { defineMessages, useIntl } from 'react-intl';
import { useShallow } from 'zustand/react/shallow';

import { useFlowNavItems } from '../../hooks/flows/useFlowNavItems';
import { useFlowsNotInCollection } from '../../hooks/useFlowsNotInCollection';
import { useCreateWorkflow } from '../../modules/discover/hooks/flowsEditor/useCreateWorkflow';
import {
  trackFlowEditorAction,
  trackNavAction,
} from '../../modules/discover/services/analytics';
import { useNavBarStore } from '../../stores/useNavBarStore';
import { FlowNavItem } from './FlowNavItem';
import { NavCollapsibleSection } from './NavCollapsibleSection';
import { NavItem } from './NavItem';
import { NavSectionEmptyState } from './NavSectionEmptyState';

const messages = defineMessages({
  flows: {
    defaultMessage: 'MY FLOWS',
    id: 'DlUEh3',
  },
  createFlow: {
    defaultMessage: 'Create a flow',
    id: 'Nmjp0t',
  },
  emptySectionText: {
    defaultMessage: `Nothing to show here yet! You may not have any flows or all your flows may already be in your folders. {canCreateFlow, select, true {Click the + to create a new flow.} other {}}`,
    id: '+nKs9W',
  },
});

export function FlowsNavSectionSkeleton() {
  const { formatMessage } = useIntl();

  const isFlowsSectionOpen = useNavBarStore(
    useShallow((state) => state.sectionOpenState.flows)
  );

  const toggleNavSection = useNavBarStore(
    useShallow((state) => state.toggleSection)
  );
  return (
    <NavCollapsibleSection
      title={formatMessage(messages.flows)}
      isSectionOpen={isFlowsSectionOpen}
      onSectionToggle={() => toggleNavSection('flows')}
      menu={
        <IconButton variation="secondaryEmphasized" size="xSmall" disabled>
          <div className="h-4 w-4 animate-pulse rounded bg-gradient-to-r from-gray-3 to-gray-5" />
        </IconButton>
      }
    >
      <NavItem.Skeleton />
      <NavItem.Skeleton />
      <NavItem.Skeleton />
      <NavItem.Skeleton />
    </NavCollapsibleSection>
  );
}

export function FlowsNavSection() {
  const { formatMessage } = useIntl();

  const { data: userDetails } = useSuspenseUserDetails();
  const { isMobileDevice } = useDeviceInfo();

  const canCreateFlow =
    userDetails.member.permissions.canCreateFlow && !isMobileDevice;

  const createFlow = useCreateWorkflow();

  const { data: flowsToView } = useFlowsNotInCollection();

  const getFlowNavItems = useFlowNavItems();

  const flows = getFlowNavItems(flowsToView);

  const isFlowsSectionOpen = useNavBarStore(
    useShallow((state) => state.sectionOpenState.flows)
  );

  const toggleNavSection = useNavBarStore(
    useShallow((state) => state.toggleSection)
  );

  return (
    <NavCollapsibleSection
      variant="flows"
      title={formatMessage(messages.flows)}
      isSectionOpen={isFlowsSectionOpen}
      onSectionToggle={() => toggleNavSection('flows')}
      menu={
        Boolean(canCreateFlow) && (
          <IconButton
            variation="secondaryEmphasized"
            size="xSmall"
            onClick={withoutDefaultEventBehavior(() => {
              createFlow();
              trackNavAction('createFlowClicked');
              trackFlowEditorAction('createFlowNavClicked');
            })}
          >
            <Tooltip tooltipText={formatMessage(messages.createFlow)}>
              <PlusIcon className="h-4 w-4" />
            </Tooltip>
          </IconButton>
        )
      }
    >
      {flows.length === 0 ? (
        <NavSectionEmptyState
          text={formatMessage(messages.emptySectionText, { canCreateFlow })}
        />
      ) : (
        flows.map((flow) => <FlowNavItem key={flow.id} flow={flow} />)
      )}
    </NavCollapsibleSection>
  );
}
