import { SplitNames, useFeatureSplit } from '@assembly-web/services';

export const NewUxOverrideKey = 'newUX';

export function useIsNewUX() {
  const { isTreatmentActive: isNewUX } = useFeatureSplit(SplitNames.NewUX);

  let newUXOverride;

  try {
    newUXOverride = localStorage.getItem(NewUxOverrideKey);
  } catch {
    // ignore
  }

  return isNewUX && Boolean(newUXOverride);
}
