import type { ContentTypeFilter } from '@assembly-web/services';
import { GlobalFilterOption } from '@assembly-web/ui';
import { useSearchParams } from 'react-router-dom';

import { secondaryFiltersStore } from '../../stores/secondaryFiltersStore';
import { useGlobalFilter } from '../useGlobalFilter';

export function useGetSelectedSecondaryFilters() {
  const [searchParams] = useSearchParams();
  const { excludedPostedByMembers } = secondaryFiltersStore();

  //Param Values of Secondary Filters
  const filter = useGlobalFilter();
  const flows = searchParams.get('flows') ?? '';
  const postedBy = searchParams.get('postedBy') ?? '';
  const mentionedBy = searchParams.get('mentionedBy') ?? '';
  const postContentType = searchParams.get('postContentType') ?? '';
  const flowStatusType = searchParams.get('flowStatusType') ?? '';
  const collections = searchParams.get('collections') ?? '';
  const apps = searchParams.get('apps') ?? '';
  const assignees = searchParams.get('assignee') ?? '';
  const taskStatus = searchParams.get('taskStatus') ?? '';
  const fileTypes = searchParams.get('fileType') ?? '';
  const departmentsValue = searchParams.get('department') ?? '';
  const homeLocationValue = searchParams.get('homeLocation') ?? '';
  const workLocationValue = searchParams.get('workLocation') ?? '';
  const reportsToValue = searchParams.get('reportsTo') ?? '';
  const jobTitleValue = searchParams.get('jobTitle') ?? '';
  const dueDateValue = searchParams.get('dueDate') ?? '';
  const dueDateStartValue = searchParams.get('dueDateStart') ?? '';
  const dueDateEndValue = searchParams.get('dueDateEnd') ?? '';
  const dateCreatedValue = searchParams.get('dateCreated') ?? '';
  const dateCreatedEndValue = searchParams.get('dateCreatedEnd') ?? '';
  const dateCreatedStartValue = searchParams.get('dateCreatedStart') ?? '';
  const challengeStatusType = searchParams.get('challengeStatusType') ?? '';

  const hasDateCreatedFilter = Boolean(
    dateCreatedValue.length ||
      dateCreatedEndValue.length ||
      dateCreatedStartValue.length
  );

  const hasDueDateFilter = Boolean(
    dueDateValue.length || dueDateEndValue.length || dueDateStartValue.length
  );

  const isAllFilterSecondaryFilterSelected =
    (filter === GlobalFilterOption.All &&
      Boolean(
        flows.length || apps.length || collections.length || postedBy.length
      )) ||
    hasDateCreatedFilter;

  const isCollectionsFilterSecondaryFilterSelected =
    (filter === GlobalFilterOption.Collections && Boolean(postedBy.length)) ||
    hasDateCreatedFilter;

  const isFlowsFilterSecondaryFilterSelected =
    filter === GlobalFilterOption.Flows &&
    Boolean(flowStatusType.length || postedBy.length || hasDateCreatedFilter);

  const isFileSecondaryFilterSelected =
    filter === GlobalFilterOption.Files &&
    Boolean(
      flows.length ||
        apps.length ||
        collections.length ||
        fileTypes.length ||
        postedBy.length ||
        hasDateCreatedFilter
    );

  const isTaskSecondaryFilterSelected =
    filter === GlobalFilterOption.Tasks &&
    Boolean(
      postedBy.length ||
        taskStatus.length ||
        assignees.length ||
        hasDateCreatedFilter ||
        hasDueDateFilter
    );

  const isPeopleSecondaryFilterSelected =
    filter === GlobalFilterOption.People &&
    Boolean(
      departmentsValue.length ||
        jobTitleValue.length ||
        workLocationValue.length ||
        homeLocationValue.length ||
        reportsToValue.length
    );

  const isChallengeSecondaryFilterSelected =
    filter === GlobalFilterOption.Challenges &&
    Boolean(challengeStatusType.length);

  const isRecognitionSecondaryFilterSelected =
    filter === GlobalFilterOption.Recognition &&
    (Boolean(postContentType.length) ||
      Boolean(mentionedBy.length) ||
      Boolean(postedBy.length) ||
      hasDueDateFilter);

  const isSecondaryFiltersApplied =
    isAllFilterSecondaryFilterSelected ||
    isCollectionsFilterSecondaryFilterSelected ||
    isFlowsFilterSecondaryFilterSelected ||
    isFileSecondaryFilterSelected ||
    isTaskSecondaryFilterSelected ||
    isPeopleSecondaryFilterSelected ||
    isChallengeSecondaryFilterSelected ||
    isRecognitionSecondaryFilterSelected;

  const contentType: ContentTypeFilter = postContentType as ContentTypeFilter;

  return {
    secondaryFilterParams: {
      flows,
      apps,
      collections,
      postedBy,
      dateCreatedValue,
      filter,
      mentionedBy,
      flowStatusType,
      assignees,
      taskStatus,
      fileTypes,
      departmentsValue,
      homeLocationValue,
      workLocationValue,
      reportsToValue,
      jobTitleValue,
      postContentType: contentType,
      dueDateValue,
      dateCreatedStartValue,
      dateCreatedEndValue,
      dueDateStartValue,
      dueDateEndValue,
      excludedPostedByMembers,
      challengeStatusType,
    },
    isSecondaryFiltersApplied,
  };
}
