import { isTruthy, withoutDefaultEventBehavior } from '@assembly-web/services';
import { IconButton, Tooltip } from '@assembly-web/ui';
import { EyeSlashIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useShallow } from 'zustand/react/shallow';

import { useHiddenFolders } from '../../../hooks/folder/useHiddenFolders';
import { CollectionModalsContext } from '../../../modules/discover/contexts/CollectionModalsContext';
import { useNavData } from '../../../modules/discover/hooks/nav/useNavData';
import { useNavBarStore } from '../../../stores/useNavBarStore';
import { NavCollapsibleSection } from '../NavCollapsibleSection';
import { NavSectionEmptyState } from '../NavSectionEmptyState';
import { FolderSection, FolderSectionSkeleton } from './FolderSection';

const messages = defineMessages({
  folders: {
    defaultMessage: 'MY FOLDERS',
    id: 'NsugKx',
  },
  createFolder: {
    defaultMessage: 'Create a folder',
    id: 'rWG5IW',
  },
  hiddenFolders: {
    defaultMessage: 'Hidden Folders',
    id: 'E3eQft',
  },
  emptyText: {
    defaultMessage: `You haven’t created any Folders yet. Create a Folder with the + button.`,
    id: '5W7Snn',
  },
});

export function FoldersNavSectionSkeleton() {
  const { formatMessage } = useIntl();

  const isFoldersSectionOpen = useNavBarStore(
    useShallow((state) => state.sectionOpenState.folders)
  );

  const toggleNavSection = useNavBarStore(
    useShallow((state) => state.toggleSection)
  );
  return (
    <NavCollapsibleSection
      title={formatMessage(messages.folders)}
      isSectionOpen={isFoldersSectionOpen}
      onSectionToggle={() => toggleNavSection('folders')}
      menu={
        <IconButton variation="secondaryEmphasized" size="xSmall" disabled>
          <div className="h-4 w-4 animate-pulse rounded bg-gradient-to-r from-gray-3 to-gray-5" />
        </IconButton>
      }
    >
      <div className="flex w-full flex-col gap-2 space-y-[-1px]">
        {Array.from({ length: 2 }).map((_, index) => (
          <FolderSectionSkeleton key={index} />
        ))}
      </div>
    </NavCollapsibleSection>
  );
}

export function FoldersNavSection() {
  const { formatMessage } = useIntl();

  const isFoldersSectionOpen = useNavBarStore(
    useShallow((state) => state.sectionOpenState.folders)
  );
  const isHiddenFoldersSectionOpen = useNavBarStore(
    useShallow((state) => state.sectionOpenState.hiddenFolders)
  );

  const toggleNavSection = useNavBarStore(
    useShallow((state) => state.toggleSection)
  );

  const { openCreateCollectionModal } = useContext(CollectionModalsContext);

  const { pinnedCollections: folders } = useNavData();

  const { hiddenFolders } = useHiddenFolders();

  return (
    <NavCollapsibleSection
      title={formatMessage(messages.folders)}
      isSectionOpen={isFoldersSectionOpen}
      onSectionToggle={() => toggleNavSection('folders')}
      menu={
        <IconButton
          variation="secondaryEmphasized"
          size="xSmall"
          onClick={withoutDefaultEventBehavior(() => {
            openCreateCollectionModal();
          })}
        >
          <Tooltip tooltipText={formatMessage(messages.createFolder)}>
            <PlusIcon className="h-4 w-4" />
          </Tooltip>
        </IconButton>
      }
    >
      {folders.length === 0 && hiddenFolders.length === 0 ? (
        <NavSectionEmptyState text={formatMessage(messages.emptyText)} />
      ) : (
        <div className="flex w-full flex-col gap-2 space-y-[-1px]">
          {folders.map((folder) => (
            <FolderSection key={folder.id} folder={folder} />
          ))}

          {isTruthy(hiddenFolders) && hiddenFolders.length > 0 && (
            <NavCollapsibleSection
              title={
                <div className="flex items-center gap-1">
                  <EyeSlashIcon className="h-4 w-4" />
                  {formatMessage(messages.hiddenFolders)}
                </div>
              }
              triggerClassName="pl-2"
              isSectionOpen={isHiddenFoldersSectionOpen}
              onSectionToggle={() => toggleNavSection('hiddenFolders')}
              variant="hiddenFolder"
              isSubSection
            >
              {hiddenFolders.map((folder) => (
                <FolderSection key={folder.id} folder={folder} isHiddenFolder />
              ))}
            </NavCollapsibleSection>
          )}
        </div>
      )}
    </NavCollapsibleSection>
  );
}
