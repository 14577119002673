import { config, useSuspenseUserDetails } from '@assembly-web/services';
import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  name: {
    defaultMessage: 'Give Recognition',
    id: 'CPVG7R',
  },
  description: {
    defaultMessage: 'Recognize someone for doing something great!',
    id: 'g8V+PD',
  },
});

export function useGiveRecognitionFlowDetails(redirectUrl = '/a/discover') {
  const { formatMessage } = useIntl();
  const { data: userDetails } = useSuspenseUserDetails();

  const workspaceSlugPath = userDetails.assembly.workspaceSlugPath;

  const id = 'recognition';

  const feedLink = `/a/${workspaceSlugPath}/flows/${id}`;

  const link = `${feedLink}/answer?redirectUrl=${config.domains.app}${redirectUrl}`;

  return useMemo(
    () => ({
      data: {
        link,
        feedLink,
        flowDetails: {
          id,
          name: formatMessage(messages.name),
          description: formatMessage(messages.description),
          icon: {
            kind: 'HEX_CODE',
            value: '1F389',
          },
        } as const,
      },
    }),
    [feedLink, formatMessage, link]
  );
}
