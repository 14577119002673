import type {
  MemberDetails,
  MemberState,
  ReactionDetails,
  UserDetails,
} from '@assembly-web/services';
import { getMemberDetailsFromUserDetails } from '@assembly-web/services';
import {
  getFormattedMessage,
  PostCardActions,
  ReplyCard,
  type ToolbarItem,
  useAssemblyNavigate,
} from '@assembly-web/ui';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';
import { useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useNavigateToUserFeed } from '../../../../hooks/useNavigateToUserFeed';
import { usePosts } from '../../../../hooks/usePosts';
import { useCommentReactionMutation } from '../../../../hooks/useReactionMutation';
import { useReplies } from '../../../../hooks/useReplies';
import { getURLForAssemblyPostActions } from '../../../../services/post';
import type { PostData } from '../../../../types/postsAndReplies';
import { ModalsContext } from '../../contexts/ModalsContext';
import { type CardAction, trackCardAction } from '../../services/analytics';
import type { CommonCardProps } from './type';
import {
  getToolbarMenuItemsForReplyCard,
  ReplyToolBarAction,
  ToolbarItemId,
} from './utils';

const msgs = defineMessages({
  text: {
    defaultMessage:
      'Reply by {author, select, self {you} other {{author}}} on {post} in {flow}',
    id: 'RiL5JV',
  },
  subText: {
    defaultMessage: '{author}: {content}',
    id: '/1iwkO',
  },
  viewThread: {
    defaultMessage: 'View thread',
    id: 'akrSOb',
  },
});

type CommentCardProps = {
  content: string;
  collectionTitle?: string;
  taggedUsers: MemberDetails[];
  commentId: string;
  author: string;
  authorId: string;
  flow: string;
  onClick?: () => void;
  gifURL?: string;
  postId: string;
  flowId: string;
  responseId: string;
  urlSlug: string;
  commentOwner: MemberDetails | undefined;
  postOwner: {
    firstName?: string;
    lastName?: string;
    profileStatus: MemberState;
    entityId: string;
    profileImageUrl?: string;
    fullName: string;
    type?: string;
    pronouns?: string;
    email?: string;
    department?: string;
    jobTitle?: string;
  };
  kind: string;
  isEdited?: boolean;
  createdAt: Date;
  userDetails: UserDetails;
  reactions?: ReactionDetails[];
  pointsEach: number;
  postCreatedAt: string;
  editReplySettings: {
    isV3Reply: boolean;
    isAnonymousReplyEditable: boolean;
  };
  boostedUsers?: { member: MemberDetails; points: number }[];
} & CommonCardProps;

export function CommentCard({
  flow,
  cardId,
  commentId,
  content,
  isAdmin,
  isCollectionItem,
  isFirstItem,
  isLastItem,
  canEditCollection,
  gifURL,
  filter,
  currentUserId,
  onToolbarMenuItemClick,
  postId,
  flowId,
  responseId,
  workspaceSlugPath,
  urlSlug,
  showPostInteractionSettings,
  commentOwner,
  postOwner,
  hideReplies,
  hideReactions,
  kind,
  createdAt,
  userDetails,
  reactions = [],
  pointsEach,
  postCreatedAt,
  editReplySettings,
  isEdited,
  taggedUsers,
  collectionTitle,
  currencyDetails,
  boostedUsers,
  isNewUX,
}: CommentCardProps) {
  const navigate = useAssemblyNavigate();
  const { navigate: navigateToUserFeed } = useNavigateToUserFeed();
  const { formatMessage } = useIntl();

  let messageNode;

  const urlPath = `/a/${workspaceSlugPath}${urlSlug}`;
  const { onDeleteReplyOrPostClick } = useContext(ModalsContext);

  const { onReplyClicked } = useReplies();
  const { onPostClicked } = usePosts();

  const trackAction = (action: CardAction) => {
    trackCardAction(action, {
      cardType: 'comment',
      filterType: filter,
      flowId,
      flowName: flow,
      isNotification: false,
      isAnonymousReply: !commentOwner?.memberID,
    });
  };

  const handleMenuItem = (args: ToolbarItem) => {
    trackAction(ReplyToolBarAction[args.id]);
    if (ToolbarItemId.EditReply === args.id) {
      onViewRepliesClick(commentId);
    } else if (ToolbarItemId.DeleteReply === args.id) {
      onDeleteReplyOrPostClick({
        replyId: commentId,
        flowId: flowId,
        responseId: responseId,
      });
    } else {
      onToolbarMenuItemClick?.({
        ...args,
        url: urlPath,
        cardId,
        commentId,
        cardIcon: (
          <ChatBubbleBottomCenterTextIcon className="h-4 w-4 stroke-2 max-md:max-w-none" />
        ),
        title: collectionTitle?.length ? collectionTitle : content,
        type: 'comment',
        entityId: commentId,
        postId,
        flowId,
        plainFlow: flow,
        author: postOwner.fullName,
        responseId,
        allowReplies: !hideReplies,
        allowReactions: !hideReactions,
      });
    }
  };

  const redirectToV2 = (action?: PostCardActions, id?: string) => {
    const redirectionURL = getURLForAssemblyPostActions({
      action: action ?? PostCardActions.RepliesBarClicked,
      slug: workspaceSlugPath,
      flowId,
      id,
      responseId,
    });
    if (redirectionURL) navigate(redirectionURL);
  };

  const onViewRepliesClick = (editSelectedCommentId?: string) => {
    const viewReplyPayload: PostData =
      kind === 'recognition'
        ? {
            type: 'post',
            postId: postId,
            commentId,
            editSelectedCommentId,
          }
        : {
            type: 'flow',
            flowId: flowId,
            responseId: responseId,
            commentId,
            editSelectedCommentId,
          };
    onReplyClicked(viewReplyPayload);
  };

  const onViewPostClick = () => {
    const openPostPayload: PostData =
      kind === 'recognition'
        ? { type: 'post', postId }
        : {
            type: 'flow',
            flowId,
            responseId,
          };
    onPostClicked(openPostPayload);
  };

  const reactionMutate = useCommentReactionMutation({
    currentUser: getMemberDetailsFromUserDetails(userDetails.member),
  });

  const handleReactionClick = (emoji: ReactionDetails) => {
    const hasCurrentUserReactedToTheSelectedEmoji = reactions.some(
      (reaction) =>
        reaction.name === emoji.name &&
        reaction.members.some((member) => member.memberID === currentUserId)
    );
    reactionMutate({
      payload: {
        displayName: emoji.displayName,
        name: emoji.name,
        type: emoji.type,
        value: emoji.type,
      },
      action: hasCurrentUserReactedToTheSelectedEmoji ? 'unset' : 'set',
      commentId,
      flowId: flowId,
      responseId: responseId,
    });
  };

  if (taggedUsers.length && !editReplySettings.isV3Reply) {
    messageNode = getFormattedMessage(
      {
        mentions: taggedUsers.map((user) => {
          return {
            ...user,
            memberId: user.memberID,
          };
        }),
        value: content,
      },
      () => {},
      {}
    );
  }

  return (
    commentOwner && (
      <div data-testid={`comment-card-${cardId}`}>
        <ReplyCard
          onMemberClick={navigateToUserFeed}
          boostedUsers={boostedUsers}
          taggedUsers={taggedUsers}
          buttonText={formatMessage(msgs.viewThread)}
          gifURL={gifURL}
          content={content}
          messageNode={messageNode}
          handleMenuItem={handleMenuItem}
          conversationMemberDetails={{
            createdAt: createdAt.toISOString(),
            isAnonymous: !commentOwner.memberID,
            ...commentOwner,
          }}
          postCreatedAt={postCreatedAt}
          flow={flow}
          postOwner={postOwner}
          commentId={commentId}
          handleReactionClick={handleReactionClick}
          reactions={reactions}
          currentUserId={currentUserId ?? ''}
          isEdited={isEdited}
          pointsEach={{
            icon: userDetails.assembly.currency,
            points: pointsEach,
          }}
          toolbarMenuItems={[
            ...getToolbarMenuItemsForReplyCard(
              {
                formatMessage,
                canEditCollection,
                canEditReply:
                  (currentUserId === commentOwner.memberID ||
                    editReplySettings.isAnonymousReplyEditable) &&
                  editReplySettings.isV3Reply,
                isAdmin,
                isAuthor: currentUserId === commentOwner.memberID,
                isCollectionItem,
                isFirstItem,
                isLastItem,
                showPostInteractionSettings,
              },
              isNewUX
            ),
          ]}
          onCardClicked={() => {
            trackAction('cardClicked');
            onViewRepliesClick();
          }}
          onViewThreadClicked={() => {
            trackAction('viewThreadClicked');
            onViewRepliesClick();
          }}
          onSeePostClicked={() => {
            trackAction('seePostClicked');
            onViewPostClick();
          }}
          handlePostOwnerClick={() => {
            trackAction('profileNameClicked');
            redirectToV2(PostCardActions.PersonClicked, postOwner.entityId);
          }}
          handleViewRepliesClick={() => {
            trackAction('replyButtonClicked');
            onViewRepliesClick();
          }}
          handleFlowClick={() => {
            trackAction('flowClicked');
            redirectToV2(PostCardActions.FlowNameClicked);
          }}
          currencyDetails={currencyDetails}
        />
      </div>
    )
  );
}
