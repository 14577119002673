import type {
  EntityPermissionApiResponse,
  FlowPostResponse,
  UserFeedApiResponse,
} from '@assembly-web/services';
import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import type { InfiniteData } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';

import type { SearchIndexApiResponse } from './useSearchIndex';

type SaveAnnouncementPayload = {
  title: string;
  color: string;
  type: 'RESPONSE';
  description: string;
  announcementId?: string;
  entity: {
    id: string;
    type: 'RESPONSE';
    flowId: string | null;
  };
  responseSettings: {
    hideReplies: boolean;
    hideReactions: boolean;
  };
  notificationSettings: {
    email: boolean;
    mobile: boolean;
  };
};

let data: SaveAnnouncementPayload | null = null;

export function useSaveAnnouncementsMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: SaveAnnouncementPayload) => {
      data = payload;
      return await assemblyAPI.post(APIEndpoints.saveAnnouncement, payload);
    },

    onSuccess: async (response) => {
      const queryCache = queryClient.getQueryCache();

      if (data?.entity.flowId && data.entity.id) {
        const queryKey = [
          'assemblyFlowPost',
          data.entity.flowId,
          data.entity.id,
        ];
        const postDetails =
          queryClient.getQueryData<FlowPostResponse>(queryKey);

        const updatedPostDetails = produce(postDetails, (draft) => {
          if (draft && data) {
            draft.activeAnnouncement = {
              ...data,
              viewsCount: 0,
              showInsights: true,
              announcementId: response.data.announcementId ?? '',
              notificationSettings: {
                push: data.notificationSettings.mobile,
                email: data.notificationSettings.email,
              },
            };
          }
        });

        queryClient.setQueryData(queryKey, updatedPostDetails);
      }

      queryCache.getAll().map((query) => {
        const queryKey = query.queryKey;
        if (Array.isArray(queryKey) && queryKey[0] === 'userFeed') {
          const userFeedCacheKey = queryKey;
          const previousUserFeed =
            queryClient.getQueryData<InfiniteData<UserFeedApiResponse>>(
              userFeedCacheKey
            );

          const updatedCards = produce(previousUserFeed, (draft) => {
            draft?.pages.forEach((page) => {
              page.data = page.data.map((card) => {
                if (card.type === 'response') {
                  if (card.id === data?.entity.id && card.cardDetails) {
                    card.cardDetails.activeAnnouncement = {
                      ...data,
                      viewsCount: 0,
                      showInsights: true,
                      announcementId: data.announcementId ?? '',
                      notificationSettings: {
                        push: data.notificationSettings.mobile,
                        email: data.notificationSettings.email,
                      },
                    };
                  }
                }
                return card;
              });
            });
          });

          queryClient.setQueryData(userFeedCacheKey, updatedCards);
        }

        if (Array.isArray(queryKey) && queryKey[0] === 'searchResults') {
          const searchResultsCacheKey = queryKey;
          const previousSearchResults = queryClient.getQueryData<
            InfiniteData<SearchIndexApiResponse>
          >(searchResultsCacheKey);

          const updatedCards = produce(previousSearchResults, (draft) => {
            draft?.pages.forEach((page) => {
              page.data.data = page.data.data.map((card) => {
                if (card.type === 'response') {
                  if (card.id === data?.entity.id && card.cardDetails) {
                    card.cardDetails.activeAnnouncement = {
                      ...data,
                      viewsCount: 0,
                      showInsights: true,
                      announcementId: data.announcementId ?? '',
                      notificationSettings: {
                        push: data.notificationSettings.mobile,
                        email: data.notificationSettings.email,
                      },
                    };
                  }
                }
                return card;
              });
            });
          });

          queryClient.setQueryData(searchResultsCacheKey, updatedCards);
        }

        if (Array.isArray(queryKey) && queryKey[0] === 'entityPermission') {
          const permissionsCacheKey = queryKey;

          const permissionResults =
            queryClient.getQueryData<EntityPermissionApiResponse>(
              permissionsCacheKey
            );

          const updatedCards = produce(permissionResults, (draft) => {
            draft?.data.forEach((card) => {
              if (card.entityId === data?.entity.id) {
                card.canShareAsAnnouncement = false;
                card.canEditAnnouncement = true;
                card.canEndAnnouncement = true;
              }
            });
          });

          queryClient.setQueryData(permissionsCacheKey, updatedCards);
        }
      });
    },
  });
}
