import type { ItemToAdd } from '@assembly-web/services';
import type { GlobalFilterOption } from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';

import { useGetCollectionsQuery } from '../../hooks/useGetCollectionsQuery';
import { useIsNewUX } from '../../hooks/useIsNewUX';
import type { SearchPayload } from '../../hooks/useSearchIndex';
import { BaseCollectionModal } from './BaseCollectionModal';

const messages = defineMessages({
  header: {
    defaultMessage: 'Add to a collection',
    id: 'gYu4QI',
  },
  folderHeader: {
    defaultMessage: 'Add to a folder',
    id: 'e5R2/b',
  },
  pinHeader: {
    defaultMessage: 'Add item and pin Collection',
    id: 'zC56JI',
  },
  content: {
    defaultMessage: 'Add to an existing collection or create a new one',
    id: '+RnRsS',
  },
  folderSubTitle: {
    defaultMessage: 'Add to an existing folder or create a new one',
    id: 'Tt96fb',
  },
  chooseCollection: {
    defaultMessage: 'Choose an existing collection',
    id: 'XczYAd',
  },
  chooseFolder: {
    defaultMessage: 'Choose an existing folder',
    id: '3Dl10l',
  },
  addToCollection: {
    defaultMessage: 'Add to collection',
    id: 'ub1kHa',
  },
  adding: {
    defaultMessage: 'Adding to collection',
    id: 'VK+1HH',
  },
  addToFolder: {
    defaultMessage: 'Add to folder',
    id: 'O3N3Cb',
  },
  addingToFolder: {
    defaultMessage: 'Adding to folder',
    id: 'FhuXmN',
  },
});

type AddToCollectionModalProps = {
  filter: GlobalFilterOption;
  itemToAdd: ItemToAdd | null;
  onClose: () => void;
  onCreateCollection: () => void;
  open: boolean;
  pinCollection: boolean;
  searchTerm: string;
  query?: SearchPayload;
};

export function AddToCollectionModal(props: AddToCollectionModalProps) {
  const {
    pinCollection,
    onCreateCollection,
    onClose,
    open,
    itemToAdd,
    filter,
    searchTerm,
    query,
  } = props;
  const { formatMessage } = useIntl();

  const isNewUX = useIsNewUX();

  const { data: collections } = useGetCollectionsQuery({ enabled: open });

  return (
    <BaseCollectionModal
      onCreateCollection={onCreateCollection}
      onClose={onClose}
      open={open}
      title={formatMessage(
        pinCollection
          ? messages.pinHeader
          : isNewUX
            ? messages.folderHeader
            : messages.header
      )}
      bodyText={formatMessage(
        isNewUX ? messages.folderSubTitle : messages.content
      )}
      collectionLabel={formatMessage(
        isNewUX ? messages.chooseFolder : messages.chooseCollection
      )}
      ctaText={formatMessage(
        isNewUX ? messages.addToFolder : messages.addToCollection
      )}
      ctaLoadingText={formatMessage(
        isNewUX ? messages.addingToFolder : messages.adding
      )}
      filter={filter}
      itemToAdd={itemToAdd}
      searchTerm={searchTerm}
      collections={collections?.data}
      pinCollection={pinCollection}
      query={query}
    />
  );
}
