import type { MemberState } from '@assembly-web/services';
import {
  CriteriaRuleType,
  CustomRuleType,
  isUserAdmin,
  Operator,
  PermissionType,
  useUserDetails,
} from '@assembly-web/services';
import type {
  CriteriaItemProps,
  CustomRule,
  CustomRuleOption,
  CustomRuleWithType,
} from '@assembly-web/ui';
import { useCallback, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import invariant from 'tiny-invariant';
import type { Updater } from 'use-immer';

import { trackSharesheetAction } from '../../services/analytics';
import {
  CriteriaCardIconMap,
  getOwnerAndCollaboratorPermissionOptions,
  getPeopleSelectorPermissionOptions,
  getPermissionOptions as getCollectionPermissionOptions,
} from '../../services/shareCollectionsData';
import {
  ApproverPermission,
  DefaultPermission,
  ParticipantPermission,
} from '../../services/shareSheetData';
import { getPermissionOptions as getChallengesPermissionOptions } from '../shareChallenge/useShareChallengesData';
import { useIsNewUX } from '../useIsNewUX';
import { messages as RuleMessages } from './messages';
import { useShareSheetNonMemberCriteriaOptions } from './useShareSheetNonMemberCriteriaOptions';

export const operatorLabelMap: Record<string, string> = {
  [Operator.Is]: 'Is',
  [Operator.IsNot]: 'IsNot',
};

const messages = defineMessages({
  everyone: {
    defaultMessage: 'Everyone',
    id: '2XDuH4',
  },
  department: {
    defaultMessage: 'Department',
    id: 'eXcD4S',
  },
  workLocation: {
    defaultMessage: 'Work location',
    id: '4mb2dn',
  },
  manager: {
    defaultMessage: 'Manager status',
    id: 'jfRiOS',
  },
  homeLocation: {
    defaultMessage: 'Home location',
    id: 'BdIFnv',
  },
  jobTitle: {
    defaultMessage: 'Job title',
    id: 'HsQ3BK',
  },
  everyoneInThisWorkspace: {
    defaultMessage: 'Everyone in this workspace',
    id: 'zqo7Vo',
  },
  is: {
    defaultMessage: 'is',
    id: 'fXdkiI',
  },
  isNot: {
    defaultMessage: 'is not',
    id: 'alA913',
  },
  isTrue: {
    defaultMessage: 'is true',
    id: '0rzFZS',
  },
  isFalse: {
    defaultMessage: 'is false',
    id: 'thJnXm',
  },
  isTheManagerOfTheParticipant: {
    defaultMessage: 'is the Manager of the Participant',
    id: 'Go/EJf',
  },
  nonMemberCriteriaTooltipWithEveryone: {
    defaultMessage:
      'These role settings will override ‘Everyone in this workspace,’ but will not override individual role settings',
    id: 'sx3r1f',
  },
  memberCriteriaTooltip: {
    defaultMessage:
      'These manual role settings will override any roles configured using rules.',
    id: 'rTuy8M',
  },
  inviteUponSharing: {
    defaultMessage: 'Invite upon sharing',
    id: 'zTWTNM',
  },
  currentUserLabel: {
    defaultMessage: '{currentUser} (You)',
    id: 'UfG6o8',
  },
});

export const getCriteriaId = ({
  field,
  value,
  operator,
}: {
  field: string;
  value: string;
  operator: string;
}) => {
  if (field === CriteriaRuleType.Everyone) {
    return `${field}-${operator}`;
  }

  return `${field}-${operator}-${value}`;
};

export const useShareSheet = ({
  enabled,
  rules,
  setRules,
  type,
  customHandlePermissionChange,
  onEmailInvite,
  onRemoveRule,
}: {
  enabled: boolean;
  rules: CriteriaItemProps[];
  setRules: Updater<CriteriaItemProps[]>;
  type: keyof typeof DefaultPermission;
  customHandlePermissionChange?: (args: {
    currentRule: CriteriaItemProps;
    draftRules: CriteriaItemProps[];
    updatedPermission?: CriteriaItemProps['permission'];
    ruleToUpdate: CriteriaItemProps;
    currentOwner?: {
      memberState: MemberState;
      memberID: string;
    };
  }) => boolean;
  onEmailInvite?: () => void;
  onRemoveRule?: ({ currentRule }: { currentRule: CriteriaItemProps }) => void;
}) => {
  const { formatMessage } = useIntl();
  const isNewUX = useIsNewUX();

  const [selectedCustomRule, setSelectedCustomRule] = useState<{
    rule: CustomRule;
    type: 'searchableDropdown' | 'booleanDropdown';
  } | null>(null);

  const { data: userDetails } = useUserDetails();
  invariant(userDetails);

  const currentUserId = userDetails.member.memberId;

  const { departments, jobTitles, homeLocations, workLocations } =
    useShareSheetNonMemberCriteriaOptions({
      enabled,
    });

  const hasNonMemberCriteriaOtherThanEveryone = useMemo(
    () =>
      rules.some(
        (rule) =>
          rule.metaData?.field !== CriteriaRuleType.Member &&
          rule.metaData?.field !== CriteriaRuleType.Email &&
          rule.metaData?.field !== CriteriaRuleType.Everyone
      ),
    [rules]
  );

  const isEveryoneRuleSelected = useMemo(
    () =>
      rules.some(
        (rule) =>
          rule.id ===
          getCriteriaId({
            value: '',
            operator: Operator.Is,
            field: CriteriaRuleType.Everyone,
          })
      ),
    [rules]
  );

  const customRules = useMemo(() => {
    const rules = [
      {
        rule: {
          id: CustomRuleType.ManagerStatus,
          label: formatMessage(messages.manager),
        },
        type: 'booleanDropdown',
      },
      {
        rule: {
          id: CustomRuleType.Department,
          label: formatMessage(messages.department),
        },
        type: 'searchableDropdown',
      },
      {
        rule: {
          id: CustomRuleType.WorkLocation,
          label: formatMessage(messages.workLocation),
        },
        type: 'searchableDropdown',
      },
      {
        rule: {
          id: CustomRuleType.HomeLocation,
          label: formatMessage(messages.homeLocation),
        },
        type: 'searchableDropdown',
      },
      {
        rule: {
          id: CustomRuleType.JobTitle,
          label: formatMessage(messages.jobTitle),
        },
        type: 'searchableDropdown',
      },
    ] satisfies {
      rule: CustomRule;
      type: 'searchableDropdown' | 'booleanDropdown';
    }[];

    if (!isEveryoneRuleSelected) {
      rules.unshift({
        rule: {
          id: CustomRuleType.Everyone,
          label: formatMessage(messages.everyone),
        },
        type: 'searchableDropdown',
      });
    }

    return rules;
  }, [formatMessage, isEveryoneRuleSelected]);

  const customRuleOptions = useMemo(() => {
    if (!selectedCustomRule) return [];
    switch (selectedCustomRule.rule.id) {
      case CriteriaRuleType.Department:
        return departments;
      case CriteriaRuleType.JobTitle:
        return jobTitles;
      case CriteriaRuleType.HomeLocation:
        return homeLocations;
      case CriteriaRuleType.WorkLocation:
        return workLocations;
      default:
        return [];
    }
  }, [
    departments,
    homeLocations,
    jobTitles,
    selectedCustomRule,
    workLocations,
  ]);

  const onRulesPermissionChange = useCallback(
    (
      currentRule: CriteriaItemProps,
      updatedPermission: CriteriaItemProps['permission'],
      currentOwner?: {
        memberState: MemberState;
        memberID: string;
      }
    ) => {
      setRules((draftRules) => {
        const ruleToUpdate = draftRules.find(
          (rule) => rule.id === currentRule.id
        );
        if (ruleToUpdate) {
          if (updatedPermission?.id === PermissionType.RemoveRule) {
            const isCurrentRuleOnlyNonMemberRule =
              rules.filter(
                (rule) =>
                  rule.id !== currentRule.id &&
                  rule.metaData?.field !== CriteriaRuleType.Member &&
                  rule.metaData?.field !== CriteriaRuleType.Email &&
                  rule.metaData?.field !== CriteriaRuleType.Everyone
              ).length === 0;

            draftRules.map((rule) => {
              if (
                (ruleToUpdate.metaData?.field === CriteriaRuleType.Everyone &&
                  rule.metaData?.field !== CriteriaRuleType.Everyone &&
                  rule.metaData?.field !== CriteriaRuleType.Member &&
                  rule.metaData?.field !== CriteriaRuleType.Email) ||
                rule.permission?.id === PermissionType.Owner ||
                isCurrentRuleOnlyNonMemberRule
              ) {
                rule.tooltipText = undefined;
              }

              return rule;
            });
            draftRules.splice(draftRules.indexOf(ruleToUpdate), 1);

            onRemoveRule?.({ currentRule });
          } else {
            const handled =
              customHandlePermissionChange?.({
                currentRule,
                draftRules,
                updatedPermission,
                ruleToUpdate,
                currentOwner,
              }) ?? false;

            if (!handled) {
              if (ruleToUpdate.metaData) {
                ruleToUpdate.id = getCriteriaId({
                  field: ruleToUpdate.metaData.field,
                  value: ruleToUpdate.metaData.value,
                  operator: ruleToUpdate.metaData.operator,
                });
              }
              ruleToUpdate.permission = updatedPermission;
            }
          }
        }
      });
    },
    [customHandlePermissionChange, onRemoveRule, rules, setRules]
  );

  const getTooltipText = useCallback(
    ({
      hasEveryoneRule,
    }: {
      hasEveryoneRule?: boolean;
      hasNonMemberCriteriaOtherThanEveryone?: boolean;
    }) => {
      if (hasEveryoneRule)
        return formatMessage(messages.nonMemberCriteriaTooltipWithEveryone);

      return undefined;
    },
    [formatMessage]
  );

  const onCustomRuleSelect = useCallback(
    (selectedCustomRule: CustomRuleWithType | null) => {
      if (selectedCustomRule?.rule.id === CustomRuleType.Everyone) {
        const doesEveryoneRuleAlreadyExists = rules.some(
          (rule) =>
            rule.id ===
            getCriteriaId({
              value: '',
              operator: Operator.Is,
              field: CustomRuleType.Everyone,
            })
        );
        if (!doesEveryoneRuleAlreadyExists) {
          setRules((draftRules) => {
            draftRules.map((rule) => {
              if (rule.permission?.id === PermissionType.Owner) {
                rule.tooltipText = undefined;
              } else if (
                rule.metaData?.field === CriteriaRuleType.Member ||
                rule.metaData?.field === CriteriaRuleType.Email
              ) {
                rule.tooltipText = getTooltipText({
                  hasNonMemberCriteriaOtherThanEveryone: true,
                });
              } else if (
                rule.metaData?.field !== CriteriaRuleType.Everyone &&
                !rule.tooltipText
              ) {
                rule.tooltipText = getTooltipText({
                  hasEveryoneRule: true,
                });
              } else {
                return rule;
              }
            });
            draftRules.unshift({
              id: getCriteriaId({
                value: '',
                operator: Operator.Is,
                field: CustomRuleType.Everyone,
              }),
              title: formatMessage(messages.everyoneInThisWorkspace),
              subtitle: undefined,
              customIcon: {
                icon: CriteriaCardIconMap[CustomRuleType.Everyone],
              },
              permission: DefaultPermission[type],
              permissionOptions: (() => {
                if (type === 'peopleSelector') {
                  return getPeopleSelectorPermissionOptions({ formatMessage });
                }
                if (type === 'challenge') {
                  return getChallengesPermissionOptions({
                    type: CriteriaRuleType.Everyone,
                    formatMessage,
                  });
                }
                if (type === 'collection') {
                  return getCollectionPermissionOptions({
                    type: CriteriaRuleType.Everyone,
                    formatMessage,
                    isNewUX,
                  });
                }
                return getOwnerAndCollaboratorPermissionOptions({
                  formatMessage,
                });
              })(),
              metaData: {
                field: CustomRuleType.Everyone,
                operator: Operator.Is,
                value: '',
              },
            });
            sortCurrentUserToTop(draftRules, currentUserId);
          });
        }
      } else {
        setSelectedCustomRule(selectedCustomRule);
      }
    },
    [
      formatMessage,
      getTooltipText,
      rules,
      setRules,
      type,
      currentUserId,
      isNewUX,
    ]
  );

  const onRulesSelectionChange = useCallback(
    (rule: CriteriaItemProps) => {
      const isAlreadySelected = rules.some(
        (selectedRule) =>
          selectedRule.id ===
          getCriteriaId({
            field: CriteriaRuleType.Member,
            operator: rule.metaData?.operator ?? Operator.Is,
            value: rule.avatar?.memberID ?? rule.id,
          })
      );

      if (isAlreadySelected) {
        const element = document.getElementById(
          getCriteriaId({
            field: CriteriaRuleType.Member,
            operator: rule.metaData?.operator ?? Operator.Is,
            value: rule.avatar?.memberID ?? rule.id,
          })
        );
        scrollIntoViewAndAnimateBackground(element);

        return;
      } else {
        const hasNonMemberCriteria = rules.some(
          (rule) =>
            rule.metaData?.field !== CriteriaRuleType.Member &&
            rule.metaData?.field !== CriteriaRuleType.Email
        );

        setRules((draftRules) => {
          draftRules.unshift({
            ...rule,
            id: getCriteriaId({
              field: CriteriaRuleType.Member,
              operator: Operator.Is,
              value: rule.avatar?.memberID ?? rule.id,
            }),
            title:
              rule.id === currentUserId
                ? formatMessage(messages.currentUserLabel, {
                    currentUser: rule.title,
                  })
                : rule.title,
            permission: DefaultPermission[type],
            permissionOptions: (() => {
              if (type === 'challenge') {
                return getChallengesPermissionOptions({
                  type: CriteriaRuleType.Member,
                  formatMessage,
                  isAdmin: rule.role ? isUserAdmin({ role: rule.role }) : false,
                });
              }
              if (type === 'peopleSelector') {
                return getPeopleSelectorPermissionOptions({ formatMessage });
              }
              if (type === 'collection') {
                return getCollectionPermissionOptions({
                  type: CriteriaRuleType.Member,
                  formatMessage,
                  isNewUX,
                });
              }
              return getOwnerAndCollaboratorPermissionOptions({
                formatMessage,
              });
            })(),
            metaData: {
              field: CriteriaRuleType.Member,
              operator: Operator.Is,
              value: rule.avatar?.memberID ?? rule.id,
              name: rule.title,
              email: rule.subtitle ?? '',
            },
            tooltipText: getTooltipText({
              hasNonMemberCriteriaOtherThanEveryone: hasNonMemberCriteria,
            }),
          });
          sortCurrentUserToTop(draftRules, currentUserId);
        });
      }
    },
    [
      currentUserId,
      formatMessage,
      getTooltipText,
      isNewUX,
      rules,
      setRules,
      type,
    ]
  );

  const onCustomRulesAction = useCallback(
    ({
      selectedRules,
      ruleType,
      condition,
    }: {
      selectedRules?: CustomRuleOption[];
      ruleType: CustomRule;
      condition: string;
    }) => {
      const getId = () => {
        if (ruleType.id === CustomRuleType.ManagerStatus) {
          return getCriteriaId({
            field: CustomRuleType.ManagerStatus,
            operator:
              condition === 'isManager' ? Operator.IsManager : Operator.Is,
            value:
              condition === 'is' || condition === 'isManager'
                ? 'true'
                : 'false',
          });
        } else {
          return getCriteriaId({
            field: ruleType.id,
            operator:
              condition === Operator.IsNot ? Operator.IsNot : Operator.Is,
            value: selectedRules?.[0]?.id ?? '',
          });
        }
      };
      const isAlreadySelected = rules.some((rule) => {
        return rule.id === getId();
      });

      if (isAlreadySelected) {
        const element = document.getElementById(getId());
        scrollIntoViewAndAnimateBackground(element);

        return;
      }
      if (ruleType.id === CustomRuleType.ManagerStatus) {
        const doesCurrentManagerStatusRuleExist = rules.some((rule) => {
          const newRule = getCriteriaId({
            value:
              condition === 'is' || condition === 'isManager'
                ? 'true'
                : 'false',
            operator:
              condition === 'isManager' ? Operator.IsManager : Operator.Is,
            field: CustomRuleType.ManagerStatus,
          });
          return rule.id === newRule;
        });
        if (!doesCurrentManagerStatusRuleExist) {
          setRules((draftRules) => {
            draftRules.unshift({
              id: getCriteriaId({
                value:
                  condition === 'is' || condition === 'isManager'
                    ? 'true'
                    : 'false',
                operator:
                  condition === 'isManager' ? Operator.IsManager : Operator.Is,
                field: CustomRuleType.ManagerStatus,
              }),
              title: formatMessage(RuleMessages.managerRule, {
                condition:
                  condition === 'isManager'
                    ? formatMessage(messages.isTheManagerOfTheParticipant)
                    : condition === 'is'
                      ? formatMessage(messages.isTrue)
                      : formatMessage(messages.isFalse),
              }),
              subtitle: undefined,
              customIcon: {
                icon: CriteriaCardIconMap[ruleType.id],
              },
              permission:
                type === 'challenge'
                  ? condition === 'isManager'
                    ? ApproverPermission
                    : ParticipantPermission
                  : DefaultPermission[type],
              permissionOptions: (() => {
                if (type === 'challenge') {
                  return getChallengesPermissionOptions({
                    type: CriteriaRuleType.ManagerStatus,
                    formatMessage,
                    isManagerOfParticipant: condition === 'isManager',
                  });
                }
                if (type === 'peopleSelector') {
                  return getPeopleSelectorPermissionOptions({ formatMessage });
                }
                if (type === 'collection') {
                  return getCollectionPermissionOptions({
                    type: CriteriaRuleType.ManagerStatus,
                    formatMessage,
                    isNewUX,
                  });
                }
                return getOwnerAndCollaboratorPermissionOptions({
                  formatMessage,
                });
              })(),
              metaData: {
                field: CustomRuleType.ManagerStatus,
                operator:
                  condition === 'isManager' ? Operator.IsManager : Operator.Is,
                value:
                  condition === 'is' || condition === 'isManager'
                    ? 'true'
                    : 'false',
              },
            });
            sortCurrentUserToTop(draftRules, currentUserId);
          });
        }
      } else if (selectedRules) {
        const hasEveryoneRule = rules.some(
          (rule) => rule.metaData?.field === CriteriaRuleType.Everyone
        );
        setRules((draftRules) => {
          selectedRules.forEach((selectedRule) => {
            const checkIfRuleAlreadyExists = rules.some(
              (rule) =>
                rule.id ===
                getCriteriaId({
                  value: selectedRule.id,
                  operator:
                    condition === Operator.Is ? Operator.Is : Operator.IsNot,
                  field: ruleType.id,
                })
            );
            if (!checkIfRuleAlreadyExists) {
              draftRules.map((rule) => {
                if (
                  rule.metaData?.field === CriteriaRuleType.Member ||
                  rule.metaData?.field === CriteriaRuleType.Email
                ) {
                  rule.tooltipText = getTooltipText({
                    hasNonMemberCriteriaOtherThanEveryone: true,
                  });
                } else if (rule.metaData?.field !== CriteriaRuleType.Everyone) {
                  rule.tooltipText = getTooltipText({
                    hasEveryoneRule,
                  });
                }
                if (rule.permission?.id === PermissionType.Owner) {
                  rule.tooltipText = undefined;
                }
                return rule;
              });

              draftRules.unshift({
                id: getCriteriaId({
                  value: selectedRule.id,
                  operator: condition === 'is' ? Operator.Is : Operator.IsNot,
                  field: ruleType.id,
                }),
                title: formatMessage(
                  RuleMessages[
                    `${ruleType.id}Rule` as keyof typeof RuleMessages
                  ],
                  {
                    condition:
                      condition === Operator.Is
                        ? formatMessage(messages.is)
                        : formatMessage(messages.isNot),
                    name: selectedRule.label,
                  }
                ),
                subtitle: undefined,
                customIcon: {
                  icon: CriteriaCardIconMap[ruleType.id],
                },
                permission: DefaultPermission[type],
                permissionOptions: (() => {
                  if (type === 'challenge') {
                    return getChallengesPermissionOptions({
                      type: ruleType.id as CriteriaRuleType,
                      formatMessage,
                    });
                  }
                  if (type === 'peopleSelector') {
                    return getPeopleSelectorPermissionOptions({
                      formatMessage,
                    });
                  }
                  if (type === 'collection') {
                    return getCollectionPermissionOptions({
                      type: ruleType.id as CriteriaRuleType,
                      formatMessage,
                      isNewUX,
                    });
                  }
                  return getOwnerAndCollaboratorPermissionOptions({
                    formatMessage,
                  });
                })(),
                metaData: {
                  field: ruleType.id,
                  operator: condition === 'is' ? Operator.Is : Operator.IsNot,
                  value: selectedRule.id,
                },
              });
            }
          });
          sortCurrentUserToTop(draftRules, currentUserId);
        });
      }
    },
    [
      currentUserId,
      formatMessage,
      getTooltipText,
      isNewUX,
      rules,
      setRules,
      type,
    ]
  );

  const handleOnEmailInvite = useCallback(
    (email: string) => {
      const currentRuleId = getCriteriaId({
        value: email,
        operator: Operator.Is,
        field: CriteriaRuleType.Email,
      });
      const doesEmailRuleAlreadyExists = rules.some(
        (rule) => rule.id === currentRuleId
      );
      if (doesEmailRuleAlreadyExists) {
        const element = document.getElementById(currentRuleId);
        scrollIntoViewAndAnimateBackground(element);
        return;
      }
      trackSharesheetAction({
        actionName: 'inviteMemberClicked',
        props: {
          criteriaSelected: `${CriteriaRuleType.Email}${
            operatorLabelMap[Operator.Is]
          }`,
          roleSelected: PermissionType.Participant,
          ruleType: 'simple',
          isV3sharesheet: true,
          sharesheetType: 'challenge',
        },
      });
      onEmailInvite?.();
      setRules((draftRules) => {
        draftRules.unshift({
          id: currentRuleId,
          title: email,
          subtitle: formatMessage(messages.inviteUponSharing),
          customIcon: {
            icon: CriteriaCardIconMap['pending'],
            iconBackgroundColor: 'bg-gray-4',
          },
          permission: DefaultPermission[type],
          permissionOptions: (() => {
            if (type === 'challenge') {
              return getChallengesPermissionOptions({
                type: CriteriaRuleType.Email,
                formatMessage,
              });
            }
            if (type === 'peopleSelector') {
              return getPeopleSelectorPermissionOptions({
                formatMessage,
              });
            }
            if (type === 'collection') {
              return getCollectionPermissionOptions({
                type: CriteriaRuleType.Email,
                formatMessage,
                isNewUX,
              });
            }
            return getOwnerAndCollaboratorPermissionOptions({
              formatMessage,
            });
          })(),
          metaData: {
            field: CriteriaRuleType.Email,
            operator: Operator.Is,
            value: email,
          },
          tooltipText: getTooltipText({
            hasNonMemberCriteriaOtherThanEveryone,
          }),
        });
        sortCurrentUserToTop(draftRules, currentUserId);
      });
    },
    [
      currentUserId,
      formatMessage,
      getTooltipText,
      hasNonMemberCriteriaOtherThanEveryone,
      isNewUX,
      onEmailInvite,
      rules,
      setRules,
      type,
    ]
  );

  return {
    customRules,
    customRuleOptions,
    handleOnEmailInvite,
    selectedCustomRule,
    onCustomRuleSelect,
    onCustomRulesAction,
    onRulesPermissionChange,
    onRulesSelectionChange,
  } as const;
};

export function scrollIntoViewAndAnimateBackground(
  element: HTMLElement | null
) {
  if (element) {
    element.scrollIntoView({
      block: 'center',
      inline: 'center',
      behavior: 'smooth',
    });
    element.classList.add('bg-primary-2', 'animate-pulse');

    setTimeout(() => {
      element.classList.remove('bg-primary-2', 'animate-pulse');
    }, 1000);
  }
}

export const sortCurrentUserToTop = (
  draftRules: CriteriaItemProps[],
  currentUser: string
) => {
  draftRules.sort((a, b) => {
    if (
      a.metaData?.field === CriteriaRuleType.Member &&
      a.metaData.value === currentUser
    ) {
      return -1;
    } else if (
      b.metaData?.field === CriteriaRuleType.Member &&
      b.metaData.value === currentUser
    ) {
      return 1;
    } else {
      return 0;
    }
  });
};
