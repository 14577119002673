import { deriveEnumForValue } from '@assembly-web/services';
import { GlobalFilterOption } from '@assembly-web/ui';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useIsNewUX } from './useIsNewUX';

function getFilterTypeForLocation(pathname: string): GlobalFilterOption {
  if (pathname.endsWith('/collections') || pathname.endsWith('/folders')) {
    return GlobalFilterOption.Collections;
  } else if (pathname.endsWith('/challenges')) {
    return GlobalFilterOption.Challenges;
  } else if (pathname.endsWith('/files')) {
    return GlobalFilterOption.Files;
  } else if (pathname.endsWith('/flows')) {
    return GlobalFilterOption.Flows;
  } else if (pathname.endsWith('/people')) {
    return GlobalFilterOption.People;
  } else if (pathname.endsWith('/rewards')) {
    return GlobalFilterOption.Rewards;
  } else if (pathname.endsWith('/tasks')) {
    return GlobalFilterOption.Tasks;
  } else if (pathname.includes('/activity')) {
    return GlobalFilterOption.Notifications;
  } else if (pathname.includes('/recognition')) {
    return GlobalFilterOption.Recognition;
  }

  return GlobalFilterOption.All;
}

export function useGlobalFilter() {
  const [searchParams] = useSearchParams();

  const { pathname } = useLocation();
  const isNewUX = useIsNewUX();

  if (isNewUX) {
    return getFilterTypeForLocation(pathname);
  }

  return (
    deriveEnumForValue(searchParams.get('filter'), GlobalFilterOption) ??
    GlobalFilterOption.All
  );
}
