import type { MemberAPIResponse } from '@assembly-web/services';
import {
  APIEndpoints,
  assemblyAPI,
  config,
  useUserDetails,
} from '@assembly-web/services';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import timezonePlugin from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';
import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import invariant from 'tiny-invariant';

dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);

function renderGtagScript(data: Record<string, unknown>) {
  const script = document.createElement('script');
  const scriptText = document.createTextNode(
    `gtag('event', 'conversion', ${JSON.stringify(data)});`
  );
  script.appendChild(scriptText);
  document.body.appendChild(script);
}

function fireConversionForEmployerOnboard() {
  if (!config.isProduction) {
    return;
  }

  renderGtagScript({ send_to: 'AW-979429994/IyZ_CJ2YpI4DEOrUg9MD' });
}

function fireConversionForNonGenericEmailEmployerOnboard() {
  if (!config.isProduction) {
    return;
  }

  renderGtagScript({ send_to: 'AW-979429994/bD8hCMW4oMkDEOrUg9MD' });
}

function fireConversionForSignup(userId: string) {
  if (!config.isProduction) {
    return;
  }

  renderGtagScript({
    send_to: 'AW-979429994/Zp4nCNu03aQBEOrUg9MD',
    value: 40.0,
    currency: 'USD',
    transaction_id: userId,
  });
}

type JobStatus = 'QUEUED' | 'PROCESSING' | 'ERROR' | 'SUCCESS';

type OnboardingJobResponse = {
  details: JobDetail[];
};

type JobDetail = {
  name: string;
  job: { id: string; status: JobStatus };
};

function useAccountCreationMutation() {
  return useMutation({
    mutationKey: ['accountSetup'],
    mutationFn: async function () {
      const { data } = await assemblyAPI.post<OnboardingJobResponse>(
        APIEndpoints.accountSetup,
        { onboardingPath: '' }
      );
      return data;
    },
  });
}

function useUpdateUserTimeZone() {
  const timeZone = dayjs.tz.guess();

  return useMutation({
    mutationKey: ['updateUserTimeZone'],
    mutationFn: () =>
      assemblyAPI.post(APIEndpoints.updateUserTimeZone, { timeZone }),
  });
}

function useJobPoller({
  accountSetupStatus,
  shouldExecute,
}: {
  accountSetupStatus: OnboardingJobResponse | undefined;
  shouldExecute: boolean;
}) {
  const [isAccountReady, setIsAccountReady] = useState(false);
  const listOfJobIds = accountSetupStatus?.details.flatMap((x) => x.job.id);

  const canCheckStatus =
    shouldExecute && Boolean(listOfJobIds) && !isAccountReady;

  const { data: jobStatus, refetch: refetchJobStatus } = useQuery({
    staleTime: Infinity,
    refetchInterval: 3000,
    queryKey: ['onboardingJobLookup', listOfJobIds, shouldExecute],
    enabled: canCheckStatus,
    queryFn: async function () {
      invariant(listOfJobIds, 'jobs exist here');
      const {
        data: { data },
      } = await assemblyAPI.get<{
        data: { id: string; name: string; status: JobStatus }[];
      }>(APIEndpoints.onboardingJobsStatus(listOfJobIds));

      return data;
    },
  });

  useEffect(() => {
    if (!canCheckStatus) {
      return;
    }

    const relevantJobs = jobStatus?.filter((x) => listOfJobIds?.includes(x.id));

    if (
      relevantJobs?.every((x) => x.status === 'SUCCESS' || x.status === 'ERROR')
    ) {
      setIsAccountReady(true);
    } else {
      refetchJobStatus();
    }
  }, [
    shouldExecute,
    canCheckStatus,
    jobStatus,
    listOfJobIds,
    refetchJobStatus,
  ]);

  return isAccountReady;
}

const commonUserDetailsQueryOptions: UseQueryOptions<MemberAPIResponse> = {
  queryKey: ['onboarding-user-details'],
  staleTime: 0,
};

export function useWorkspaceSetup(shouldExecute: boolean) {
  const { mutate: updateUserTimeZone, isSuccess: hasUpdatedTimeZone } =
    useUpdateUserTimeZone();

  const {
    mutate: setupAccount,
    data: accountSetupStatus,
    isSuccess: hasStartedAccountCreation,
  } = useAccountCreationMutation();

  useEffect(() => {
    const apiFn = debounce(() => {
      setupAccount();
      updateUserTimeZone();
    }, 750);

    if (shouldExecute) {
      apiFn();
    }

    return () => apiFn.cancel();
  }, [setupAccount, shouldExecute, updateUserTimeZone]);

  const isProfileSetup = useJobPoller({
    accountSetupStatus,
    shouldExecute:
      shouldExecute && hasUpdatedTimeZone && hasStartedAccountCreation,
  });

  const [isAccountSetupDone, setIsAccountSetupDone] = useState(false);

  const { data: userDetails } = useUserDetails({
    enabled: isProfileSetup,
    ...commonUserDetailsQueryOptions,
  });

  useEffect(() => {
    if (!isProfileSetup || !userDetails) {
      return;
    }
    fireConversionForSignup(userDetails.member.memberId);

    setIsAccountSetupDone(true);
  }, [isProfileSetup, userDetails]);

  return isAccountSetupDone;
}

export function useUserSetup() {
  const { mutate: updateUserTimeZone } = useUpdateUserTimeZone();

  const { data: accountSetupStatus, isSuccess } = useQuery({
    queryKey: ['onboardingJobsList'],
    queryFn: async function () {
      // NOTE: adding it here to avoid the extra useEffect
      updateUserTimeZone();
      const { data } = await assemblyAPI.get<OnboardingJobResponse>(
        APIEndpoints.onboardingJobsList
      );
      return data;
    },
  });

  const isProfileSetup = useJobPoller({
    accountSetupStatus,
    shouldExecute: isSuccess,
  });

  const { data: userDetails } = useUserDetails({
    enabled: isProfileSetup,
    ...commonUserDetailsQueryOptions,
  });

  const [isAccountSetupDone, setIsAccountSetupDone] = useState(false);

  useEffect(() => {
    if (!isProfileSetup || !userDetails) {
      return;
    }

    if (userDetails.member.isFreeEmail) {
      fireConversionForEmployerOnboard();
    } else {
      fireConversionForNonGenericEmailEmployerOnboard();
    }

    setIsAccountSetupDone(true);
  }, [isProfileSetup, userDetails]);

  return isAccountSetupDone;
}
