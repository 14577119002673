import {
  ActivityCardTypeEnum,
  APIEndpoints,
  type ImportantActivitiesApiResponse,
  type UserFeedApiResponse,
} from '@assembly-web/services';
import { assemblyAPI } from '@assembly-web/services';
import {
  type InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { produce } from 'immer';

import type { SearchIndexApiResponse } from './useSearchIndex';

type UpdateAssemblyChallengeNotificationsPreferencePayload = {
  cardId?: string;
  challengeId: string;
  postType: 'CHALLENGE';
  type: 'SUBSCRIBE' | 'UNSUBSCRIBE';
};

export function useUpdateAssemblyChallengeNotificationPreferenceMutationQuery() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: UpdateAssemblyChallengeNotificationsPreferencePayload
    ) => {
      const { challengeId, type } = payload;
      await assemblyAPI.put(
        APIEndpoints.updateChallengeNotification(challengeId),
        {
          type,
        }
      );
    },

    onMutate: async (
      payload: UpdateAssemblyChallengeNotificationsPreferencePayload
    ) => {
      const { cardId, type } = payload;
      const queryCacheKey = ['importantCards'];
      const previousCards =
        queryClient.getQueryData<InfiniteData<ImportantActivitiesApiResponse>>(
          queryCacheKey
        );

      if (previousCards) {
        const updatedActivities = produce(previousCards, (draft) => {
          draft.pages.forEach((page) => {
            page.data.data = page.data.data.map((card) => {
              if (
                card.cardId === cardId &&
                // TODO: check for isMuted flag for ActivityCardTypeEnum.ChallengeLaunchActivity
                (card.type === ActivityCardTypeEnum.OwnerChallengeReply ||
                  card.type === ActivityCardTypeEnum.ChallengeThreadReplies)
              ) {
                card.entity.isMuted = type === 'UNSUBSCRIBE';
              }
              return card;
            });
          });
        });

        queryClient.setQueryData(queryCacheKey, updatedActivities);
      }

      return { previousCards };
    },

    onSuccess: async (_, payload) => {
      const queryCache = queryClient.getQueryCache();
      const userFeedKeys = queryCache
        .findAll({
          queryKey: ['userFeed'],
        })
        .map((query) => query.queryKey);
      const searchFeedKeys = queryCache
        .findAll({
          queryKey: ['searchResults'],
        })
        .map((query) => query.queryKey);

      userFeedKeys.forEach((key) => {
        const userFeedData: InfiniteData<UserFeedApiResponse> | undefined =
          queryClient.getQueryData(key);
        if (userFeedData) {
          const mutatedUserFeedData: InfiniteData<UserFeedApiResponse> = {
            ...userFeedData,
            pages: userFeedData.pages.map((page) => {
              return {
                ...page,
                data: page.data.map((post) => {
                  if (post.cardDetails?.challengeId === payload.challengeId) {
                    return produce(post, (draft) => {
                      if (draft.cardDetails) {
                        draft.cardDetails.isMuted =
                          payload.type === 'UNSUBSCRIBE' ? true : undefined;
                      }
                    });
                  }
                  return post;
                }),
              };
            }),
          };
          queryClient.setQueryData(key, mutatedUserFeedData);
        }
      });

      searchFeedKeys.forEach((queryKey) => {
        const searchData =
          queryClient.getQueryData<InfiniteData<SearchIndexApiResponse>>(
            queryKey
          );
        if (searchData) {
          const mutatedSearchData: InfiniteData<SearchIndexApiResponse> = {
            ...searchData,
            pages: searchData.pages.map((page) => {
              return {
                ...page,
                data: {
                  ...page.data,
                  data: page.data.data.map((result) => {
                    if (
                      result.type === 'post' &&
                      result.cardDetails?.post?.postID === payload.challengeId
                    ) {
                      return produce(result, (draft) => {
                        if (draft.cardDetails) {
                          draft.cardDetails.isMuted =
                            payload.type === 'UNSUBSCRIBE' ? true : undefined;
                        }
                      });
                    }
                    return result;
                  }),
                },
              };
            }),
          };
          queryClient.setQueryData(queryKey, mutatedSearchData);
        }
      });
    },

    onError: (_, __, context) => {
      const queryCacheKey = ['importantCards'];
      queryClient.setQueryData(queryCacheKey, context?.previousCards);
    },
  });
}
