import {
  BlockResponseState,
  BlockTypes,
  type BoostDetails,
  getMemberDetailsFromUserDetails,
  LegacyPostTypes,
  type MemberDetails,
  type MemberState,
  PersonSelectorSelectType,
  type PostGroupedCommentSearchResult,
  type ReactionDetails,
  type ResponseGroupedCommentSearchResult,
  SplitNames,
  useFeatureSplit,
  type UserDetails,
  VisibilityTypes,
} from '@assembly-web/services';
import {
  getFormattedMessage,
  getRespondentForAnonymousFlowPost,
  PostCardActions,
  ReplyCardWithPostPreview,
  type ToolbarItem,
  useAssemblyNavigate,
} from '@assembly-web/ui';
import { getRespondentForLegacyFlowPost, ReplyCard } from '@assembly-web/ui';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/20/solid';
import { useContext, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useNavigateToUserFeed } from '../../../../hooks/useNavigateToUserFeed';
import { usePosts } from '../../../../hooks/usePosts';
import { useCommentReactionMutation } from '../../../../hooks/useReactionMutation';
import { useReplies } from '../../../../hooks/useReplies';
import { getURLForAssemblyPostActions } from '../../../../services/post';
import type { PostData } from '../../../../types/postsAndReplies';
import { ModalsContext } from '../../contexts/ModalsContext';
import { type CardAction, trackCardAction } from '../../services/analytics';
import { PostCard, type PostCardProps } from './PostCard';
import { type FlowResponseCardProps, ResponseCard } from './ResponseCard';
import type { CommonCardProps } from './type';
import {
  getToolbarMenuItemsForReplyCard,
  ReplyToolBarAction,
  ToolbarItemId,
} from './utils';

const msgs = defineMessages({
  reply: {
    defaultMessage: 'Reply',
    id: '9HU8vw',
  },
  recognitionBlockTitle: {
    defaultMessage: 'Who would you like to celebrate?',
    id: '2iD0/h',
  },
  recognitionBlockContent: {
    defaultMessage: 'What do you want to say?',
    id: 'Zny4Po',
  },
  recognitionBlockPoints: {
    defaultMessage: 'Lastly, boost your celebration with Points!',
    id: 'Iv1r3f',
  },
  birthday: {
    defaultMessage: 'Birthday',
    id: 'yngd9x',
  },
  anniversary: {
    defaultMessage: 'Anniversary',
    id: '1MHEps',
  },
});

type GroupedCommentCardProps = {
  commentCardDetails:
    | PostGroupedCommentSearchResult['cardDetails']
    | ResponseGroupedCommentSearchResult['cardDetails'];
  content: string;
  collectionTitle?: string;
  onClick?: () => void;
  gifURL?: string;
  onReplyClick?: () => void;
  replyUrlSlug: string;
  taggedUsers: MemberDetails[];
  commentId: string;
  postOwner: {
    firstName?: string;
    lastName?: string;
    profileStatus: MemberState;
    entityId: string;
    profileImageUrl?: string;
    fullName: string;
    type?: string;
    pronouns?: string;
    email?: string;
    department?: string;
    jobTitle?: string;
  };
  flow: string;
  commentOwner: MemberDetails;
  commentCreatedAt: string;
  postCreatedAt: string;
  kind: string;
  flowId: string;
  postId: string;
  responseId: string;
  userDetails: UserDetails;
  editReplySettings: {
    isV3Reply: boolean;
    isAnonymousReplyEditable: boolean;
  };
  isEdited: boolean;
  reactions: ReactionDetails[];
  commentCount: number;
  pointsEach: number;
  boostedUsers: BoostDetails[];
} & CommonCardProps;

export function GroupedCommentCard({
  content,
  commentCardDetails,
  replyUrlSlug,
  postOwner,
  commentId,
  commentOwner,
  commentCreatedAt,
  postCreatedAt,
  flow,
  kind,
  flowId,
  postId,
  responseId,
  userDetails,
  isCollectionItem,
  showPostInteractionSettings,
  onToolbarMenuItemClick,
  cardId,
  workspaceSlugPath,
  isAdmin,
  hideReactions,
  hideReplies,
  editReplySettings,
  reactions,
  commentCount,
  gifURL,
  pointsEach,
  isEdited,
  taggedUsers,
  collectionTitle,
  currencyDetails,
  boostedUsers,
  filter,
  isNewUX,
}: GroupedCommentCardProps) {
  const navigate = useAssemblyNavigate();
  const { navigate: navigateToUserFeed } = useNavigateToUserFeed();

  const { onReplyClicked } = useReplies();
  const { onPostClicked } = usePosts();
  const currentUserId = userDetails.member.memberId;
  const { formatMessage } = useIntl();
  let messageNode;
  const { onDeleteReplyOrPostClick } = useContext(ModalsContext);
  const { isTreatmentActive: isReplyWithPreviewEnabled } = useFeatureSplit(
    SplitNames.ReplyWithPostPreview
  );

  const trackAction = (action: CardAction) => {
    trackCardAction(action, {
      filterType: filter,
      cardType: 'reply',
      flowId,
      flowName: flow,
      isNotification: false,
      isAnonymousReply: !conversationMemberDetails.memberID,
    });
  };

  const handleMenuItem = (args: ToolbarItem) => {
    trackAction(ReplyToolBarAction[args.id]);
    if (ToolbarItemId.EditReply === args.id) {
      onViewRepliesClick(commentId);
    } else if (ToolbarItemId.DeleteReply === args.id) {
      onDeleteReplyOrPostClick({
        replyId: commentId,
        flowId,
        responseId,
      });
    } else {
      onToolbarMenuItemClick?.({
        ...args,
        url: replyUrlSlug,
        cardId,
        commentId,
        cardIcon: (
          <ChatBubbleBottomCenterTextIcon className="h-4 w-4 stroke-2 max-md:max-w-none" />
        ),
        title: collectionTitle?.length ? collectionTitle : content,
        type: 'comment',
        entityId: commentId,
        postId,
        flowId,
        plainFlow: flow,
        author: postOwner.fullName,
        responseId,
        allowReplies: !hideReplies,
        allowReactions: !hideReactions,
      });
    }
  };

  const redirectToV2 = (action?: PostCardActions, id?: string) => {
    const redirectionURL = getURLForAssemblyPostActions({
      action: action ?? PostCardActions.RepliesBarClicked,
      slug: workspaceSlugPath,
      flowId,
      id,
      responseId,
    });
    if (redirectionURL) navigate(redirectionURL);
  };

  const onViewRepliesClick = (editSelectedCommentId?: string) => {
    const viewReplyPayload: PostData =
      kind === 'recognition'
        ? { type: 'post', postId, commentId, editSelectedCommentId }
        : {
            type: 'flow',
            flowId,
            responseId,
            commentId,
            editSelectedCommentId,
          };
    onReplyClicked(viewReplyPayload);
  };

  const onViewPostClick = () => {
    const openPostPayload: PostData =
      kind === 'recognition'
        ? { type: 'post', postId }
        : {
            type: 'flow',
            flowId,
            responseId,
          };
    onPostClicked(openPostPayload);
  };

  const conversationMemberDetails = {
    cardId: commentId,
    messageContent: content,
    createdAt: commentCreatedAt,
    isAnonymous: !commentOwner.memberID,
    ...commentOwner,
  };

  const reactionMutate = useCommentReactionMutation({
    currentUser: getMemberDetailsFromUserDetails(userDetails.member),
  });

  const handleReactionClick = (emoji: ReactionDetails) => {
    const hasCurrentUserReactedToTheSelectedEmoji = reactions.some(
      (reaction) =>
        reaction.name === emoji.name &&
        reaction.members.some((member) => member.memberID === currentUserId)
    );
    const replyReaction = hasCurrentUserReactedToTheSelectedEmoji
      ? 'replyReactionRemoved'
      : 'replyReactionAdded';
    trackAction(replyReaction);
    reactionMutate({
      payload: {
        displayName: emoji.displayName,
        name: emoji.name,
        type: emoji.type,
        value: emoji.type,
      },
      action: hasCurrentUserReactedToTheSelectedEmoji ? 'unset' : 'set',
      commentId,
      flowId,
      responseId,
    });
  };

  if (taggedUsers.length && !editReplySettings.isV3Reply) {
    messageNode = getFormattedMessage(
      {
        mentions: taggedUsers.map((user) => {
          return {
            ...user,
            memberId: user.memberID,
          };
        }),
        value: content,
      },
      () => {},
      {}
    );
  }

  const commentCardProps = {
    onMemberClick: navigateToUserFeed,
    taggedUsers: taggedUsers,
    boostedUsers: boostedUsers,
    messageNode: messageNode,
    buttonText: formatMessage(msgs.reply),
    content: content,
    handleMenuItem: handleMenuItem,
    conversationMemberDetails: conversationMemberDetails,
    flow: flow,
    postOwner: postOwner,
    commentId: commentId,
    gifURL: gifURL,
    commentCount,
    pointsEach: {
      icon: userDetails.assembly.currency,
      points: pointsEach,
    },
    isEdited: isEdited,
    postCreatedAt: postCreatedAt,
    handleReactionClick: handleReactionClick,
    reactions: reactions,
    currentUserId: currentUserId,
    toolbarMenuItems: [
      ...getToolbarMenuItemsForReplyCard(
        {
          formatMessage,
          canEditReply:
            (currentUserId === commentOwner.memberID ||
              editReplySettings.isAnonymousReplyEditable) &&
            editReplySettings.isV3Reply,
          isAdmin,
          isAuthor: currentUserId === commentOwner.memberID,
          isCollectionItem,
          showPostInteractionSettings,
        },
        isNewUX
      ),
    ],
    onCardClicked: () => {
      trackAction('cardClicked');
      onViewRepliesClick();
    },
    onViewThreadClicked: () => {
      trackAction('viewThreadClicked');
      onViewRepliesClick();
    },
    onSeePostClicked: () => {
      trackAction('seePostClicked');
      onViewPostClick();
    },
    handlePostOwnerClick: () => {
      trackAction('profileNameClicked');
      redirectToV2(PostCardActions.PersonClicked, postOwner.entityId);
    },
    handleViewRepliesClick: () => {
      trackAction('replyButtonClicked');
      onViewRepliesClick();
    },
    handleFlowClick: () => {
      trackAction('flowClicked');
      redirectToV2(PostCardActions.FlowNameClicked);
    },
    currencyDetails: currencyDetails,
  };

  const previewProps: PostCardProps | FlowResponseCardProps | unknown =
    useMemo(() => {
      let props = {};
      if (kind === 'recognition') {
        const postData =
          'post' in commentCardDetails && commentCardDetails.post;
        const post = postData && 'post' in postData && postData.post;
        if (post)
          props = {
            postResponse: {
              hasReplyDraft: post.hasReplyDraft ?? false,
              respondent:
                post.from ??
                (post.type === 'anniversary'
                  ? getRespondentForLegacyFlowPost(LegacyPostTypes.Anniversary)
                  : getRespondentForLegacyFlowPost(LegacyPostTypes.Birthday)),
              reactions: post.reactions ?? [],
              responseId: commentCardDetails.postID,
              createdAt: post.createdAt,
              kind: post.kind,
              isPrivate: post.isPrivate,
              responses: [
                {
                  blockId: '1',
                  state: BlockResponseState.Responded,
                  type: BlockTypes.PersonSelector,
                  content: {
                    title: formatMessage(msgs.recognitionBlockTitle),
                    type: BlockTypes.PersonSelector,
                    selectType: PersonSelectorSelectType.MultiPerson,
                  },
                  edited: Boolean(post.editedAt),
                  response: {
                    persons: post.to,
                  },
                },
                {
                  blockId: '3',
                  type: BlockTypes.OpenEnded,
                  state: BlockResponseState.Responded,
                  edited: true,
                  content: {
                    title: formatMessage(msgs.recognitionBlockContent),
                    type: BlockTypes.OpenEnded,
                  },
                  response: {
                    value: post.message,
                    files: [],
                    gifUrl: '',
                    mentions: [],
                  },
                },
                {
                  blockId: '3',
                  type: BlockTypes.GivePointsStack,
                  state: BlockResponseState.Responded,
                  edited: true,
                  content: {
                    title: formatMessage(msgs.recognitionBlockPoints),
                    type: BlockTypes.GivePointsStack,
                    rules: {
                      required: false,
                      hidePoints: false,
                      points: {
                        noLimit: true,
                      },
                    },
                  },
                  response: {
                    value: post.pointsEach,
                  },
                },
              ],
              flow: {
                name: 'Give recognition',
                flowId: 'recognition',
                kind: '',
                icon: {
                  kind: 'HEX_CODE',
                  value: '1F389',
                },
              },
              visibility: post.isPrivate
                ? VisibilityTypes.Everyone
                : VisibilityTypes.Custom,
              canDelete: post.canDelete,
              canEdit: commentCardDetails.canEdit,
              isBoostAllowed: true,
              replySummary: commentCardDetails.replySummary,
            },
            isReplyPreviewCard: true,
            lazyLoadPostDetailsOnScroll: false,
            flow: 'Give Recognition',
            postId: commentCardDetails.postID,
            authorId: post.from?.memberID,
            postType: post.type,
            description: post.message,
            hasReplyDraft: post.hasReplyDraft ?? false,
            celebratedTeammate: post.to[0].name,
            celebratedTeammateId: post.to[0].memberID,
            plainCelebratedTeammate: post.to[0].name,
            author:
              (post.from?.name ?? post.type === 'anniversary')
                ? formatMessage(msgs.anniversary)
                : formatMessage(msgs.birthday),
            plainAuthor: post.from?.name,
            urlSlug: `/post/flow/recognition?postId=${postId}&showReplies=true&type=recognition`,
            cardId: cardId,
            isAdmin: userDetails.member.role.includes('ADMIN'),
            workspaceName: userDetails.assembly.name,
            workspaceSlugPath: userDetails.assembly.workspaceSlugPath,
          };
      } else if (kind === 'response' && 'response' in commentCardDetails) {
        const { response } = commentCardDetails;
        if (response) {
          const respondent =
            response.respondent ?? getRespondentForAnonymousFlowPost();
          props = {
            isReplyPreviewCard: true,
            isNewUX: false,
            workspaceName: userDetails.assembly.name,
            workspaceSlugPath: userDetails.assembly.workspaceSlugPath,
            cardId: cardId,
            currentUserId: userDetails.member.memberId,
            isAdmin: userDetails.member.role.includes('ADMIN'),
            postResponse: {
              responseId: response,
              respondent: response.respondent,
              responses: response.responses,
              flow: response.flow,
              commentsCount: 0,
              reactions: response.reactions,
              visibility: response.isPrivate ? 'CUSTOM' : 'EVERYONE',
              isPrivate: response.isPrivate,
              createdAt: response.createdAt,
              canDelete: response.canDelete,
              canEdit: response.canEdit,
              kind: 'INTERNAL',
              hideReplies: response.hideReplies,
              hideReactions: response.hideReactions,
              showTurnOffSettings: true,
              hasReplyDraft: false,
              replySummary: {
                count: 0,
                respondentsCount: 0,
                initialRespondents: [],
              },
            },
            currencyDetails: userDetails.assembly.currency,
            flowId: response.flow.flowId,
            responseId: response.responseId,
            createdAt: response.createdAt,
            description: '',
            urlSlug: `/post/flow/flows?flowId=${response.flow.flowId}&responseId=${response.responseId}&showReplies=true&type=flow`,
            author: respondent.name,
            plainAuthor: respondent.name,
            flow: response.flow.name,
            plainFlow: response.flow.name,
            authorId: respondent.memberID,
            hideReplies: commentCardDetails.hideReplies,
            hideReactions: commentCardDetails.hideReactions,
            hasReplyDraft: response.hasReplyDraft,
            showPostInteractionSettings: true,
            lazyLoadPostDetailsOnScroll: false,
          };
        }
      }
      return props;
    }, [commentCardDetails, cardId, formatMessage, kind, postId, userDetails]);

  function isPostCardProps(object: unknown): object is PostCardProps {
    return typeof object === 'object' && object !== null && 'postId' in object;
  }

  function isResponseCardProps(
    object: unknown
  ): object is FlowResponseCardProps {
    return (
      typeof object === 'object' && object !== null && 'responseId' in object
    );
  }

  return (
    <div data-testid={`grouped-comment-card-${cardId}`}>
      {isReplyWithPreviewEnabled ? (
        <>
          {'post' in commentCardDetails &&
            Boolean(commentCardDetails.post) &&
            isPostCardProps(previewProps) && (
              <PostCard
                key={`${commentId}-post-card`}
                {...previewProps}
                isNewUX={isNewUX}
              />
            )}

          {'response' in commentCardDetails &&
            isResponseCardProps(previewProps) && (
              <ResponseCard
                key={`${commentId}-response-card`}
                {...previewProps}
                isNewUX={isNewUX}
              />
            )}
          <ReplyCardWithPostPreview {...commentCardProps} />
        </>
      ) : (
        <ReplyCard {...commentCardProps} commentCount={commentCount - 1} />
      )}
    </div>
  );
}
